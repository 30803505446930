import { Box, Divider, Image, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { OwnerObject } from '../../interfaces/components';
import ProfilePicture from '../../assets/profilePicture.png';
import { otherUserData } from '../../api/usersProfile/api';
import LoadingSpinner from '../common/LoadingSpinner';

interface UsersProfileHeroProps {
  owner: OwnerObject;
}

export default function UsersProfileHero(props: UsersProfileHeroProps) {
  const { owner } = props;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [otherUserInfo, setOtherUserInfo] = useState<any>();

  const fetchOtherUserProfile = async () => {
    try {
      setLoading(true);
      const res = await otherUserData(owner?.wallet_address);
      setLoading(false);
      setOtherUserInfo(res);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching user data',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchOtherUserProfile();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box width={['100%']} padding="56px 0px 24px 0px">
            <Box display="flex" gap="36px">
              <Box display="flex" flexDirection="row" gap="14px">
                {otherUserInfo?.profile_picture ? (
                  <Box height="116px" width="116px">
                    <Image
                      src={otherUserInfo?.profile_picture}
                      objectFit="cover"
                      borderRadius="50%"
                      height="inherit"
                      width="inherit"
                    />
                  </Box>
                ) : (
                  <Box height="116px" width="116px">
                    <Image src={ProfilePicture} objectFit="cover" height="inherit" width="inherit" borderRadius="50%" />
                  </Box>
                )}
                <Box display="flex" flexDirection="column" marginTop="8px" gap="16px">
                  <Text color="#fff" fontSize="24px" fontWeight="700">
                    {`@${otherUserInfo?.username.toLowerCase()}`}
                  </Text>
                  <Box
                    height="60px"
                    borderRadius="var(--Standard, 12px)"
                    background="rgba(255, 255, 255, 0.10)"
                    padding="12px"
                    display="flex"
                    gap="12px"
                  >
                    <Image width="32px" src={otherUserInfo?.user_level?.level_image} />
                    <Box display="flex" flexDirection="column">
                      <Text color="rgba(255, 255, 255, 0.50)" fontSize="12px" fontWeight="500">
                        Rank
                      </Text>
                      <Text color="#fff" fontSize="16px" fontWeight="700">
                        {otherUserInfo?.user_level?.level_name}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="24px" />
        </>
      )}
    </>
  );
}
