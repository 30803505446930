import { Box, Text, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

export default function Main() {
  const navigate = useNavigate();
  const { address } = useAccount();

  useEffect(() => {
    if (!address) {
      // navigate('/link-wallet');
    }
  }, [address, navigate]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Box
        padding="1px"
        borderRadius="24px"
        backgroundColor="#1F1F1F"
        zIndex={2}
        style={{
          borderRadius: '24px',
          backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
          backgroundOrigin: 'border-box',
          backgroundClip: ' content-box, border-box',
        }}
      >
        <Box
          padding={['20px 20px', '36px', '36px']}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text fontWeight={700} fontSize="28px" color="#fff">
            All set!
          </Text>
          <Text fontWeight={500} fontSize="18px" color="#fff" marginTop="24px">
            Let’s mint your first NFT
          </Text>
          <Button
            marginTop="24px"
            width={['100%', '100%', '344px', '344px']}
            borderRadius=" 15px"
            borderTop="1px solid #43BDD7"
            backgroundColor="#118BCF"
            padding="16px 32px"
            color=" #FFF"
            fontSize=" 20px"
            fontWeight=" 600"
            h="56px"
            _hover={{
              color: 'white',
              bg: '#43BDD7',
            }}
            onClick={() => navigate('/mint-nft')}
          >
            Mint now
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
