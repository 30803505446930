import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CloseIconGray(props: IconProps) {
  return (
    <Icon width="59px" height="52px" viewBox="0 0 59 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.21582 15C0.21582 6.71573 6.93155 0 15.2158 0H43.2158C51.5001 0 58.2158 6.71573 58.2158 15V37C58.2158 45.2843 51.5001 52 43.2158 52H15.2158C6.93155 52 0.21582 45.2843 0.21582 37V15Z"
        fill="#2C2C2C"
      />
      <mask id="mask0_1666_7834" maskUnits="userSpaceOnUse" x="15" y="12" width="29" height="28">
        <rect x="15.2158" y="12" width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1666_7834)">
        <path
          d="M29.2158 27.6342L23.4991 33.3509C23.2852 33.5648 23.013 33.6717 22.6824 33.6717C22.3519 33.6717 22.0796 33.5648 21.8658 33.3509C21.6519 33.137 21.5449 32.8648 21.5449 32.5342C21.5449 32.2037 21.6519 31.9315 21.8658 31.7176L27.5824 26.0009L21.8658 20.2842C21.6519 20.0704 21.5449 19.7981 21.5449 19.4676C21.5449 19.137 21.6519 18.8648 21.8658 18.6509C22.0796 18.437 22.3519 18.3301 22.6824 18.3301C23.013 18.3301 23.2852 18.437 23.4991 18.6509L29.2158 24.3676L34.9324 18.6509C35.1463 18.437 35.4185 18.3301 35.7491 18.3301C36.0796 18.3301 36.3519 18.437 36.5658 18.6509C36.7796 18.8648 36.8866 19.137 36.8866 19.4676C36.8866 19.7981 36.7796 20.0704 36.5658 20.2842L30.8491 26.0009L36.5658 31.7176C36.7796 31.9315 36.8866 32.2037 36.8866 32.5342C36.8866 32.8648 36.7796 33.137 36.5658 33.3509C36.3519 33.5648 36.0796 33.6717 35.7491 33.6717C35.4185 33.6717 35.1463 33.5648 34.9324 33.3509L29.2158 27.6342Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
