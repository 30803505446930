export function formatAddress(hexString: string, startLetters: number, endLetters: number) {
  const prefix = hexString.substring(0, startLetters); // Get the first 8 characters
  const suffix = hexString.substring(hexString.length - endLetters); // Get the last 8 characters
  return prefix + '......' + suffix; // Concatenate with dots in between
}

export const formatDateForInput = (dateString: string | number | Date) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().slice(0, 16); // Format as "YYYY-MM-DDTHH:MM"
};

export const truncateAddressString = (str: string, length: number) => {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  }
  return str;
};

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  const second = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

export function timeAgo(timestamp: string | number | Date) {
  const now = new Date();
  const timeDiff = now.getTime() - new Date(timestamp).getTime();

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Approximation
  const years = Math.floor(days / 365); // Approximation

  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (days < 7) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (months < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
}

export function formatStatus(status: string): string {
  const spacedStatus = status.split('_').join(' ');

  return spacedStatus[0].toUpperCase() + spacedStatus.slice(1).toLowerCase();
}
