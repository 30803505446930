import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function VerifiedLogo(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_10_10615" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.536621" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10_10615)">
        <path
          d="M11.4868 12.6999L10.0368 11.2749C9.85348 11.0915 9.62432 10.9999 9.34932 10.9999C9.07432 10.9999 8.83682 11.0999 8.63682 11.2999C8.45348 11.4832 8.36182 11.7165 8.36182 11.9999C8.36182 12.2832 8.45348 12.5165 8.63682 12.6999L10.7868 14.8499C10.9868 15.0499 11.2201 15.1499 11.4868 15.1499C11.7535 15.1499 11.9868 15.0499 12.1868 14.8499L16.4368 10.5999C16.6368 10.3999 16.7326 10.1665 16.7243 9.89987C16.716 9.63321 16.6201 9.39987 16.4368 9.19987C16.2368 8.99987 15.9993 8.89571 15.7243 8.88737C15.4493 8.87904 15.2118 8.97487 15.0118 9.17487L11.4868 12.6999ZM8.68682 21.7499L7.23682 19.2999L4.48682 18.6999C4.23682 18.6499 4.03682 18.5207 3.88682 18.3124C3.73682 18.104 3.67848 17.8749 3.71182 17.6249L3.98682 14.7999L2.11182 12.6499C1.94515 12.4665 1.86182 12.2499 1.86182 11.9999C1.86182 11.7499 1.94515 11.5332 2.11182 11.3499L3.98682 9.19987L3.71182 6.37487C3.67848 6.12487 3.73682 5.89571 3.88682 5.68737C4.03682 5.47904 4.23682 5.34987 4.48682 5.29987L7.23682 4.69987L8.68682 2.24987C8.82015 2.03321 9.00348 1.88737 9.23682 1.81237C9.47015 1.73737 9.70348 1.74987 9.93682 1.84987L12.5368 2.94987L15.1368 1.84987C15.3702 1.74987 15.6035 1.73737 15.8368 1.81237C16.0702 1.88737 16.2535 2.03321 16.3868 2.24987L17.8368 4.69987L20.5868 5.29987C20.8368 5.34987 21.0368 5.47904 21.1868 5.68737C21.3368 5.89571 21.3951 6.12487 21.3618 6.37487L21.0868 9.19987L22.9618 11.3499C23.1285 11.5332 23.2118 11.7499 23.2118 11.9999C23.2118 12.2499 23.1285 12.4665 22.9618 12.6499L21.0868 14.7999L21.3618 17.6249C21.3951 17.8749 21.3368 18.104 21.1868 18.3124C21.0368 18.5207 20.8368 18.6499 20.5868 18.6999L17.8368 19.2999L16.3868 21.7499C16.2535 21.9665 16.0702 22.1124 15.8368 22.1874C15.6035 22.2624 15.3702 22.2499 15.1368 22.1499L12.5368 21.0499L9.93682 22.1499C9.70348 22.2499 9.47015 22.2624 9.23682 22.1874C9.00348 22.1124 8.82015 21.9665 8.68682 21.7499Z"
          fill="#04D3FF"
        />
      </g>
    </Icon>
  );
}
