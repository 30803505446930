import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function BellIcon(props: IconProps) {
  return (
    <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_808_5521" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.333252" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_808_5521)">
        <path
          d="M5.33325 19C5.04992 19 4.81242 18.9042 4.62075 18.7125C4.42909 18.5208 4.33325 18.2833 4.33325 18C4.33325 17.7167 4.42909 17.4792 4.62075 17.2875C4.81242 17.0958 5.04992 17 5.33325 17H6.33325V10C6.33325 8.61667 6.74992 7.3875 7.58325 6.3125C8.41659 5.2375 9.49992 4.53333 10.8333 4.2V3.5C10.8333 3.08333 10.9791 2.72917 11.2708 2.4375C11.5624 2.14583 11.9166 2 12.3333 2C12.7499 2 13.1041 2.14583 13.3958 2.4375C13.6874 2.72917 13.8333 3.08333 13.8333 3.5V4.2C15.1666 4.53333 16.2499 5.2375 17.0833 6.3125C17.9166 7.3875 18.3333 8.61667 18.3333 10V17H19.3333C19.6166 17 19.8541 17.0958 20.0458 17.2875C20.2374 17.4792 20.3333 17.7167 20.3333 18C20.3333 18.2833 20.2374 18.5208 20.0458 18.7125C19.8541 18.9042 19.6166 19 19.3333 19H5.33325ZM12.3333 22C11.7833 22 11.3124 21.8042 10.9208 21.4125C10.5291 21.0208 10.3333 20.55 10.3333 20H14.3333C14.3333 20.55 14.1374 21.0208 13.7458 21.4125C13.3541 21.8042 12.8833 22 12.3333 22ZM8.33325 17H16.3333V10C16.3333 8.9 15.9416 7.95833 15.1583 7.175C14.3749 6.39167 13.4333 6 12.3333 6C11.2333 6 10.2916 6.39167 9.50825 7.175C8.72492 7.95833 8.33325 8.9 8.33325 10V17Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
