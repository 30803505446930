import { IUser, LoginPayload, LoginRes } from '../interfaces/components';
import axios from './axios';

export const userLoginApi = async (data: LoginPayload) => {
  try {
    const endPoint = `api/user/login/?auth_type=WALLET`;
    const res = await axios.post<LoginRes>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const challengeApi = async (wallet_address: string) => {
  try {
    const endPoint = `api/user/challenge/`;
    const res = await axios.get<{
      data: string;
      status: string;
    }>(endPoint, { params: { wallet_address } });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const currentUserApi = async () => {
  try {
    const endPoint = `api/user/current/`;
    const res = await axios.get<{
      data: IUser;
      status: string;
    }>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
