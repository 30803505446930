import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { OwnerObject } from '../../interfaces/components';
import { useNavigate } from 'react-router-dom';
import VerifiedLogo from '../../Icons/Verified';

interface NftOwnerProps {
  nftOwner: OwnerObject;
  ownerName?: string;
}

export default function NftOwner(props: NftOwnerProps) {
  const { nftOwner, ownerName } = props;
  const navigate = useNavigate();

  const onOwnerClick = (owner: OwnerObject) => {
    navigate(`/users-profile/${owner?.twitter_username}`, { state: { owner } });
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px" marginBottom="8px">
      <Text color="rgba(255, 255, 255, 0.50)" fontSize="14px">
        Creator
      </Text>
      {ownerName ? (
        <Box
          display="flex"
          justifyContent="center"
          gap="6px"
          // onClick={(event) => {
          //   onOwnerClick(nftOwner);
          //   event.stopPropagation();
          // }}
        >
          <Text
            color="#FFFFFF"
            fontSize="14px"
            fontWeight="600"
            display="inline-block" // Ensure the bg on hover works
            _hover={{
              color: '#43BDD7',
            }}
            cursor="pointer"
          >
            {ownerName}
          </Text>
          <VerifiedLogo />
        </Box>
      ) : nftOwner?.twitter_username ? (
        <Box
          display="flex"
          justifyContent="center"
          gap="6px"
          onClick={(event) => {
            onOwnerClick(nftOwner);
            event.stopPropagation();
          }}
        >
          <Text
            color="#FFFFFF"
            fontSize="14px"
            fontWeight="600"
            textDecoration="none" // Ensure the bg on hover works
            _hover={{
              textDecoration: 'underline',
              color: '#04D3FF',
            }}
          >
            {nftOwner?.twitter_username}
          </Text>
          <VerifiedLogo />
        </Box>
      ) : (
        <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
          Anonymous
        </Text>
      )}
    </Box>
  );
}
