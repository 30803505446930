import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function TickIcon(props: IconProps) {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_228_17763" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
        <rect x="0.666626" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_228_17763)">
        <path
          d="M9.49992 11.5001L7.70825 9.70842C7.55547 9.55564 7.36103 9.47925 7.12492 9.47925C6.88881 9.47925 6.69436 9.55564 6.54159 9.70842C6.38881 9.86119 6.31242 10.0556 6.31242 10.2917C6.31242 10.5279 6.38881 10.7223 6.54159 10.8751L8.91659 13.2501C9.08325 13.4167 9.2777 13.5001 9.49992 13.5001C9.72214 13.5001 9.91659 13.4167 10.0833 13.2501L14.7916 8.54175C14.9444 8.38897 15.0208 8.19453 15.0208 7.95841C15.0208 7.7223 14.9444 7.52786 14.7916 7.37508C14.6388 7.2223 14.4444 7.14592 14.2083 7.14592C13.9721 7.14592 13.7777 7.2223 13.6249 7.37508L9.49992 11.5001ZM10.6666 18.3334C9.51381 18.3334 8.43047 18.1147 7.41658 17.6772C6.4027 17.2397 5.52075 16.6459 4.77075 15.8959C4.02075 15.1459 3.427 14.264 2.9895 13.2501C2.552 12.2362 2.33325 11.1529 2.33325 10.0001C2.33325 8.8473 2.552 7.76397 2.9895 6.75008C3.427 5.73619 4.02075 4.85425 4.77075 4.10425C5.52075 3.35425 6.4027 2.7605 7.41658 2.323C8.43047 1.8855 9.51381 1.66675 10.6666 1.66675C11.8194 1.66675 12.9027 1.8855 13.9166 2.323C14.9305 2.7605 15.8124 3.35425 16.5624 4.10425C17.3124 4.85425 17.9062 5.73619 18.3437 6.75008C18.7812 7.76397 18.9999 8.8473 18.9999 10.0001C18.9999 11.1529 18.7812 12.2362 18.3437 13.2501C17.9062 14.264 17.3124 15.1459 16.5624 15.8959C15.8124 16.6459 14.9305 17.2397 13.9166 17.6772C12.9027 18.1147 11.8194 18.3334 10.6666 18.3334Z"
          fill="#04D3FF"
        />
      </g>
    </Icon>
  );
}
