import React from 'react';

export default function LinkWalletIcon() {
  return (
    <svg width="25px" height="24px" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5002 13.0292C22.5002 13.5792 22.0602 14.0292 21.5002 14.0492H19.5402C18.4602 14.0492 17.4702 13.2592 17.3802 12.1792C17.3202 11.5492 17.5602 10.9592 17.9802 10.5492C18.3502 10.1692 18.8602 9.94922 19.4202 9.94922H21.5002C22.0602 9.96922 22.5002 10.4192 22.5002 10.9692V13.0292Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.54 15.55C17.64 15.55 16.04 14.12 15.88 12.3C15.79 11.26 16.17 10.22 16.93 9.48C17.57 8.82 18.46 8.45 19.42 8.45H20.97C21.26 8.45 21.5 8.21 21.47 7.92C21.25 5.49 19.64 3.83 17.25 3.55C17.01 3.51 16.76 3.5 16.5 3.5H7.5C7.22 3.5 6.95 3.52 6.69 3.56C4.14 3.88 2.5 5.78 2.5 8.5V15.5C2.5 18.26 4.74 20.5 7.5 20.5H16.5C19.3 20.5 21.23 18.75 21.47 16.08C21.5 15.79 21.26 15.55 20.97 15.55H19.54ZM7.5 9.75C7.09 9.75 6.75 9.41 6.75 9C6.75 8.59 7.09 8.25 7.5 8.25H13.5C13.91 8.25 14.25 8.59 14.25 9C14.25 9.41 13.91 9.75 13.5 9.75H7.5Z"
        fill="white"
      />
    </svg>
  );
}
