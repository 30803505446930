import { ChakraProvider } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { CustomTheme } from './theme/theme';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Overlay from './components/common/Overlay';
import Login from './components/login/Login';
import Main from './components/main/Main';
import UnauthorizedOverlay from './components/common/UnauthorizedOverlay';
import LinkWallet from './components/linkWallet/LinkWallet';
import MintNft from './components/Mint/MintNft';
import MintedNft from './components/Mint/MintedNft';
import { AppStoreState, useAppStore } from './store';
import TwitterAuth from './components/TwiiterAuth/TwitterAuth';
import HomeMain from './components/home/HomeMain';
import MyProfile from './components/profile/Myprofile';
import NftOverViewMain from './components/nftOverview/NftOverviewMain';
import Resources from './components/Content/Resources';
import PrivacyPolicy from './components/Content/PrivacyPolicy';
import TermsAndCondition from './components/Content/TermsAndConditions';
import AllNftsMain from './components/allNfts/allNftsMain';
import CompetitionOverviewMain from './components/competitionOverview/CompetitionOverviewMain';
import SingleCompetitionMain from './components/singleCompetition/SingleCompetitionMain';
import MyProgressMain from './components/progress/MyProgressMain';
import LoginWithXpadButton from './components/xpadAuth';
import AuthCallback from './components/xpadAuth/authCallback';
import { useAccount, useSwitchChain } from 'wagmi';
import UsersProfileMain from './components/otherUsersProfile/UsersProfileMain';

function AuthenticatedRoutes() {
  const { chain, address } = useAccount();
  const { switchChain } = useSwitchChain();

  useEffect(() => {
    if (chain?.id !== 11155111) {
      switchChain({ chainId: 11155111 });
    }
  }, [chain?.id]);

  return (
    <ChakraProvider theme={CustomTheme}>
      <BrowserRouter>
        <Overlay>
          <Routes>
            <Route path="/all-set-screen" element={<Main />} />
            <Route
              path="/mint-nft"
              element={
                <MintNft
                  heading="Mint your own NFT"
                  subHeading="Paste the link of the tweet you’d like to mint"
                  btnText={!address ? 'Connect wallet' : 'Mint now'}
                />
              }
            />
            <Route path="/minted-nft" element={<MintedNft />} />
            <Route path="/link-wallet" element={<LinkWallet />} />
            <Route path="/nfts" element={<AllNftsMain />} />
            <Route path="/competitions" element={<CompetitionOverviewMain />} />
            <Route path="/competitions/:id" element={<SingleCompetitionMain />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/users-profile/:id" element={<UsersProfileMain />} />
            <Route path="/my-profile/progress" element={<MyProgressMain />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsAndCondition" element={<TermsAndCondition />} />
            <Route path="/nfts/:id" element={<NftOverViewMain />} />
            <Route path="/" element={<HomeMain />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Overlay>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function UnAuthenticatedRoutes() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <UnauthorizedOverlay>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/authenticate" element={<TwitterAuth />} />
            <Route path="/" element={<HomeMain />} />
            <Route path="/nfts" element={<AllNftsMain />} />
            <Route path="/competitions" element={<CompetitionOverviewMain />} />
            <Route path="/competitions/:id" element={<SingleCompetitionMain />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/users-profile/:id" element={<UsersProfileMain />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsAndCondition" element={<TermsAndCondition />} />
            <Route path="/nfts/:id" element={<NftOverViewMain />} />
            <Route path="/xpad-login/" element={<LoginWithXpadButton />} />
            <Route path="/auth-callback/" element={<AuthCallback />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </UnauthorizedOverlay>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const App = () => {
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);

  if (accessToken) {
    return <AuthenticatedRoutes />;
  }
  return <UnAuthenticatedRoutes />;
};

export default App;
