import { Icon } from '@chakra-ui/icons';
import React from 'react';

interface CopyIconProps {
  onClick: () => void;
}

export default function CopyIcon(props: CopyIconProps) {
  return (
    <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg " {...props}>
      <mask id="mask0_10_10441" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10_10441)">
        <path
          d="M6.75 13.5002C6.3375 13.5002 5.98437 13.3534 5.69062 13.0596C5.39687 12.7659 5.25 12.4127 5.25 12.0002V3.00024C5.25 2.58774 5.39687 2.23462 5.69062 1.94087C5.98437 1.64712 6.3375 1.50024 6.75 1.50024H13.5C13.9125 1.50024 14.2656 1.64712 14.5594 1.94087C14.8531 2.23462 15 2.58774 15 3.00024V12.0002C15 12.4127 14.8531 12.7659 14.5594 13.0596C14.2656 13.3534 13.9125 13.5002 13.5 13.5002H6.75ZM6.75 12.0002H13.5V3.00024H6.75V12.0002ZM3.75 16.5002C3.3375 16.5002 2.98438 16.3534 2.69062 16.0596C2.39688 15.7659 2.25 15.4127 2.25 15.0002V5.25024C2.25 5.03774 2.32187 4.85962 2.46562 4.71587C2.60937 4.57212 2.7875 4.50024 3 4.50024C3.2125 4.50024 3.39062 4.57212 3.53437 4.71587C3.67812 4.85962 3.75 5.03774 3.75 5.25024V15.0002H11.25C11.4625 15.0002 11.6406 15.0721 11.7844 15.2159C11.9281 15.3596 12 15.5377 12 15.7502C12 15.9627 11.9281 16.1409 11.7844 16.2846C11.6406 16.4284 11.4625 16.5002 11.25 16.5002H3.75Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
    </Icon>
  );
}
