import { UserLevelResponse } from '../../interfaces/components';
import axios from '../axios';

export const getUserLevel = async (): Promise<UserLevelResponse> => {
  try {
    const endPoint = `/api/user/level/`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUserLookupLevel = async (walletAddress: string): Promise<UserLevelResponse[]> => {
  try {
    const endPoint = `/api/user/lookup/level/`;
    const res = await axios.get(endPoint, {
      params: {
        wallet_address: walletAddress,
      },
    });
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
