import { Box } from '@chakra-ui/react';
import React from 'react';
import UsersProfileHero from './UsersProfileHero';
import OtherUserProfileOverview from './UsersProfileOverview';
import { useLocation } from 'react-router-dom';

export default function UsersProfileMain() {
  const location = useLocation();
  const { owner } = location.state || {};

  return (
    <>
      <Box
        display="flex"
        zIndex={2}
        flexDirection="column"
        marginBottom="20px"
        maxHeight="100%"
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for WebKit browsers
          },
          '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
          'scrollbar-width': 'none', // Hide scrollbar for Firefox
        }}
        maxWidth="1400px"
      >
        <UsersProfileHero owner={owner} />
        <OtherUserProfileOverview owner={owner} />
      </Box>
    </>
  );
}
