import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export default function PrivacyPolicy() {
  return (
    <Box>
      <Text color="#fff"> PrivacyPolicy here</Text>
    </Box>
  );
}
