import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function EclipseIcon(props: IconProps) {
  return (
    <Icon width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg>
        <rect y="0.5" width="8" height="8" rx="4" />
      </svg>
    </Icon>
  );
}
