import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useToast, Box, Text } from '@chakra-ui/react';
import { useUser } from '../../hooks';
import LoadingSpinner from '../common/LoadingSpinner';

export default function TwitterAuth() {
  const [searchParams] = useSearchParams();
  const stat = searchParams.get('state');
  const code = searchParams.get('code');
  // const navigate = useNavigate();
  const { loginTwitter } = useUser();
  const toast = useToast();

  const loginTwit = async () => {
    if (stat && code) {
      try {
        const res = await loginTwitter({ state: stat, code: code });
        if (res) {
          // navigate('/link-wallet');
        }
      } catch (e) {
        toast({
          title: 'Login error',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (stat && code) {
      loginTwit();
    }
  }, [stat, code]);

  return (
    <Box className="Page" textAlign="center" mt={5}>
      <Text>Authenticate Your Twitter, please wait ...</Text>
      <LoadingSpinner />
    </Box>
  );
}
