import { Tab } from '@chakra-ui/react';
import React from 'react';
import { ReactNode } from 'react';

interface CustomTabProps {
  isCompetition?: boolean;
  isActive: boolean;
  children: ReactNode;
}

export const CustomTab: React.FC<CustomTabProps> = ({ isActive, children, isCompetition }) => {
  return (
    <Tab
      width={['50%', '150px', isCompetition ? '220px' : '174px']}
      borderRadius="15px"
      bg={isActive ? '#353535' : 'transparent'}
      display="flex"
      alignItems="center"
      gap="1"
      color={isActive ? '#04D3FF' : '#909090'}
      _hover={{
        bg: '#353535',
        color: '#04D3FF',
      }}
    >
      {children}
    </Tab>
  );
};
