import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CloseIcon from '../../Icons/Close';
import { AppStoreState, useAppStore } from '../../store';
import { getMyHistory } from '../../api/competitions/apis';
import { HistoryObject, HistoryResponse } from '../../interfaces/components';
import CloseIconGray from '../../Icons/CloseIconGrey';
import XpHistoryDetails from '../common/XpHistoryDetails';
import Pagination from '../common/Pagination';

interface LeaderBoardDrawerProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const XpLeaderBoardDrawer = (props: LeaderBoardDrawerProps) => {
  const { isOpen, onClose } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const historyItemsList = useAppStore((state: AppStoreState) => state.historyItemsList);
  const setHistoryItemsList = useAppStore((state: AppStoreState) => state.setHistoryItemsList);
  const toast = useToast();

  // Helper function to format the date
  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day}, ${month}, ${year}`;
  };

  useEffect(() => {
    console.log('isOPen', isOpen);
    // if (isOpen) {
    fetchHistory(currentPage, 10);
    // }
  }, [isOpen, currentPage]);

  const fetchHistory = async (page: number, recordsPerPage: number) => {
    try {
      setIsDataLoading(true);
      const res: HistoryResponse = await getMyHistory(page, recordsPerPage);
      setHistoryItemsList(res);
      setCurrentPage(res?.current_page);
      setIsDataLoading(false);
    } catch (error: any) {
      setIsDataLoading(false);
      toast({
        title: 'Something went wrong while xp history',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePrevPage = () => {
    console.log('handlePrevPage');
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function onPaginationitemClick(pageToFetch: number): void {
    console.log('onPaginationitemClick', pageToFetch);
    setCurrentPage(pageToFetch);
  }

  function handleNextPage(): void {
    console.log('handle next page');
    if (historyItemsList?.count && currentPage < historyItemsList?.count) {
      setCurrentPage(currentPage + 1);
    }
  }

  const historyItemsGroupedByDate: Record<string, HistoryObject[]> = (historyItemsList?.results ?? []).reduce(
    (acc, transaction) => {
      const date = formatDate(transaction.transaction_timestamp);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(transaction);
      return acc;
    },
    {} as Record<string, HistoryObject[]>,
  );

  const handleClose = () => {
    console.log('onClose triggered');
    setCurrentPage(1);
    onClose(); // This should only be triggered when you explicitly want to close the drawer.
  };

  return (
    <>
      {isDataLoading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center"></Box>
      ) : (
        <Drawer isOpen={isOpen} placement={'right'} onClose={onClose} size="lg">
          <DrawerOverlay />
          <DrawerContent
            width={['100% !important', '100% !important', '473px !important']}
            className="leader-board-drawer"
            // borderRadius={['44px', '44px', 'unset']}
            // right={['unset !important', 'unset !important', '0px !important']}
            // marginTop={['12px', '12px', 'unset']}
            // marginBottom={['12px', '12px', 'unset']}
          >
            <Box
              display={['none', 'none', 'flex']}
              borderRadius="15px"
              background="#2C2C2C"
              width="58px"
              height="52px"
              position="absolute"
              top="49px"
              left="-92px"
              onClick={handleClose}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
            >
              <CloseIconGray />
            </Box>

            <DrawerHeader pt="36px" pb="23px">
              <Box
                borderRadius="24px"
                padding={['10px', '10px', '24px 36px']}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin={['18px', '18px', 'unset']}
                height="77px"
                width="-webkit-fill-available"
                border="0.5px solid rgba(255, 255, 255, 0.10)"
                background=" rgba(255, 255, 255, 0.05)"
              >
                <Text
                  color="#fff"
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontSize={['16px', '16px', '24px']}
                  fontStyle=" normal"
                  fontWeight="800"
                  lineHeight="normal"
                >
                  History
                </Text>
                <Box
                  position="absolute"
                  right="55px"
                  borderRadius="15px"
                  borderBottom="2px solid #00C22B"
                  background=" #3AFF65"
                  width="48px"
                  height="48px"
                  onClick={onClose}
                  display={['flex', 'flex', 'none']}
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                >
                  <CloseIcon fill="black" />
                </Box>
              </Box>
            </DrawerHeader>
            <DrawerBody className="custom-scroll" pt="0px" pl="24px" pr="24px">
              {Object.entries(historyItemsGroupedByDate).map(([date, historyItems], i) => (
                <XpHistoryDetails key={i} date={date} historyItems={historyItems} />
              ))}
            </DrawerBody>
            <DrawerFooter
              height="96px"
              borderTop="1px solid rgba(255, 255, 255, 0.10)"
              borderLeft="1px solid rgba(255, 255, 255, 0.10)"
              background="#212121"
              boxShadow="0px -6px 34px 0px rgba(0, 0, 0, 0.55);"
              justifyContent="center"
            >
              <Pagination
                isDrawer
                handlePreviousPage={handlePrevPage}
                totalPages={historyItemsList?.total_pages || 0}
                currentPage={currentPage}
                onPaginationitemClick={onPaginationitemClick}
                handleNextPage={handleNextPage}
              />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
