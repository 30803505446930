import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function NothingToSee(props: IconProps) {
  return (
    <Icon width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M41.6684 7.105L8.6823 9.39695C4.06748 9.7176 0.586354 13.7186 0.907003 18.3334L4.25857 66.5697C4.57922 71.1845 8.58022 74.6656 13.195 74.345L52.0309 71.6466C56.6458 71.3259 60.1269 67.3249 59.8062 62.7101L56.8329 19.9167C56.6716 17.5961 55.5526 15.4471 53.7438 13.9844L47.5158 8.94795C45.8696 7.61672 43.7804 6.95826 41.6684 7.105Z"
        fill="url(#paint0_linear_1949_1632)"
        fillOpacity="0.34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.079 1.44691L61.6475 7.15879C63.7327 7.5245 65.6022 8.6663 66.8797 10.3545L71.713 16.7414C73.1167 18.5964 73.6858 20.9515 73.284 23.2428L65.8739 65.4944C65.0748 70.0508 60.7332 73.0967 56.1768 72.2976L17.8325 65.5728C13.2761 64.7737 10.2302 60.4322 11.0293 55.8758L19.3819 8.25011C20.1811 3.6937 24.5226 0.647803 29.079 1.44691ZM40.3182 46.196C40.7703 45.8788 41.394 45.9882 41.7112 46.4403L46.2487 52.908C46.5895 53.3937 47.0584 53.6889 47.6553 53.7936C48.2523 53.8983 48.7936 53.7803 49.2794 53.4395C49.7651 53.0987 50.0604 52.6298 50.1651 52.0329C50.2698 51.4359 50.1517 50.8946 49.8109 50.4088L45.2733 43.9411C44.9562 43.489 45.0655 42.8654 45.5176 42.5482L51.9853 38.0106C52.4711 37.6698 52.7663 37.201 52.871 36.604C52.9757 36.0071 52.8576 35.4657 52.5168 34.98C52.176 34.4942 51.7072 34.199 51.1102 34.0943C50.5133 33.9896 49.9719 34.1077 49.4862 34.4484L43.0185 38.986C42.5664 39.3032 41.9427 39.1938 41.6255 38.7417L37.088 32.274C36.7472 31.7883 36.2783 31.4931 35.6814 31.3884C35.0844 31.2837 34.5431 31.4017 34.0573 31.7425C33.5716 32.0833 33.2763 32.5522 33.1716 33.1491C33.0669 33.7461 33.185 34.2874 33.5258 34.7732L38.0634 41.2409C38.3805 41.693 38.2712 42.3166 37.8191 42.6338L31.3514 47.1714C30.8656 47.5122 30.5704 47.981 30.4657 48.578C30.361 49.1749 30.4791 49.7163 30.8199 50.202C31.1607 50.6878 31.6295 50.983 32.2265 51.0877C32.8234 51.1924 33.3648 51.0744 33.8505 50.7336L40.3182 46.196Z"
        fill="url(#paint1_linear_1949_1632)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1949_1632"
          x1="28.1003"
          y1="8.04775"
          x2="32.613"
          y2="72.9958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D3FF" />
          <stop offset="1" stopColor="#3AFF65" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1949_1632"
          x1="48.2511"
          y1="4.80933"
          x2="37.0047"
          y2="68.9352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D3FF" />
          <stop offset="1" stopColor="#3AFF65" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
