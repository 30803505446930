import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';

interface CommentSpinnerProps {
  numberOfComments: number | null;
}

export default function CommentSpinner(props: CommentSpinnerProps) {
  const { numberOfComments } = props;
  return (
    <Box style={{ marginLeft: 4 }}>
      {numberOfComments === null || numberOfComments === undefined ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" ml="4px">
          <Spinner thickness="2px" speed="0.65s" emptyColor="white" color="#ffffff66" size="sm" />
        </Box>
      ) : (
        `(${numberOfComments})`
      )}
    </Box>
  );
}
