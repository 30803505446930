import { Icon } from '@chakra-ui/icons';
import React from 'react';

export default function TwitterIcon() {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3263 1.90392H21.6998L14.3297 10.3274L23 21.7899H16.2112L10.894 14.838L4.80995 21.7899H1.43443L9.31743 12.78L1 1.90392H7.96111L12.7674 8.25826L18.3263 1.90392ZM17.1423 19.7707H19.0116L6.94539 3.81706H4.93946L17.1423 19.7707Z"
        fill="white"
      />
    </Icon>
  );
}
