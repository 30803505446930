import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function AddToNftSuccessIcon(props: IconProps) {
  return (
    <Icon width="96px" height="96px" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_b_1177_10516)">
        <path
          d="M0.25 48C0.25 21.6284 21.6284 0.25 48 0.25C74.3716 0.25 95.75 21.6284 95.75 48C95.75 74.3716 74.3716 95.75 48 95.75C21.6284 95.75 0.25 74.3716 0.25 48Z"
          stroke="white"
          strokeOpacity="0.1"
          strokeWidth="0.5"
        />
        <mask id="mask0_1177_10516" maskUnits="userSpaceOnUse" x="16" y="16" width="64" height="64">
          <rect x="16" y="16" width="64" height="64" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1177_10516)">
          <path
            d="M41.4673 56.4L64.0673 33.8C64.6006 33.2667 65.2229 33 65.934 33C66.6451 33 67.2673 33.2667 67.8006 33.8C68.334 34.3333 68.6006 34.9667 68.6006 35.7C68.6006 36.4333 68.334 37.0667 67.8006 37.6L43.334 62.1333C42.8006 62.6667 42.1784 62.9333 41.4673 62.9333C40.7562 62.9333 40.134 62.6667 39.6006 62.1333L28.134 50.6667C27.6006 50.1333 27.3451 49.5 27.3673 48.7667C27.3895 48.0333 27.6673 47.4 28.2006 46.8667C28.734 46.3333 29.3673 46.0667 30.1006 46.0667C30.834 46.0667 31.4673 46.3333 32.0006 46.8667L41.4673 56.4Z"
            fill="#04D3FF"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_1177_10516"
          x="-44"
          y="-44"
          width="184"
          height="184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="22" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1177_10516" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1177_10516" result="shape" />
        </filter>
      </defs>
    </Icon>
  );
}
