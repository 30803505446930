import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CrownIconBronze(props: IconProps) {
  return (
    <Icon
      width="68px !important"
      height="52px !important"
      viewBox="0 0 68 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1014_25688)">
        <g filter="url(#filter1_dii_1014_25688)">
          <path
            d="M18.7528 19.5469L10.1785 13.3233C8.21906 11.9011 5.50483 13.4926 5.78919 15.8971L7.79666 32.8717C7.92322 33.9419 8.66136 34.8445 9.68548 35.18C22.9713 39.5328 29.4865 40.9327 49.2828 43.1567C50.3841 43.2805 51.4568 42.7356 52.0052 41.7726L60.4331 26.9707C61.6311 24.8666 59.7506 22.344 57.392 22.8912L47.0712 25.2857C45.7747 25.5865 44.447 24.9251 43.9061 23.709L37.7593 9.88988C36.9831 8.14493 34.7182 7.68593 33.3239 8.99104L22.2821 19.3269C21.3104 20.2365 19.83 20.3288 18.7528 19.5469Z"
            fill="url(#paint0_linear_1014_25688)"
          />
          <path
            d="M9.77071 13.8852L18.345 20.1088C19.6914 21.0861 21.542 20.9707 22.7566 19.8338L33.7984 9.49792C34.8441 8.51909 36.5428 8.86334 37.1249 10.1721L43.2717 23.9912C43.9479 25.5113 45.6075 26.338 47.2282 25.962L57.5489 23.5675C59.3179 23.1571 60.7282 25.0491 59.8297 26.6271L51.4018 41.429C50.991 42.1505 50.1863 42.5596 49.3603 42.4668C29.5977 40.2465 23.1312 38.8546 9.90164 34.5202C9.13468 34.2689 8.581 33.5921 8.48615 32.7901L6.47869 15.8155C6.26542 14.0121 8.30109 12.8185 9.77071 13.8852Z"
            stroke="url(#paint1_linear_1014_25688)"
            strokeOpacity="0.5"
            strokeWidth="1.3886"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1014_25688"
          x="-5.55429"
          y="-2.38365"
          width="76.546"
          height="57.0629"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.77721" />
          <feGaussianBlur stdDeviation="2.77721" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1014_25688" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1014_25688" result="shape" />
        </filter>
        <filter
          id="filter1_dii_1014_25688"
          x="4.37995"
          y="8.24023"
          width="57.8134"
          height="37.7108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.3886" />
          <feGaussianBlur stdDeviation="0.694302" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1014_25688" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1014_25688" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-45.8239" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1014_25688" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.77721" />
          <feGaussianBlur stdDeviation="0.694302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.696 0 0 0 0 0.525 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_1014_25688" result="effect3_innerShadow_1014_25688" />
        </filter>
        <linearGradient
          id="paint0_linear_1014_25688"
          x1="38.4294"
          y1="-4.80987"
          x2="29.2642"
          y2="40.4169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B75800" />
          <stop offset="0.0966666" stopColor="#DA7600" />
          <stop offset="0.186667" stopColor="#FFA740" />
          <stop offset="0.366667" stopColor="#FFBE90" />
          <stop offset="0.761667" stopColor="#FF993C" />
          <stop offset="1" stopColor="#FFBB95" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1014_25688"
          x1="38.4294"
          y1="-4.80987"
          x2="29.2642"
          y2="40.4169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
