import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Box,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { BrowseNftsModal } from './BrowseNfts';
import { AppStoreState, useAppStore } from '../../store';
import { loginWithXpad } from '../xpadAuth';

interface SubmitNftModalProps {
  isModalOpen: boolean;
  onModalOpen: () => void;
  onModalClose: () => void;
  submitClicked?: boolean;
  setSubmitClicked?: (submitted: boolean) => void;
  heading: string;
  subHeading: string;
  buttonText: string;
  buttonBgColor: string;
  modalIcon: ReactNode;
}

export const SubmitNftModal = (props: SubmitNftModalProps) => {
  const {
    isModalOpen,
    onModalClose,
    submitClicked,
    setSubmitClicked,
    heading,
    subHeading,
    buttonBgColor,
    buttonText,
    modalIcon,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const setMintNowView = useAppStore((state: AppStoreState) => state.setMintNowView);

  const onBrowseNftClick = () => {
    if (accessToken) {
      if (buttonText === 'Browse NFTs') {
        onModalClose();
        onOpen();
      } else {
        setSubmitClicked && setSubmitClicked(false);
      }
    } else {
      toast({
        title: 'Login',
        description: 'Please login to continue',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      onModalClose();
      loginWithXpad();
    }
  };

  const onMintNowClick = () => {
    onModalClose();
    onOpen();
    setMintNowView(true);
  };

  return (
    <>
      <BrowseNftsModal
        isBrowseModalOpen={isOpen}
        onBrowseModalOpen={onOpen}
        onBrowseModalClose={onClose}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
      />
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isModalOpen}
        onClose={onModalClose}
      >
        <ModalOverlay />
        <ModalContent className="submit-nft-modal">
          <ModalBody pb={6} className="modal-body">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              {modalIcon}
              <Text color=" #FFF" fontSize="28px" fontWeight="700" marginTop="24px" textAlign="center">
                {heading}
              </Text>
            </Box>
            <Text
              color="rgba(255, 255, 255, 0.50)"
              fontSize="18px"
              fontWeight="500"
              textAlign="center"
              lineHeight="normal"
              marginTop="10px"
            >
              {subHeading}
            </Text>
          </ModalBody>

          <>
            {buttonText === 'Browse NFTs' ? (
              <ModalFooter className="modal-footer">
                <Button
                  backgroundColor={`${buttonBgColor}`}
                  _hover={{
                    color: 'white',
                    bg: 'rgba(255, 255, 255, 0.20)',
                  }}
                  className="browse-btn"
                  onClick={onBrowseNftClick}
                >
                  {buttonText}
                </Button>
                <Button
                  backgroundColor={'#118BCF'}
                  _hover={{
                    color: 'white',
                    bg: '#43BDD7',
                  }}
                  className="browse-btn"
                  onClick={() => onMintNowClick()}
                >
                  Mint now
                </Button>
              </ModalFooter>
            ) : (
              <ModalFooter className="modal-footer">
                <Button
                  backgroundColor={`${buttonBgColor}`}
                  _hover={{
                    color: 'white',
                    bg: 'rgba(255, 255, 255, 0.20)',
                  }}
                  className="browse-btn"
                  onClick={onBrowseNftClick}
                >
                  {buttonText}
                </Button>
              </ModalFooter>
            )}
          </>
        </ModalContent>
      </Modal>
    </>
  );
};
