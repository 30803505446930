import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function Hamburger(props: IconProps) {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="-0.00146484" width="48.0015" height="48" rx="14" fill="white" fillOpacity="0.1" />
      <mask id="mask0_513_4671" maskUnits="userSpaceOnUse" x="11" y="12" width="25" height="24">
        <rect x="11.9985" y="12" width="24.0015" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_513_4671)">
        <path
          d="M15.9991 30C15.7157 30 15.4782 29.9042 15.2865 29.7125C15.0949 29.5208 14.999 29.2833 14.999 29C14.999 28.7167 15.0949 28.4792 15.2865 28.2875C15.4782 28.0958 15.7157 28 15.9991 28H32.0001C32.2834 28 32.5209 28.0958 32.7126 28.2875C32.9043 28.4792 33.0001 28.7167 33.0001 29C33.0001 29.2833 32.9043 29.5208 32.7126 29.7125C32.5209 29.9042 32.2834 30 32.0001 30H15.9991ZM15.9991 25C15.7157 25 15.4782 24.9042 15.2865 24.7125C15.0949 24.5208 14.999 24.2833 14.999 24C14.999 23.7167 15.0949 23.4792 15.2865 23.2875C15.4782 23.0958 15.7157 23 15.9991 23H32.0001C32.2834 23 32.5209 23.0958 32.7126 23.2875C32.9043 23.4792 33.0001 23.7167 33.0001 24C33.0001 24.2833 32.9043 24.5208 32.7126 24.7125C32.5209 24.9042 32.2834 25 32.0001 25H15.9991ZM15.9991 20C15.7157 20 15.4782 19.9042 15.2865 19.7125C15.0949 19.5208 14.999 19.2833 14.999 19C14.999 18.7167 15.0949 18.4792 15.2865 18.2875C15.4782 18.0958 15.7157 18 15.9991 18H32.0001C32.2834 18 32.5209 18.0958 32.7126 18.2875C32.9043 18.4792 33.0001 18.7167 33.0001 19C33.0001 19.2833 32.9043 19.5208 32.7126 19.7125C32.5209 19.9042 32.2834 20 32.0001 20H15.9991Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
