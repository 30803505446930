import { useToast, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAppStore, AppStoreState } from '../../store';
import { getMyCompetitions } from '../../api/competitions/apis';
import { SubmitNftModal } from '../modals/SubmitNft';
import FeatureCardIcon from '../../Icons/FeatureCard';
import AddToNftSuccessIcon from '../../Icons/AddToNftSuccess';
import AddToNftFailureIcon from '../../Icons/AddToNftFailure';
import { CompetitionObject, OtherUserCompetitions } from '../../interfaces/components';
import Competition from './Competition';
import storage from '../../utils/storage';

interface MyCompetitionsProps {
  userCompetitions?: OtherUserCompetitions;
  isUserProfile?: boolean;
}

export default function MyCompetitions(props: MyCompetitionsProps) {
  const { userCompetitions, isUserProfile } = props;
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const setRerender = useAppStore((state: AppStoreState) => state.setRerender);
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const myCompetitionList = useAppStore((state: AppStoreState) => state.myCompetitionList);
  const setSelectedCompetitionId = useAppStore((state: AppStoreState) => state.setSelectedCompetitionId);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const setMyCompetitionList = useAppStore((state: AppStoreState) => state.setMyCompetitionList);
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const setMintNowView = useAppStore((state: AppStoreState) => state.setMintNowView);
  const user = useAppStore((state: AppStoreState) => state.user);
  const [submitClicked, setSubmitClicked] = useState(false);
  const addNftToCompetitionStatus = useAppStore((state: AppStoreState) => state.addNftToCompetitionStatus);
  const userFromStorage = storage.get('user');
  const currentUser = JSON.parse(userFromStorage || '{}');

  const fetchMyCompetitions = async (walletAddress: string, page: number, recordsPerPage: number) => {
    try {
      setLoading(true);
      const res = await getMyCompetitions(walletAddress, page, recordsPerPage);
      setMyCompetitionList(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching competition',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (location?.pathname === '/competitions') {
      setSelectedCompetitionId(null);
      !isUserProfile && fetchMyCompetitions(currentUser?.wallet_address, currentPage, 9);
    } else {
      !isUserProfile && fetchMyCompetitions(currentUser?.wallet_address, currentPage, 9);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname === '/competitions') {
      setSelectedCompetitionId(null);
      !isUserProfile && fetchMyCompetitions(currentUser?.wallet_address, currentPage, 9);
    } else {
      !isUserProfile && fetchMyCompetitions(currentUser?.wallet_address, currentPage, 9);
    }
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onPaginationitemClick = (pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  };

  const handleNextPage = () => {
    if (myCompetitionList?.total_pages && currentPage < myCompetitionList?.total_pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onParticipateClick = (competition: CompetitionObject) => {
    setSelectedCompetitionId(competition?.id);
    setSelectedNft(null);
    setMintNowView(false);
    onOpen();
  };

  useEffect(() => {
    if (user?.wallet_address === '') {
      if (location?.pathname === '/competitions') {
        setSelectedCompetitionId(null);
        !isUserProfile && fetchMyCompetitions('none', currentPage, 9);
      } else {
        !isUserProfile && fetchMyCompetitions('none', currentPage, 9);
      }
    } else {
      if (location?.pathname === '/competitions') {
        setSelectedCompetitionId(null);
        user?.wallet_address && !isUserProfile && fetchMyCompetitions(user?.wallet_address, currentPage, 9);
      } else {
        user?.wallet_address && !isUserProfile && fetchMyCompetitions(user?.wallet_address, currentPage, 9);
      }
    }
  }, [user]);

  return (
    <>
      {submitClicked && (
        <SubmitNftModal
          heading={addNftToCompetitionStatus === 'success' ? 'Submitted successfully' : 'Submission failed'}
          subHeading={
            addNftToCompetitionStatus === 'success'
              ? 'Your NFT has been submitted into the competition.'
              : 'Your NFT has been created, but failed to submit, please try to resubmit it later.'
          }
          buttonText={'Close'}
          buttonBgColor={addNftToCompetitionStatus === 'success' ? '#118BCF' : '#E13838'}
          isModalOpen={submitClicked}
          onModalOpen={onOpen}
          onModalClose={onClose}
          setSubmitClicked={setSubmitClicked}
          modalIcon={addNftToCompetitionStatus === 'success' ? <AddToNftSuccessIcon /> : <AddToNftFailureIcon />}
        />
      )}
      <SubmitNftModal
        isModalOpen={isOpen}
        onModalOpen={onOpen}
        onModalClose={onClose}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
        heading={'Submit your NFT'}
        subHeading={'Browse your NFTs or mint a new one to compete in this competition.'}
        buttonText={'Browse NFTs'}
        buttonBgColor={'#222'}
        modalIcon={<FeatureCardIcon />}
      />

      {userCompetitions ? (
        <Competition
          myCompetitionList={userCompetitions?.data}
          otherUserCompetition={true}
          currentPage={currentPage}
          totalPages={userCompetitions?.total_pages}
          reRender={reRender}
          setRerender={setRerender}
          onParticipateClick={onParticipateClick}
          handleNextPage={handleNextPage}
          onPaginationitemClick={onPaginationitemClick}
          handlePrevPage={handlePrevPage}
        />
      ) : (
        <Competition
          loading={loading}
          otherUserCompetition={false}
          myCompetitionList={myCompetitionList?.data}
          setRerender={setRerender}
          reRender={reRender}
          onParticipateClick={onParticipateClick}
          handleNextPage={handleNextPage}
          onPaginationitemClick={onPaginationitemClick}
          handlePrevPage={handlePrevPage}
          currentPage={currentPage}
          totalPages={myCompetitionList?.total_pages}
        />
      )}
    </>
  );
}
