import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function BellFilledIcon(props: IconProps) {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg>
        <mask
          id="mask0_2134_2419"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2134_2419)">
          <path d="M4.99927 19.0003C4.71593 19.0003 4.47843 18.9045 4.28677 18.7128C4.0951 18.5211 3.99927 18.2836 3.99927 18.0003C3.99927 17.717 4.0951 17.4795 4.28677 17.2878C4.47843 17.0961 4.71593 17.0003 4.99927 17.0003H5.99927V10.0003C5.99927 8.61697 6.41593 7.3878 7.24927 6.3128C8.0826 5.2378 9.16593 4.53364 10.4993 4.2003V3.5003C10.4993 3.08364 10.6451 2.72947 10.9368 2.43781C11.2284 2.14614 11.5826 2.00031 11.9993 2.00031C12.4159 2.00031 12.7701 2.14614 13.0618 2.43781C13.3534 2.72947 13.4993 3.08364 13.4993 3.5003V4.2003C14.8326 4.53364 15.9159 5.2378 16.7493 6.3128C17.5826 7.3878 17.9993 8.61697 17.9993 10.0003V17.0003H18.9993C19.2826 17.0003 19.5201 17.0961 19.7118 17.2878C19.9034 17.4795 19.9993 17.717 19.9993 18.0003C19.9993 18.2836 19.9034 18.5211 19.7118 18.7128C19.5201 18.9045 19.2826 19.0003 18.9993 19.0003H4.99927ZM11.9993 22.0003C11.4493 22.0003 10.9784 21.8045 10.5868 21.4128C10.1951 21.0211 9.99927 20.5503 9.99927 20.0003H13.9993C13.9993 20.5503 13.8034 21.0211 13.4118 21.4128C13.0201 21.8045 12.5493 22.0003 11.9993 22.0003Z" />
        </g>
      </svg>
    </Icon>
  );
}
