import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CompetitionCardIcon2(props: IconProps) {
  return (
    <Icon width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.666748" width="58.0021" height="58" rx="14" fill="white" fillOpacity="0.1" />
      <mask id="mask0_1021_318" maskUnits="userSpaceOnUse" x="12" y="12" width="35" height="34">
        <rect x="12.6667" y="12" width="34.0021" height="34" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1021_318)">
        <path
          d="M25.4175 24.7496V40.3329H35.3348V36.0829C35.3348 35.6816 35.4705 35.3451 35.7421 35.0736C36.0136 34.802 36.3501 34.6663 36.7515 34.6663H41.0018V24.7496H25.4175ZM22.584 40.3329V24.7142C22.584 23.935 22.8673 23.2739 23.434 22.7309C24.0007 22.1878 24.6737 21.9163 25.4529 21.9163H41.0018C41.781 21.9163 42.448 22.1937 43.0029 22.7486C43.5578 23.3034 43.8353 23.9704 43.8353 24.7496V34.9142C43.8353 35.292 43.7644 35.652 43.6228 35.9944C43.4811 36.3368 43.2804 36.6378 43.0206 36.8975L37.5661 42.3517C37.3064 42.6114 37.0053 42.8121 36.663 42.9538C36.3206 43.0954 35.9605 43.1663 35.5827 43.1663H25.4175C24.6383 43.1663 23.9712 42.8888 23.4163 42.334C22.8614 41.7791 22.584 41.1121 22.584 40.3329ZM15.5356 20.8538C15.3939 20.0746 15.5474 19.3722 15.9961 18.7465C16.4447 18.1208 17.0586 17.7371 17.8378 17.5954L33.2096 14.8684C33.9652 14.7267 34.65 14.8861 35.2639 15.3465C35.8778 15.8069 36.2793 16.4149 36.4682 17.1704L36.7161 18.2684C36.8342 18.7406 36.7633 19.1184 36.5036 19.4017C36.2438 19.685 35.9369 19.8503 35.5827 19.8975C35.2285 19.9447 34.892 19.8798 34.5733 19.7027C34.2545 19.5257 34.0361 19.201 33.918 18.7288L33.6701 17.6663L18.3337 20.3934L20.4588 32.5767C20.5297 32.9781 20.4588 33.3381 20.2463 33.6569C20.0338 33.9756 19.7269 34.1704 19.3254 34.2413C18.924 34.3121 18.5698 34.2354 18.2629 34.0111C17.9559 33.7868 17.767 33.4739 17.6962 33.0725L15.5356 20.8538Z"
          fill="#04D3FF"
        />
      </g>
    </Icon>
  );
}
