import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function CardIcon(props: IconProps) {
  return (
    <Icon width="34px" height="34px" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5613 27.6249C9.25392 27.6249 8.02467 27.1156 7.10041 26.1911C6.17591 25.2668 5.66669 24.0377 5.66669 22.7301C5.66669 21.4225 6.17566 20.1934 7.10017 19.2691L12.0826 14.2863C13.9911 12.3779 17.0962 12.3779 19.0043 14.2863C19.4808 14.7628 19.4808 15.5353 19.0043 16.0116C18.5282 16.4879 17.7552 16.4879 17.2792 16.0116C16.3225 15.0549 14.7653 15.0546 13.8079 16.0116L8.82571 20.9942C7.86875 21.9515 7.86875 23.5085 8.82571 24.4657C9.2893 24.9293 9.90564 25.1846 10.5613 25.1846C11.2169 25.1846 11.8332 24.9293 12.2968 24.4657L14.7653 21.9976C15.2421 21.5211 16.0143 21.5213 16.4906 21.9976C16.9669 22.4739 16.9671 23.2464 16.4906 23.7227L14.0221 26.1911C13.0976 27.1156 11.8686 27.6249 10.5613 27.6249Z"
        fill="#04D3FF"
      />
      <path
        d="M17.75 20.4362C16.4427 20.4362 15.2134 19.927 14.2889 19.0025C13.8124 18.5261 13.8124 17.7536 14.2889 17.2771C14.7654 16.801 15.5379 16.801 16.0142 17.2773C16.4778 17.7409 17.0944 17.9962 17.7498 17.9962C18.4051 17.9962 19.0217 17.7409 19.4851 17.2773L24.4677 12.2947C24.9313 11.8311 25.1865 11.2147 25.1865 10.5591C25.1865 9.90345 24.9313 9.28709 24.4677 8.82348C23.5108 7.86648 21.9533 7.86648 20.9964 8.82348L18.5281 11.2918C18.0521 11.7681 17.2793 11.7681 16.8028 11.2918C16.3263 10.8153 16.3263 10.0428 16.8028 9.56648L19.2716 7.09786C21.1796 5.18949 24.285 5.18949 26.193 7.09786C27.1175 8.0224 27.6267 9.25146 27.6267 10.5591C27.6267 11.8667 27.1175 13.0958 26.193 14.0203L21.2106 19.0027C20.2863 19.927 19.0573 20.4362 17.75 20.4362Z"
        fill="#04D3FF"
      />
    </Icon>
  );
}
