import { Icon } from '@chakra-ui/icons';
import React from 'react';

export default function PowerSettingIcon() {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_595_1208" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_595_1208)">
        <path
          d="M12.0011 22.2032C10.5859 22.2032 9.25802 21.9357 8.01735 21.4007C6.77685 20.8657 5.6976 20.1392 4.7796 19.2214C3.86177 18.3034 3.13535 17.2242 2.60035 15.9837C2.06535 14.743 1.79785 13.4151 1.79785 11.9999C1.79785 10.7912 1.99402 9.64508 2.38635 8.56141C2.77885 7.47758 3.32343 6.49841 4.0201 5.62391C4.22743 5.35075 4.47819 5.2185 4.77235 5.22716C5.06652 5.23583 5.32119 5.3315 5.53635 5.51416C5.75169 5.69666 5.89544 5.93816 5.9676 6.23866C6.03994 6.53933 5.94944 6.85324 5.6961 7.18041C5.19077 7.84774 4.79385 8.58833 4.50535 9.40216C4.21702 10.2158 4.07285 11.0817 4.07285 11.9999C4.07285 14.2172 4.83985 16.093 6.37385 17.6272C7.90802 19.1612 9.78377 19.9282 12.0011 19.9282C14.2184 19.9282 16.0942 19.1612 17.6284 17.6272C19.1624 16.093 19.9294 14.2172 19.9294 11.9999C19.9294 11.0817 19.7852 10.2158 19.4969 9.40216C19.2084 8.58833 18.8114 7.84774 18.3061 7.18041C18.0528 6.85324 17.9613 6.54033 18.0316 6.24166C18.1018 5.94316 18.2465 5.70258 18.4659 5.51991C18.685 5.33741 18.9417 5.24183 19.2359 5.23316C19.53 5.22433 19.7808 5.35658 19.9881 5.62991C20.6808 6.50441 21.2234 7.48258 21.6159 8.56441C22.0082 9.64608 22.2044 10.7912 22.2044 11.9999C22.2044 13.4151 21.9369 14.743 21.4019 15.9837C20.8669 17.2242 20.1404 18.3034 19.2226 19.2214C18.3046 20.1392 17.2254 20.8657 15.9849 21.4007C14.7442 21.9357 13.4163 22.2032 12.0011 22.2032ZM12.0011 13.1374C11.6819 13.1374 11.4125 13.0277 11.1929 12.8082C10.9734 12.5885 10.8636 12.3191 10.8636 11.9999V2.93416C10.8636 2.61499 10.9734 2.34558 11.1929 2.12591C11.4125 1.90641 11.6819 1.79666 12.0011 1.79666C12.3203 1.79666 12.5897 1.90641 12.8094 2.12591C13.0289 2.34558 13.1386 2.61499 13.1386 2.93416V11.9999C13.1386 12.3191 13.0289 12.5885 12.8094 12.8082C12.5897 13.0277 12.3203 13.1374 12.0011 13.1374Z"
          fill="#04D3FF"
        />
      </g>
    </Icon>
  );
}
