import {
  AddNftToCompetitionPayload,
  AddNftToCompetitionResponse,
  CompetitionResponse,
  HistoryResponse,
  MyCompetitionResponse,
  NotificationResponse,
  VoteToCompetition,
  VoteToCompetitionResponse,
} from '../../interfaces/components';
import axios from '../axios';

export const getAllCompetitions = async (page: number, recordsPerPage: number): Promise<CompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllNotifications = async (page: number, recordsPerPage: number): Promise<NotificationResponse> => {
  try {
    const endPoint = `/api/nfts/competition/notifications/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong while getting notifications';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMyCompetitions = async (
  walletAddress: string,
  page: number,
  recordsPerPage: number,
): Promise<MyCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/?wallet_address=${walletAddress}&?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCompetitionDetails = async (id: number): Promise<any> => {
  try {
    const endPoint = `/api/nfts/competitions/${id}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addNftToCompetition = async (
  id: number,
  data: AddNftToCompetitionPayload,
): Promise<AddNftToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/${id}/add-nft/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addVoteToCompetingNfts = async (
  id: number,
  data: VoteToCompetition,
): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competition/${id}/vote/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const removeVoteFromCompetingNfts = async (
  id: number,
  data: VoteToCompetition,
): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competition/${id}/unvote/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMyHistory = async (page: number, recordsPerPage: number): Promise<HistoryResponse> => {
  try {
    const endPoint = `/api/user/xp-history/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint);
    // const res = await axios.get(endPoint, {
    //   params: {
    //     page: page,
    //     per_page: recordsPerPage,
    //   },
    // });
    console.log('historyyy', res);
    if (!res?.data) throw 'Something went wrong History';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getLeaderBoardData = async (competitionId: number) => {
  try {
    const endPoint = `/api/nfts/competition/leaderboard/`;
    const res = await axios.get(endPoint, {
      params: {
        competition_id: competitionId,
      },
    });
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const subscribeToCompetition = async (id: number): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/${id}/subscribe/`;
    const res = await axios.post(endPoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const unsubscribeToCompetition = async (id: number): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/${id}/unsubscribe/`;
    const res = await axios.post(endPoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const markNotificationAsRead = async (id: number): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competition/notifications/${id}/mark-read/`;
    const res = await axios.post(endPoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
