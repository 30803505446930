import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function ArrowForward(props: IconProps) {
  return (
    <Icon width="28px" height="28px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_1183_629" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1183_629)">
        <path
          d="M18.8709 15.1668H5.83335C5.5028 15.1668 5.22571 15.055 5.0021 14.8314C4.77849 14.6078 4.66669 14.3307 4.66669 14.0001C4.66669 13.6696 4.77849 13.3925 5.0021 13.1689C5.22571 12.9453 5.5028 12.8335 5.83335 12.8335H18.8709L13.1542 7.11679C12.9209 6.88346 12.809 6.61124 12.8188 6.30013C12.8285 5.98902 12.95 5.71679 13.1834 5.48346C13.4167 5.26957 13.6889 5.15777 14 5.14804C14.3111 5.13832 14.5834 5.25013 14.8167 5.48346L22.5167 13.1835C22.6334 13.3001 22.716 13.4265 22.7646 13.5626C22.8132 13.6987 22.8375 13.8446 22.8375 14.0001C22.8375 14.1557 22.8132 14.3015 22.7646 14.4376C22.716 14.5737 22.6334 14.7001 22.5167 14.8168L14.8167 22.5168C14.6028 22.7307 14.3354 22.8376 14.0146 22.8376C13.6938 22.8376 13.4167 22.7307 13.1834 22.5168C12.95 22.2835 12.8334 22.0064 12.8334 21.6855C12.8334 21.3647 12.95 21.0876 13.1834 20.8543L18.8709 15.1668Z"
          fill="#1C1B1F"
        />
      </g>
    </Icon>
  );
}
