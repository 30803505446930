import { Box, Text, Image, Input, MenuList, Button, MenuButton, Menu, useToast } from '@chakra-ui/react';
import ProfilePicture from '../../assets/profilePicture.png';
import SendMessageImage from '../../assets/sendMessage.svg';
import SendMessageActive from '../../assets/addCommentActive.svg';
import React, { useEffect, useState } from 'react';
import CommentDropDown from '../../Icons/CommentDropDown';
import { addComments, deleteComment, getAllComments, updateComment } from '../../api/comments/apis';
import { CommentsResponse, User } from '../../interfaces/components';
import storage from '../../utils/storage';
import TimeAgo from '../Timer';
import { AppStoreState, useAppStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner';
import { loginWithXpad } from '../xpadAuth';

interface CommentSectionProps {
  nftId?: number;
  id?: number;
}

export default function CommentSection(props: CommentSectionProps) {
  const { nftId, id } = props;
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const navigate = useNavigate();
  const [value, setValue] = React.useState('');
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const userName = storage.get('userName');
  const [allComments, setAllComments] = useState<CommentsResponse[]>();
  const [editCommentId, setEditCommentId] = useState<any>(null);
  const [editText, setEditText] = useState('');
  const [commentToDeleteId, setCommentToDeleteId] = useState<number | null>(null);
  const user = storage.get('user');
  const currentUser = JSON.parse(user || '{}');

  const handleEditClick = (comment: CommentsResponse) => {
    setEditCommentId(comment.id);
    setEditText(comment.comment);
  };

  const removeCommentToEditFromState = () => {
    setEditCommentId('');
    setEditText('');
  };

  const handleCancelClick = () => {
    setEditCommentId(null);
    setEditText('');
  };

  const handleUpdateClick = (id: number) => {
    onUpdateComment(id, editText);
    setEditCommentId(null);
  };

  const onUpdateComment = (commentId: number, editText: string) => {
    updateComment(commentId, editText, id as number)
      .then(() => {
        fetchComments(nftId || 0);
      })
      .catch(() => {
        setLoading(false);
        toast({
          title: 'Error',
          description: 'Something went wrong while updating comment',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleInputChange = (e: { target: { value: any } }) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  const fetchComments = async (id: number) => {
    try {
      setLoading(true);
      const res = await getAllComments(id);
      setAllComments(res);
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching comments',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchComments(nftId || 0);
  }, [nftId]);

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      addComment();
    }
  };

  const addComment = async () => {
    try {
      const data = {
        nft_identifier: id,
        comment: value,
      };
      await addComments(nftId || 0, data);
      setValue('');
      fetchComments(nftId || 0);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while adding comment',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onDeleteClick = (id: any) => {
    deleteComment(id)
      .then(() => {
        fetchComments(nftId || 0);
      })
      .catch((error: any) => {
        console.error('Error caught in catch block:', error);
        setLoading(false);
        toast({
          title: 'Error',
          description: 'Something went wrong while deleting comment',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const onCommentUserNameClick = (owner: User) => {
    if (owner?.wallet_address === currentUser?.wallet_address) {
      navigate('/my-profile');
    } else {
      navigate(`/users-profile/${owner?.username}`, { state: { owner } });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box borderRadius="20px" display="flex" flexDirection="column" gap="16px" width={['100%', '100%', '50%']}>
          <Text
            color="#FFF"
            fontFamily="Montserrat"
            fontSize="20px"
            fontStyle="normal"
            fontWeight="800"
            lineHeight="normal"
            textAlign="start"
          >
            Comments
          </Text>
          <Box
            padding="16px"
            borderRadius=" 20px"
            border="1px solid rgba(255, 255, 255, 0.10)"
            background=" rgba(255, 255, 255, 0.10)"
            display="flex"
            flexDirection="column"
            gap="15px"
            minH="592px"
            height="592px"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="15px"
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '0px', // Hides scrollbar in WebKit browsers (Chrome, Safari)
                  backgroundColor: 'transparent',
                },
                '*::-webkit-scrollbar-corner': {
                  background: 'transparent',
                },
              }}
              style={{
                scrollbarWidth: 'none', // Hides scrollbar in Firefox
              }}
            >
              {allComments?.length ? (
                allComments.map((comment, i) => (
                  <Box display="flex" gap="10px" key={i}>
                    <Box>
                      {comment?.user?.profile_picture ? (
                        <Box height="40px" width="40px">
                          <Image
                            src={comment?.user?.profile_picture}
                            objectFit="cover"
                            borderRadius="50%"
                            height="inherit"
                            width="inherit"
                            cursor="pointer"
                            onClick={() => onCommentUserNameClick(comment?.user)}
                          />
                        </Box>
                      ) : (
                        <Box height="40px" width="40px">
                          <Image
                            src={ProfilePicture}
                            objectFit="cover"
                            height="inherit"
                            width="inherit"
                            borderRadius="50%"
                            cursor="pointer"
                            onClick={() => onCommentUserNameClick(comment?.user)}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      gap="5px"
                      flexDirection="column"
                      padding="12px"
                      borderRadius="16px"
                      background="rgba(255, 255, 255, 0.09)"
                      width="-webkit-fill-available"
                    >
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" gap="10px">
                          <Text
                            color="#FFF"
                            fontFamily="Montserrat"
                            fontSize=" 14px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="22px"
                            cursor="pointer"
                            onClick={() => onCommentUserNameClick(comment?.user)}
                          >
                            {comment?.user?.username}
                          </Text>
                          <Box height="14px" display="flex" justifyContent="center" alignItems="center">
                            <Text
                              color="rgba(255, 255, 255, 0.50)"
                              fontFamily="Montserrat"
                              fontSize="14px"
                              fontStyle="normal"
                              fontWeight="500"
                              lineHeight="22px"
                            >
                              .
                            </Text>
                          </Box>
                          <Text
                            color="rgba(255, 255, 255, 0.50)"
                            fontFamily="Montserrat"
                            fontSize={['10px', '14px']}
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="22px"
                          >
                            <TimeAgo timestamp={comment?.created_at} />
                          </Text>
                        </Box>

                        {comment?.user?.username === userName && (
                          <Menu placement="bottom-end">
                            {({ isOpen, onClose }) => (
                              <>
                                <MenuButton
                                  display="flex"
                                  width=" unset"
                                  backgroundColor=" transparent"
                                  rightIcon={isOpen ? <CommentDropDown /> : <CommentDropDown />}
                                  height=" unset"
                                  justifyContent="flex-end"
                                  as={Button}
                                  _hover={{
                                    color: 'white',
                                    bg: 'transparent',
                                  }}
                                  _active={{
                                    color: 'white',
                                    bg: 'transparent',
                                  }}
                                ></MenuButton>
                                <MenuList
                                  padding="8px"
                                  borderRadius="8px"
                                  backgroundColor="#585858"
                                  boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)"
                                  borderWidth="none !important"
                                  zIndex={2}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    height="36px"
                                    paddingRight="12px"
                                    paddingLeft="12px"
                                    cursor="pointer"
                                    onClick={() => {
                                      setCommentToDeleteId(null);
                                      handleEditClick(comment);
                                      onClose(); // Close the menu after clicking
                                    }}
                                  >
                                    <Text
                                      color="#FFF"
                                      fontFamily="Montserrat"
                                      fontSize=" 14px"
                                      fontStyle=" normal"
                                      fontWeight=" 500"
                                      lineHeight="22px" /* 157.143% */
                                    >
                                      Edit comment
                                    </Text>
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    height="36px"
                                    paddingRight="12px"
                                    paddingLeft="12px"
                                    cursor="pointer"
                                    onClick={() => {
                                      removeCommentToEditFromState();
                                      setCommentToDeleteId(comment?.id);
                                      onClose();
                                    }}
                                  >
                                    <Text
                                      color="#FF4D4F"
                                      fontFamily="Montserrat"
                                      fontSize=" 14px"
                                      fontStyle=" normal"
                                      fontWeight=" 500"
                                      lineHeight="22px"
                                    >
                                      Delete comment
                                    </Text>
                                  </Box>
                                </MenuList>
                              </>
                            )}
                          </Menu>
                        )}
                      </Box>

                      <Box>
                        {editCommentId === comment.id ? (
                          <Box>
                            <Input
                              value={editText}
                              onChange={(e) => setEditText(e.target.value)}
                              placeholder="Edit your comment"
                              size="sm"
                              borderRadius="8px"
                              // background="rgba(255, 255, 255, 0.09)"
                              border="none"
                              height="40px"
                              padding="8px"
                              color="white"
                              focusBorderColor="none"
                            />
                            <Box marginTop="12px" gap="5px" display="flex" justifyContent="flex-end">
                              <Button
                                borderRadius="8px"
                                background="rgba(255, 255, 255, 0.15)"
                                onClick={() => handleCancelClick()}
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                _hover={{
                                  color: '#fff',
                                  bg: 'rgba(255, 255, 255, 0.12)',
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                borderRadius="8px"
                                borderTop="1px solid #04D3FF"
                                background="#118BCF"
                                onClick={() => {
                                  handleUpdateClick(comment.id);
                                }}
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                _hover={{
                                  color: '#fff',
                                  bg: '#04D3FF',
                                }}
                              >
                                Save changes
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Text
                            color="#FFF"
                            fontFamily="Montserrat"
                            fontSize=" 14px"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="22px"
                          >
                            {comment?.comment}
                          </Text>
                        )}
                      </Box>

                      {commentToDeleteId === comment?.id && (
                        <Box marginTop="12px" gap="5px" display="flex" justifyContent="flex-end">
                          <Button
                            borderRadius="8px"
                            background=" rgba(255, 255, 255, 0.15)"
                            color="#FFF"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={() => {
                              setCommentToDeleteId(null);
                            }}
                            _hover={{
                              color: '#fff',
                              bg: 'rgba(255, 255, 255, 0.12)',
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            borderRadius="8px"
                            onClick={() => {
                              onDeleteClick(comment?.id);
                            }}
                            color="#FFF"
                            fontSize="14px"
                            fontWeight="500"
                            background=" #FF4D4F"
                            _hover={{
                              color: 'white',
                              bg: '#FF4D4F',
                            }}
                          >
                            Delete comment
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box>
                  <Text
                    color=" rgba(255, 255, 255, 0.50)"
                    textAlign="center"
                    fontFamily="Montserrat"
                    fontSize="16px"
                    fontStyle=" normal"
                    fontWeight="500"
                  >
                    No comments to display.
                  </Text>
                </Box>
              )}
            </Box>
            <Box position="relative" width="100%" marginTop="auto">
              {!accessToken ? (
                <Box
                  borderRadius="16px"
                  background="rgba(255, 255, 255, 0.09)"
                  height="64px"
                  paddingLeft="40px"
                  paddingRight="40px"
                  fontStyle=" normal"
                  fontWeight=" 500"
                  lineHeight="22px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                >
                  <Text
                    color=" rgba(255, 255, 255, 0.50)"
                    textAlign="center"
                    fontFamily="Montserrat"
                    fontSize="16px"
                    fontStyle=" normal"
                    fontWeight="500"
                  >
                    Please login to comment
                  </Text>
                  <Button
                    width="113px"
                    borderRadius="15px"
                    backgroundColor="#118BCF"
                    padding="16px 32px"
                    color=" #FFF"
                    fontSize="16px"
                    fontWeight=" 600"
                    h="44px"
                    _hover={{
                      color: 'white',
                      bg: '#43BDD7',
                    }}
                    onClick={loginWithXpad}
                  >
                    Login
                  </Button>
                </Box>
              ) : (
                <Box>
                  {currentUser?.profile_picture ? (
                    <Image
                      src={currentUser?.profile_picture}
                      alt="Left Image"
                      position="absolute"
                      left="10px"
                      top="50%"
                      transform="translateY(-50%)"
                      boxSize="20px"
                      borderRadius="50%"
                      height="34px"
                      width="34px"
                    />
                  ) : (
                    <Image
                      src={ProfilePicture}
                      alt="Left Image"
                      position="absolute"
                      left="10px"
                      top="50%"
                      transform="translateY(-50%)"
                      boxSize="20px"
                      borderRadius="50%"
                      height="34px"
                      width="34px"
                    />
                  )}
                  <Input
                    value={value}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Add a comment"
                    size="sm"
                    borderRadius="16px"
                    background="rgba(255, 255, 255, 0.09)"
                    border="none"
                    height="54px"
                    paddingLeft="60px"
                    paddingRight="40px"
                    focusBorderColor="rgba(255, 255, 255, 0.10)"
                    _placeholder={{
                      color: 'rgba(255, 255, 255, 0.50)',
                      fontFamily: 'Montserrat',
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '22px' /* 146.667% */,
                    }}
                    color="#FFF"
                    fontSize=" 15px"
                    fontStyle=" normal"
                    fontWeight=" 500"
                    lineHeight="22px" /* 146.667% */
                  />
                  <Box
                    position="absolute"
                    right="10px"
                    top="50%"
                    transform="translateY(-50%)"
                    cursor="pointer"
                    onClick={addComment}
                    zIndex="1" // Ensure it's clickable above the input
                  >
                    <Image src={value ? SendMessageActive : SendMessageImage} alt="Right Image" boxSize="20px" />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
