import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function MobileMenuIcon(props: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <mask id="mask0_1485_1938" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect width="24.0015" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1485_1938)">
        <path
          d="M4.00055 18C3.7172 18 3.47968 17.9042 3.28801 17.7125C3.09633 17.5208 3.00049 17.2833 3.00049 17C3.00049 16.7167 3.09633 16.4792 3.28801 16.2875C3.47968 16.0958 3.7172 16 4.00055 16H20.0015C20.2849 16 20.5224 16.0958 20.7141 16.2875C20.9058 16.4792 21.0016 16.7167 21.0016 17C21.0016 17.2833 20.9058 17.5208 20.7141 17.7125C20.5224 17.9042 20.2849 18 20.0015 18H4.00055ZM4.00055 13C3.7172 13 3.47968 12.9042 3.28801 12.7125C3.09633 12.5208 3.00049 12.2833 3.00049 12C3.00049 11.7167 3.09633 11.4792 3.28801 11.2875C3.47968 11.0958 3.7172 11 4.00055 11H20.0015C20.2849 11 20.5224 11.0958 20.7141 11.2875C20.9058 11.4792 21.0016 11.7167 21.0016 12C21.0016 12.2833 20.9058 12.5208 20.7141 12.7125C20.5224 12.9042 20.2849 13 20.0015 13H4.00055ZM4.00055 8C3.7172 8 3.47968 7.90417 3.28801 7.7125C3.09633 7.52083 3.00049 7.28333 3.00049 7C3.00049 6.71667 3.09633 6.47917 3.28801 6.2875C3.47968 6.09583 3.7172 6 4.00055 6H20.0015C20.2849 6 20.5224 6.09583 20.7141 6.2875C20.9058 6.47917 21.0016 6.71667 21.0016 7C21.0016 7.28333 20.9058 7.52083 20.7141 7.7125C20.5224 7.90417 20.2849 8 20.0015 8H4.00055Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
