import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CrownIconSilver(props: IconProps) {
  return (
    <Icon
      width="68px !important"
      height="52px !important"
      viewBox="0 0 68 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1014_25667)">
        <g filter="url(#filter1_dii_1014_25667)">
          <path
            d="M19.0861 19.5469L10.5118 13.3233C8.55231 11.9011 5.83808 13.4926 6.12244 15.8971L8.12991 32.8717C8.25648 33.9419 8.99461 34.8445 10.0187 35.18C23.3046 39.5328 29.8197 40.9327 49.6161 43.1567C50.7174 43.2805 51.79 42.7356 52.3384 41.7726L60.7663 26.9707C61.9644 24.8666 60.0839 22.344 57.7253 22.8912L47.4045 25.2857C46.1079 25.5865 44.7803 24.9251 44.2393 23.709L38.0926 9.88988C37.3164 8.14493 35.0514 7.68593 33.6572 8.99104L22.6154 19.3269C21.6437 20.2365 20.1632 20.3288 19.0861 19.5469Z"
            fill="url(#paint0_linear_1014_25667)"
          />
          <path
            d="M10.104 13.8852L18.6783 20.1088C20.0247 21.0861 21.8752 20.9707 23.0898 19.8338L34.1316 9.49792C35.1773 8.51909 36.8761 8.86334 37.4582 10.1721L43.605 23.9912C44.2811 25.5113 45.9407 26.338 47.5614 25.962L57.8822 23.5675C59.6511 23.1571 61.0615 25.0491 60.163 26.6271L51.735 41.429C51.3242 42.1505 50.5195 42.5596 49.6936 42.4668C29.9309 40.2465 23.4644 38.8546 10.2349 34.5202C9.46793 34.2689 8.91426 33.5921 8.81941 32.7901L6.81194 15.8155C6.59867 14.0121 8.63434 12.8185 10.104 13.8852Z"
            stroke="url(#paint1_linear_1014_25667)"
            strokeOpacity="0.5"
            strokeWidth="1.3886"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1014_25667"
          x="-5.22104"
          y="-2.38365"
          width="76.546"
          height="57.0629"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.77721" />
          <feGaussianBlur stdDeviation="2.77721" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1014_25667" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1014_25667" result="shape" />
        </filter>
        <filter
          id="filter1_dii_1014_25667"
          x="4.7132"
          y="8.24023"
          width="57.8133"
          height="37.7108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.3886" />
          <feGaussianBlur stdDeviation="0.694302" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1014_25667" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1014_25667" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-45.8239" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1014_25667" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.77721" />
          <feGaussianBlur stdDeviation="0.694302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.979123 0 0 0 0 0.979123 0 0 0 0 0.979123 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_1014_25667" result="effect3_innerShadow_1014_25667" />
        </filter>
        <linearGradient
          id="paint0_linear_1014_25667"
          x1="38.7627"
          y1="-4.80987"
          x2="29.5975"
          y2="40.4169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#565656" />
          <stop offset="0.0966666" stopColor="#D9D9D9" />
          <stop offset="0.186667" stopColor="#ADADAD" />
          <stop offset="0.366667" stopColor="white" />
          <stop offset="0.761667" stopColor="#888888" />
          <stop offset="1" stopColor="#A4A4A4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1014_25667"
          x1="38.7627"
          y1="-4.80987"
          x2="29.5975"
          y2="40.4169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
