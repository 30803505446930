import { Icon } from '@chakra-ui/icons';
import React from 'react';

export default function ProfileIcon() {
  return (
    <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10_10434" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10_10434)">
        <path
          d="M6.35 17.1C7.2 16.45 8.15 15.9375 9.2 15.5625C10.25 15.1875 11.35 15 12.5 15C13.65 15 14.75 15.1875 15.8 15.5625C16.85 15.9375 17.8 16.45 18.65 17.1C19.2333 16.4167 19.6875 15.6417 20.0125 14.775C20.3375 13.9083 20.5 12.9833 20.5 12C20.5 9.78333 19.7208 7.89583 18.1625 6.3375C16.6042 4.77917 14.7167 4 12.5 4C10.2833 4 8.39583 4.77917 6.8375 6.3375C5.27917 7.89583 4.5 9.78333 4.5 12C4.5 12.9833 4.6625 13.9083 4.9875 14.775C5.3125 15.6417 5.76667 16.4167 6.35 17.1ZM12.5 13C11.5167 13 10.6875 12.6625 10.0125 11.9875C9.3375 11.3125 9 10.4833 9 9.5C9 8.51667 9.3375 7.6875 10.0125 7.0125C10.6875 6.3375 11.5167 6 12.5 6C13.4833 6 14.3125 6.3375 14.9875 7.0125C15.6625 7.6875 16 8.51667 16 9.5C16 10.4833 15.6625 11.3125 14.9875 11.9875C14.3125 12.6625 13.4833 13 12.5 13ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22Z"
          fill="#04D3FF"
        />
      </g>
    </Icon>
  );
}
