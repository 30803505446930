import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import NothingToSee from '../../Icons/NothingToSee';

export const NoData = () => (
  <Box
    width="100%"
    height="auto"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    alignItems="center"
    gap={[6]}
    padding={['66px 36px']}
  >
    <NothingToSee boxSize={[16]} />
    <Text color="rgba(255, 255, 255, 0.50)" textAlign="center" fontFamily="Montserrat" fontSize="14px" fontWeight="500">
      There’s nothing to see here...
    </Text>
  </Box>
);
