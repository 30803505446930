import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/submitNftModal.scss';
import './styles/browseNftModal.scss';
import './styles/submit-confirmation-modal.scss';
import './styles/submit-status-modal.scss';
import './styles/mint-now.scss';
import './styles/leader-board-drawer.scss';
import { Web3ModalProvider } from './components/providers/Web3ModalProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './utils/queryClient';
import { ChakraProvider } from '@chakra-ui/react';
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ChakraProvider>
    <Web3ModalProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Web3ModalProvider>
  </ChakraProvider>,
);

reportWebVitals();
