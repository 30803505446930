import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function ArrowDownIconGrey(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_692_1509" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_692_1509)">
        <path
          d="M11.4752 14.4749L7.8502 10.8499C7.8002 10.7999 7.7627 10.7458 7.7377 10.6874C7.7127 10.6291 7.7002 10.5666 7.7002 10.4999C7.7002 10.3666 7.74603 10.2499 7.8377 10.1499C7.92936 10.0499 8.0502 9.99994 8.2002 9.99994H15.8002C15.9502 9.99994 16.071 10.0499 16.1627 10.1499C16.2544 10.2499 16.3002 10.3666 16.3002 10.4999C16.3002 10.5333 16.2502 10.6499 16.1502 10.8499L12.5252 14.4749C12.4419 14.5583 12.3585 14.6166 12.2752 14.6499C12.1919 14.6833 12.1002 14.6999 12.0002 14.6999C11.9002 14.6999 11.8085 14.6833 11.7252 14.6499C11.6419 14.6166 11.5585 14.5583 11.4752 14.4749Z"
          fill="#8F8F8F"
        />
      </g>
    </Icon>
  );
}
