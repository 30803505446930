import { Box, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import SingleCompetitionHero from './SingleCompetitionHero';
import Competitions from '../competitionOverview/Competitions';
import { AppStoreState, useAppStore } from '../../store';
import { getCompetitionDetails } from '../../api/competitions/apis';
import CompetingNFTs from './CompetingNfts';
import TopPlayers from './TopPlayers';
import { isAfter, parseISO, isBefore } from 'date-fns';

export default function SingleCompetitionMain() {
  const [loading, setLoading] = useState(false);
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);
  const competitionDetails = useAppStore((state: AppStoreState) => state.competitionDetails);
  const setCompetitionDetails = useAppStore((state: AppStoreState) => state.setCompetitionDetails);
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const competingNfts = useRef<HTMLDivElement>(null);

  const toast = useToast();

  const fetchCompetitionById = async (competitionId: number) => {
    try {
      setLoading(true);
      const res = await getCompetitionDetails(competitionId);
      setCompetitionDetails(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching competition details',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (selectedCompetitionId) {
      fetchCompetitionById(selectedCompetitionId);
    } else {
      const competitionId = location && location?.pathname?.split('/').pop();
      competitionId && fetchCompetitionById(Number(competitionId));
    }
  }, [reRender]);

  const scrollToCompetingNft = () => {
    if (competingNfts.current) {
      window.scrollBy({ top: 1150, behavior: 'smooth' });
    }
  };

  return (
    <Box
      zIndex={2}
      flexDirection="column"
      maxHeight="100%"
      marginTop={['11px', '11px', '40px', '40px']}
      maxWidth="1400px"
    >
      <Text
        color="rgba(255, 255, 255, 0.50)"
        fontFamily=" Montserrat"
        fontSize=" 14px"
        fontStyle=" normal"
        fontWeight=" 600"
        lineHeight="normal"
        marginBottom="24px"
      >
        Competitions/
        <span
          style={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
          }}
        >
          &nbsp;{competitionDetails?.competition_name}
        </span>
      </Text>

      {competitionDetails && (
        <SingleCompetitionHero onVoteNowClick={scrollToCompetingNft} competitionDetails={competitionDetails} />
      )}

      {competitionDetails?.top_nfts && (
        <>
          {isAfter(parseISO(competitionDetails?.participation_starts), new Date()) ? (
            <TopPlayers topNfts={competitionDetails?.top_nfts} loading={loading} />
          ) : isAfter(new Date(), parseISO(competitionDetails?.participation_starts)) &&
            isBefore(new Date(), parseISO(competitionDetails?.voting_starts)) ? null : isAfter(
              new Date(),
              parseISO(competitionDetails?.voting_starts),
            ) && isBefore(new Date(), parseISO(competitionDetails?.voting_ends)) ? (
            <TopPlayers topNfts={competitionDetails?.top_nfts} loading={loading} />
          ) : (
            isAfter(new Date(), parseISO(competitionDetails?.voting_ends)) && (
              <TopPlayers topNfts={competitionDetails?.top_nfts} loading={loading} />
            )
          )}
        </>
      )}

      <div ref={competingNfts}>
        <CompetingNFTs nfts={competitionDetails?.nfts} loading={loading} />
      </div>

      <Competitions heading={'More Competitions'} singleCompetition={true} />
    </Box>
  );
}
