import { Box, useToast, Tabs } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AllNftsResponseData,
  FavoriteNftResponse,
  OtherUserCompetitions,
  OtherUserNfts,
  OwnerObject,
} from '../../interfaces/components';
import NFTFilled from '../../Icons/NFTFilled';
import Nft from '../profile/Nft';
import TrophyIconGrey from '../../Icons/TrophyGrey';
import { otherUserCompetitions, otherUserNfts } from '../../api/usersProfile/api';
import Pagination from '../common/Pagination';
import { NoData } from '../common/NoData';
import { TabOptionList } from '../common/TabList';
import MyCompetitions from '../profile/MyCompetitions';
import LoadingSpinner from '../common/LoadingSpinner';

interface OtherUserProfileOverviewProps {
  owner: OwnerObject;
}

export default function OtherUserProfileOverview(props: OtherUserProfileOverviewProps) {
  const { owner } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [userCompetitions, setUserCompetitions] = useState<OtherUserCompetitions>();
  const [tabIndex, setTabIndex] = useState(0);
  const [otherUserNftList, setOtherUserNfts] = useState<OtherUserNfts>();
  const [currentPage, setCurrentPage] = useState(1);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const fetchUserCompetitionData = async (walletAddress: string, page: number, recordsPerPage: number) => {
    try {
      setLoading(true);
      const res = walletAddress && (await otherUserCompetitions(walletAddress, page, recordsPerPage));
      res && setUserCompetitions(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFTs',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchOtherUserNfts = async (walletAddress: string, page: number, recordsPerPage: number) => {
    try {
      setLoading(true);
      const res = await otherUserNfts(walletAddress, page, recordsPerPage);
      res && setOtherUserNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: `Something went wrong while fetching user's NFTs`,
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      handleOtherUserNftsClick();
    } else {
      handleOtherUserCompetitionClick();
    }
  }, [tabIndex]);

  useEffect(() => {
    owner?.wallet_address && fetchOtherUserNfts(owner?.wallet_address, 1, 9);
  }, []);

  const onNftClick = (nft: AllNftsResponseData | FavoriteNftResponse) => {
    navigate(`/nfts/${nft?.identifier}`);
  };

  const handleOtherUserNftsClick = () => {
    setTabIndex(0);
    owner?.wallet_address && fetchOtherUserNfts(owner?.wallet_address, 1, 9);
  };

  const handleOtherUserCompetitionClick = () => {
    owner?.wallet_address && fetchUserCompetitionData(owner?.wallet_address, 1, 9);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (otherUserNftList?.total_pages && currentPage < otherUserNftList?.total_pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPaginationItemClick = (pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  };

  return (
    <Box margin="0px 0px 64px 0px" display="flex" flexDirection="column" gap="16px">
      <Tabs variant="unstyled" width="100" index={tabIndex} onChange={handleTabsChange}>
        <TabOptionList
          optionOne="NFTs"
          optionTwo="Competitions"
          tabIndexValue={tabIndex}
          iconOne={<NFTFilled fill="currentcolor" boxSize={[4, 5, 6]} />}
          iconTwo={<TrophyIconGrey fill="currentcolor" boxSize={[4, 5, 6]} />}
        />
      </Tabs>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
            columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
          >
            {tabIndex == 0 ? (
              <>
                {otherUserNftList && otherUserNftList?.results?.length > 0 ? (
                  <Nft
                    myNfts={otherUserNftList?.results}
                    onNftClick={onNftClick}
                    tabIndex={tabIndex}
                    ownerName={owner?.twitter_username ? owner?.twitter_username : owner?.username}
                  />
                ) : (
                  <NoData />
                )}
              </>
            ) : (
              <>
                {userCompetitions ? (
                  <MyCompetitions userCompetitions={userCompetitions} isUserProfile={true} />
                ) : (
                  <NoData />
                )}
              </>
            )}
          </Box>
          {tabIndex == 0 && otherUserNftList?.results?.length ? (
            <Pagination
              handlePreviousPage={handlePrevPage}
              totalPages={otherUserNftList?.total_pages}
              currentPage={currentPage}
              onPaginationitemClick={onPaginationItemClick}
              handleNextPage={handleNextPage}
            />
          ) : null}
        </>
      )}
    </Box>
  );
}
