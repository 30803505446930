import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function HeartIconFilled(props: IconProps) {
  return (
    <Icon width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_2048_1371" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
        <rect x="0.333496" y="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2048_1371)">
        <path
          d="M10.3333 17.4372C10.1389 17.4372 9.94097 17.4025 9.73958 17.333C9.53819 17.2636 9.36111 17.1525 9.20833 16.9997L7.77083 15.6872C6.29861 14.34 4.96875 13.0031 3.78125 11.6768C2.59375 10.3504 2 8.88856 2 7.29134C2 5.98579 2.4375 4.89551 3.3125 4.02051C4.1875 3.14551 5.27778 2.70801 6.58333 2.70801C7.31944 2.70801 8.01389 2.86426 8.66667 3.17676C9.31944 3.48926 9.875 3.91634 10.3333 4.45801C10.7917 3.91634 11.3472 3.48926 12 3.17676C12.6528 2.86426 13.3472 2.70801 14.0833 2.70801C15.3889 2.70801 16.4792 3.14551 17.3542 4.02051C18.2292 4.89551 18.6667 5.98579 18.6667 7.29134C18.6667 8.88856 18.0764 10.3538 16.8958 11.6872C15.7153 13.0205 14.375 14.3608 12.875 15.708L11.4583 16.9997C11.3056 17.1525 11.1285 17.2636 10.9271 17.333C10.7257 17.4025 10.5278 17.4372 10.3333 17.4372Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
