import { Box } from '@chakra-ui/react';
import React from 'react';
import MyProfileHero from './MyprofileHero';
import MyNfts from './MyNfts';
import MyCompetitions from './MyCompetitions';

export default function MyProfile() {
  return (
    <>
      <Box
        display="flex"
        zIndex={2}
        flexDirection="column"
        marginBottom="20px"
        maxHeight="100%"
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for WebKit browsers
          },
          '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
          'scrollbar-width': 'none', // Hide scrollbar for Firefox
        }}
        maxWidth="1400px"
      >
        <MyProfileHero />
        <MyNfts />
        <MyCompetitions />
      </Box>
    </>
  );
}
