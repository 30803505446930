import { LinkWalletPayload, LinkWalletRes } from '../../interfaces/components';
import axios from '../axios';

export const userLoginWallet = async (payload: {
  wallet_address: string;
  challenge: string;
  signature: string;
}): Promise<any> => {
  try {
    const endPoint = '/api/user/login_wallet/';
    const res = await axios.post<any>(endPoint, payload);
    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const GetUser = async (): Promise<any> => {
  try {
    const endPoint = '/api/user/current/';
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const twitterLogin = async (): Promise<any> => {
  try {
    const endPoint = '/api/user/twitter-login/';
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const twitterSave = async (payload: any): Promise<any> => {
  try {
    const endPoint = '/api/user/twitter-login/';
    const res = await axios.post<any>(endPoint, payload);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const userLinkWallet = async (data: LinkWalletPayload) => {
  try {
    const endPoint = `api/user/link-wallet/`;
    const res = await axios.post<LinkWalletRes>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const userUnLinkWallet = async (data: LinkWalletPayload) => {
  try {
    const endPoint = `api/user/unlink-wallet/`;
    const res = await axios.post<LinkWalletRes>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};
