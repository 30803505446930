import { Box, Text, Image, Button, Divider } from '@chakra-ui/react';
import React from 'react';
import Pagination from '../common/Pagination';
import { CompetitionObject } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import { BellIcon } from '@chakra-ui/icons';
import { isAfter, parseISO, isBefore } from 'date-fns';
import Clock from '../../Icons/Clock';
import GroupIcon from '../../Icons/GroupIcon';
import VotesIcon from '../../Icons/Votes';
import Countdown from '../common/Countdown';
import LoadingSpinner from '../common/LoadingSpinner';
import { NoData } from '../common/NoData';

interface CompetitionProps {
  loading?: boolean;
  reRender: boolean;
  currentPage: number;
  totalPages: number | undefined;
  myCompetitionList: CompetitionObject[] | undefined;
  otherUserCompetition?: boolean;
  setRerender: (reRender: boolean) => void;
  onParticipateClick: (competition: CompetitionObject) => void;
  handleNextPage: () => void;
  onPaginationitemClick: (pageToFetch: number) => void;
  handlePrevPage: () => void;
}

export default function Competition(props: CompetitionProps) {
  const {
    loading,
    myCompetitionList,
    reRender,
    currentPage,
    otherUserCompetition,
    totalPages,
    setRerender,
    onParticipateClick,
    handleNextPage,
    onPaginationitemClick,
    handlePrevPage,
  } = props;
  const navigate = useNavigate();
  const setSelectedCompetitionId = useAppStore((state: AppStoreState) => state.setSelectedCompetitionId);

  return (
    <>
      {!otherUserCompetition && (
        <Text color="#FFF" fontSize={['28px', '28px', '36px']} fontWeight="800" marginBottom="24px">
          My competitions
        </Text>
      )}
      {!myCompetitionList || myCompetitionList?.length === 0 ? (
        <NoData />
      ) : (
        <Box width="-webkit-fill-available">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Box
                display="flex"
                flexWrap="wrap"
                // justifyContent={['space-between', 'space-between', 'space-between', 'space-between', 'space-between']}
                rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
                columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
              >
                {myCompetitionList &&
                  myCompetitionList?.length > 0 &&
                  myCompetitionList?.map((competition, i) => (
                    <Box
                      key={i}
                      borderRadius="26px"
                      border="var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10)"
                      width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                      position="relative"
                      padding="16px"
                      cursor="pointer"
                      backgroundColor="rgba(255, 255, 255, 0.10)"
                    >
                      <Image
                        width={['inherit', 'inherit', '100%', '100%', '100%']}
                        cursor="pointer"
                        src={competition?.competition_image}
                        maxHeight="214px"
                        height="214px"
                        objectFit="cover"
                        borderRadius="12px"
                      />
                      <Text
                        color="#FFF"
                        fontFamily=" Montserrat"
                        fontStyle=" normal"
                        fontWeight="700"
                        lineHeight="normal"
                        fontSize="18px"
                        marginTop="18px"
                        marginBottom="24px"
                      >
                        {competition?.competition_name}
                      </Text>
                      <Box
                        padding="15px 10px"
                        display="flex"
                        borderRadius="15px"
                        background="rgba(255, 255, 255, 0.10)"
                      >
                        <Box
                          width="50%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                            <GroupIcon />
                            <Text
                              color="#96BCAD"
                              textAlign="center"
                              fontFamily="Montserrat"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              Entries
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              color=" #FFF"
                              textAlign="center"
                              fontFamily=" Montserrat"
                              fontSize="20px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              {competition?.total_entries}
                            </Text>
                          </Box>
                        </Box>
                        <Divider
                          border="1px solid rgba(255, 255, 255, 0.10) !important"
                          orientation="vertical"
                          height="56px"
                        />
                        <Box
                          width="50%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                            <VotesIcon color="#96BCAD" />
                            <Text
                              color="#96BCAD"
                              textAlign="center"
                              fontFamily="Montserrat"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              Total Votes
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              color=" #FFF"
                              textAlign="center"
                              fontFamily=" Montserrat"
                              fontSize="20px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              {competition?.total_votes ? competition?.total_votes : 0}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box marginTop="24px" marginBottom="24px" display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center" gap="8px">
                          <Clock color={'#0FBF95'} />
                          <Text
                            color=" #96BCAD"
                            textAlign="center"
                            fontFamily=" Montserrat"
                            fontSize=" 16px"
                            fontStyle=" normal"
                            fontWeight=" 600"
                            lineHeight="normal"
                          >
                            {isAfter(parseISO(competition?.participation_starts), new Date())
                              ? 'Opens in ' // Announced Stage (before participation starts)
                              : isAfter(new Date(), parseISO(competition?.participation_starts)) &&
                                isBefore(new Date(), parseISO(competition?.voting_starts))
                              ? 'Entries close in' // Participation Phase (after participation starts and before voting starts)
                              : isAfter(new Date(), parseISO(competition?.voting_starts)) &&
                                isBefore(new Date(), parseISO(competition?.voting_ends))
                              ? 'Voting ends in' // Voting Phase (after voting starts and before voting ends)
                              : isAfter(new Date(), parseISO(competition?.voting_ends))
                              ? 'Closed' // Competition Closed Stage (after voting ends)
                              : ''}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            color="#fff"
                            textAlign="center"
                            fontFamily="Montserrat"
                            fontSize="20px"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="normal"
                          >
                            {isAfter(parseISO(competition?.participation_starts), new Date()) ? (
                              <Countdown endDateString={competition?.participation_starts} />
                            ) : isAfter(new Date(), parseISO(competition?.participation_starts)) &&
                              isBefore(new Date(), parseISO(competition?.voting_starts)) ? (
                              <Countdown endDateString={competition?.voting_starts} />
                            ) : isAfter(new Date(), parseISO(competition?.voting_starts)) &&
                              isBefore(new Date(), parseISO(competition?.voting_ends)) ? (
                              <Countdown endDateString={competition?.voting_ends} />
                            ) : (
                              isAfter(new Date(), parseISO(competition?.voting_ends)) && ''
                            )}
                          </Text>
                        </Box>
                      </Box>

                      {isAfter(parseISO(competition?.participation_starts), new Date()) ? (
                        <Box>
                          <Button
                            width="100%"
                            borderRadius="15px"
                            padding="12px 24px"
                            color="#FFF"
                            leftIcon={<BellIcon />}
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            onClick={() => {
                              setRerender(!reRender);
                              setSelectedCompetitionId(competition?.id);
                              navigate(`/competitions/${competition?.id}`);
                            }}
                          >
                            Come back later
                          </Button>
                        </Box>
                      ) : isAfter(new Date(), parseISO(competition?.participation_starts)) &&
                        isBefore(new Date(), parseISO(competition?.voting_starts)) ? (
                        <Box display="flex" flexDirection="row" gap="12px">
                          <Button
                            width="50%"
                            borderRadius="15px"
                            border="0.5px solid rgba(255, 255, 255, 0.20)"
                            padding="12px 24px"
                            color="#FFF"
                            backgroundColor="#222"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: 'rgba(255, 255, 255, 0.20)',
                            }}
                            onClick={() => onParticipateClick(competition)}
                          >
                            Participate
                          </Button>
                          <Button
                            width="50%"
                            borderRadius="15px"
                            padding="12px 24px"
                            color=" #FFF"
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            onClick={() => {
                              setRerender(!reRender);
                              setSelectedCompetitionId(competition?.id);
                              navigate(`/competitions/${competition?.id}`);
                            }}
                          >
                            Open
                          </Button>
                        </Box>
                      ) : isAfter(new Date(), parseISO(competition?.voting_starts)) &&
                        isBefore(new Date(), parseISO(competition?.voting_ends)) ? (
                        <Box>
                          <Button
                            width="100%"
                            borderRadius="15px"
                            padding="12px 24px"
                            color=" #FFF"
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            onClick={() => {
                              setRerender(!reRender);
                              setSelectedCompetitionId(competition?.id);
                              navigate(`/competitions/${competition?.id}`);
                            }}
                          >
                            Open & Vote
                          </Button>
                        </Box>
                      ) : (
                        isAfter(new Date(), parseISO(competition?.voting_ends)) && (
                          <Box>
                            <Button
                              width="100%"
                              borderRadius="15px"
                              padding="12px 24px"
                              color=" #FFF"
                              backgroundColor="#118BCF"
                              fontSize={['16px', '16px', '16px']}
                              fontWeight="600"
                              height="48px"
                              _hover={{
                                color: 'white',
                                bg: '#43BDD7',
                              }}
                              onClick={() => {
                                setRerender(!reRender);
                                setSelectedCompetitionId(competition?.id);
                                navigate(`/competitions/${competition?.id}`);
                              }}
                            >
                              View
                            </Button>
                          </Box>
                        )
                      )}
                    </Box>
                  ))}
              </Box>

              {totalPages && (
                <Pagination
                  handlePreviousPage={handlePrevPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPaginationitemClick={onPaginationitemClick}
                  handleNextPage={handleNextPage}
                />
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
}
