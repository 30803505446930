import React, { useEffect, useState } from 'react';
import { Box, Text, Divider, useDisclosure, Button, useToast } from '@chakra-ui/react';
import GroupIcon from '../../Icons/GroupIcon';
import VotesIcon from '../../Icons/Votes';
import { SubmitNftModal } from '../modals/SubmitNft';
import { AppStoreState, useAppStore } from '../../store';
import AddToNftSuccessIcon from '../../Icons/AddToNftSuccess';
import AddToNftFailureIcon from '../../Icons/AddToNftFailure';
import FeatureCardIcon from '../../Icons/FeatureCard';
import Clock from '../../Icons/Clock';
import Countdown from '../common/Countdown';
import { CompetitionCommentsDrawer } from '../modals/CompetitionCommentsDrawer';
import { getCompetitionComments } from '../../api/competitionComments/api';
import { BellIcon } from '@chakra-ui/icons';
import { CompetitionObject } from '../../interfaces/components';
import { isAfter, parseISO, isBefore } from 'date-fns';
import CommentIcon from '../../Icons/CommentIcon';
import CommentSpinner from '../common/CommentSpinner';
import { subscribeToCompetition, unsubscribeToCompetition } from '../../api/competitions/apis';

interface CompetitionStatsProps {
  competitionDetails: CompetitionObject;
  bgColorMainBox: string;
  playerCount: number;
  countDownDate: any;
  startDate: string;
  totalVotes: number;
  onVoteNowClick: () => void;
}

interface TimeTextProps {
  text: string;
}

export default function CompetitionStats(props: CompetitionStatsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isCommentsModalOPen, onOpen: onCommentsModalOpen, onClose: onCommentsModalClose } = useDisclosure();
  const { bgColorMainBox, playerCount, totalVotes, competitionDetails, onVoteNowClick } = props;
  const toast = useToast();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [reRender, setRerender] = useState(false);
  const numberOfComments = useAppStore((state: AppStoreState) => state.numberOfComments);
  const setNumberOfComments = useAppStore((state: AppStoreState) => state.setNumberOfComments);
  const addNftToCompetitionStatus = useAppStore((state: AppStoreState) => state.addNftToCompetitionStatus);
  const setMintNowView = useAppStore((state: AppStoreState) => state.setMintNowView);
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);

  const onParticipateClick = () => {
    setSelectedNft(null);
    setMintNowView(false);
    onOpen();
  };

  const onSaveClick = () => {
    onCommentsModalOpen();
  };

  useEffect(() => {
    if (selectedCompetitionId) {
      selectedCompetitionId && fetchComments(selectedCompetitionId);
    } else {
      const comptId = location && location?.pathname?.split('/').pop();
      comptId && fetchComments(Number(comptId));
    }
  }, []);

  useEffect(() => {
    setRerender(!reRender);
  }, [numberOfComments]);

  const fetchComments = async (id: number) => {
    try {
      const res = await getCompetitionComments(id);
      setNumberOfComments(res?.length);
    } catch (error: unknown) {}
  };

  const TimeText = (props: TimeTextProps) => {
    const { text } = props;
    return (
      <Text
        color="#96BCAD"
        textAlign="center"
        fontFamily="Montserrat"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="600"
        lineHeight="normal"
      >
        {text}
      </Text>
    );
  };

  const onGetNotifiedClick = async (competition: CompetitionObject) => {
    try {
      const res = await subscribeToCompetition(competition?.id);
      toast({
        title: 'Subscribed',
        description: res && res?.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Something went wrong while subscribing to competition',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const unSubscribeCompetition = async (competition: CompetitionObject) => {
    try {
      const res = await unsubscribeToCompetition(competition?.id);
      toast({
        title: 'Subscribed',
        description: res && res?.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Something went wrong while subscribing to competition',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <CompetitionCommentsDrawer
        isOpen={isCommentsModalOPen}
        onOpen={onCommentsModalOpen}
        onClose={onCommentsModalClose}
      />
      {submitClicked && (
        <SubmitNftModal
          heading={addNftToCompetitionStatus === 'success' ? 'Submitted successfully' : 'Submission failed'}
          subHeading={
            addNftToCompetitionStatus === 'success'
              ? 'Your NFT has been submitted into the competition.'
              : 'Your NFT has been created, but failed to submit, please try to resubmit it later.'
          }
          buttonText={'Close'}
          buttonBgColor={addNftToCompetitionStatus === 'success' ? '#118BCF' : '#E13838'}
          isModalOpen={submitClicked}
          onModalOpen={onOpen}
          onModalClose={onClose}
          setSubmitClicked={setSubmitClicked}
          modalIcon={addNftToCompetitionStatus === 'success' ? <AddToNftSuccessIcon /> : <AddToNftFailureIcon />}
        />
      )}
      <SubmitNftModal
        isModalOpen={isOpen}
        onModalOpen={onOpen}
        onModalClose={onClose}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
        heading={'Submit your NFT'}
        subHeading={'Browse your NFTs or mint a new one to compete in this competition.'}
        buttonText={'Browse NFTs'}
        buttonBgColor={'#222'}
        modalIcon={<FeatureCardIcon />}
      />
      <Box
        width={['auto', 'auto', 'auto', '500px', '590px']}
        marginTop={['unset', 'unset', 'unset', '24px']}
        borderRadius="24px"
        background={bgColorMainBox}
        padding="10px"
      >
        <Box
          borderRadius="15px"
          background="rgba(0, 0, 0, 0.20)"
          padding="10px"
          display="flex"
          flexDirection={['column', 'row', 'row']}
        >
          <Box
            width={['unset', '33%', '33%']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
              <GroupIcon />
              <Text
                color="#96BCAD"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Entries
              </Text>
            </Box>
            <Box>
              <Text
                color=" #FFF"
                textAlign="center"
                fontFamily=" Montserrat"
                fontSize="20px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {playerCount ? playerCount : 0}
              </Text>
            </Box>
          </Box>
          <Box display={['none', 'block', 'block']}>
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="vertical" height="56px" />
          </Box>
          <Box display={['block', 'none', 'none']} marginTop="10px" marginBottom="10px">
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="horizontal" />
          </Box>

          <Box
            width={['unset', '33%', '33%']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
              <VotesIcon color="#96BCAD" />
              <Text
                color="#96BCAD"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Total Votes
              </Text>
            </Box>
            <Box>
              <Text
                color=" #FFF"
                textAlign="center"
                fontFamily=" Montserrat"
                fontSize="20px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {totalVotes}
              </Text>
            </Box>
          </Box>

          <Box display={['none', 'block', 'block']}>
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="vertical" height="56px" />
          </Box>
          <Box display={['block', 'none', 'none']} marginTop="10px" marginBottom="10px">
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="horizontal" />
          </Box>
          <Box
            width={['unset', '33%', '33%']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
              <Clock color="#96BCAD" w="24px" h="24px" />
              {isAfter(parseISO(competitionDetails?.participation_starts), new Date()) ? (
                <TimeText text={'Opens in'} />
              ) : isAfter(new Date(), parseISO(competitionDetails?.participation_starts)) &&
                isBefore(new Date(), parseISO(competitionDetails?.voting_starts)) ? (
                <TimeText text={'Entries close in'} />
              ) : isAfter(new Date(), parseISO(competitionDetails?.voting_starts)) &&
                isBefore(new Date(), parseISO(competitionDetails?.voting_ends)) ? (
                <TimeText text={'Voting ends in'} />
              ) : isAfter(new Date(), parseISO(competitionDetails?.voting_ends)) ? (
                <TimeText text={'Closed'} />
              ) : (
                ''
              )}
            </Box>
            <Box>
              {isAfter(parseISO(competitionDetails?.participation_starts), new Date()) ? (
                <Countdown
                  endDateString={competitionDetails?.participation_starts}
                  color=" #FFF"
                  fontSize="13px"
                  fontWeight={600}
                />
              ) : isAfter(new Date(), parseISO(competitionDetails?.participation_starts)) &&
                isBefore(new Date(), parseISO(competitionDetails?.voting_starts)) ? (
                <Countdown
                  endDateString={competitionDetails?.voting_starts}
                  color=" #FFF"
                  fontSize="13px"
                  fontWeight={600}
                />
              ) : isAfter(new Date(), parseISO(competitionDetails?.voting_starts)) &&
                isBefore(new Date(), parseISO(competitionDetails?.voting_ends)) ? (
                <Countdown
                  endDateString={competitionDetails?.voting_ends}
                  color=" #FFF"
                  fontSize="13px"
                  fontWeight={600}
                />
              ) : (
                isAfter(new Date(), parseISO(competitionDetails?.voting_ends)) && ''
              )}
            </Box>
          </Box>
        </Box>

        {!accessToken ? (
          <Box marginTop="10px">
            <Button
              width="100%"
              borderRadius="15px"
              border=" 0.5px solid rgba(255, 255, 255, 0.20)"
              padding="16px 32px"
              leftIcon={<CommentIcon />}
              color=" #FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              h={['52px', '52px', '56px']}
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comments <CommentSpinner numberOfComments={numberOfComments} />
            </Button>
          </Box>
        ) : isAfter(parseISO(competitionDetails?.participation_starts), new Date()) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="10px">
            <Button
              width="50%"
              borderRadius="15px"
              border="0.5px solid rgba(255, 255, 255, 0.20)"
              padding="12px 24px"
              color="#FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comments <CommentSpinner numberOfComments={numberOfComments} />
            </Button>
            <Button
              width="50%"
              borderRadius="15px"
              padding="12px 24px"
              color="#FFF"
              leftIcon={<BellIcon />}
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => {
                competitionDetails?.is_subscribed
                  ? unSubscribeCompetition(competitionDetails)
                  : onGetNotifiedClick(competitionDetails);
              }}
            >
              {competitionDetails?.is_subscribed ? 'Unsubscribe' : ' Get notified'}
            </Button>
          </Box>
        ) : isAfter(parseISO(competitionDetails?.voting_starts), competitionDetails?.participation_starts) &&
          isAfter(parseISO(competitionDetails?.voting_starts), new Date()) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="10px">
            <Button
              width="50%"
              borderRadius="15px"
              border="0.5px solid rgba(255, 255, 255, 0.20)"
              padding="12px 24px"
              color="#FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comments <CommentSpinner numberOfComments={numberOfComments} />
            </Button>
            <Button
              width="50%"
              borderRadius="15px"
              padding="12px 24px"
              color=" #FFF"
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => onParticipateClick()}
            >
              Participate
            </Button>
          </Box>
        ) : isBefore(parseISO(competitionDetails?.participation_starts), competitionDetails?.voting_starts) &&
          isAfter(parseISO(competitionDetails?.voting_ends), new Date()) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="10px">
            <Button
              width="50%"
              borderRadius="15px"
              border="0.5px solid rgba(255, 255, 255, 0.20)"
              padding="12px 24px"
              color="#FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comments <CommentSpinner numberOfComments={numberOfComments} />
            </Button>
            <Button
              width="50%"
              borderRadius="15px"
              padding="12px 24px"
              color=" #FFF"
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={onVoteNowClick}
            >
              Vote
            </Button>
          </Box>
        ) : (
          isBefore(parseISO(competitionDetails?.voting_ends), new Date()) && (
            <Box marginTop="10px">
              <Button
                width="100%"
                borderRadius="15px"
                border="0.5px solid rgba(255, 255, 255, 0.20)"
                padding="12px 24px"
                color="#FFF"
                backgroundColor="#222"
                fontSize={['16px', '16px', '20px']}
                fontWeight="600"
                height="48px"
                _hover={{
                  color: 'white',
                  bg: 'rgba(255, 255, 255, 0.20)',
                }}
                onClick={onSaveClick}
              >
                Comments <CommentSpinner numberOfComments={numberOfComments} />
              </Button>
            </Box>
          )
        )}
      </Box>
    </>
  );
}
