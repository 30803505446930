import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export default function TermsAndCondition() {
  return (
    <Box>
      <Text color="#fff">Resources here</Text>
    </Box>
  );
}
