import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CloseIcon(props: IconProps) {
  return (
    <Icon width="29px" height="28px" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_1014_30904" maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="28">
        <rect x="0.21582" width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1014_30904)">
        <path
          d="M14.2158 15.6333L8.49909 21.35C8.2852 21.5639 8.01298 21.6708 7.68242 21.6708C7.35187 21.6708 7.07964 21.5639 6.86576 21.35C6.65187 21.1361 6.54492 20.8639 6.54492 20.5333C6.54492 20.2028 6.65187 19.9306 6.86576 19.7167L12.5824 14L6.86576 8.28333C6.65187 8.06944 6.54492 7.79722 6.54492 7.46666C6.54492 7.13611 6.65187 6.86388 6.86576 6.65C7.07964 6.43611 7.35187 6.32916 7.68242 6.32916C8.01298 6.32916 8.2852 6.43611 8.49909 6.65L14.2158 12.3667L19.9324 6.65C20.1463 6.43611 20.4185 6.32916 20.7491 6.32916C21.0796 6.32916 21.3519 6.43611 21.5658 6.65C21.7796 6.86388 21.8866 7.13611 21.8866 7.46666C21.8866 7.79722 21.7796 8.06944 21.5658 8.28333L15.8491 14L21.5658 19.7167C21.7796 19.9306 21.8866 20.2028 21.8866 20.5333C21.8866 20.8639 21.7796 21.1361 21.5658 21.35C21.3519 21.5639 21.0796 21.6708 20.7491 21.6708C20.4185 21.6708 20.1463 21.5639 19.9324 21.35L14.2158 15.6333Z"
          // fill="black"
        />
      </g>
    </Icon>
  );
}
