import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CommentDropDown(props: IconProps) {
  return (
    <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.66675 11.2866C3.66675 11.4446 3.69787 11.6011 3.75833 11.747C3.81879 11.893 3.90741 12.0256 4.01914 12.1374C4.13086 12.2491 4.26349 12.3377 4.40946 12.3982C4.55543 12.4586 4.71188 12.4897 4.86987 12.4897C5.02787 12.4897 5.18432 12.4586 5.33029 12.3982C5.47626 12.3377 5.60889 12.2491 5.72061 12.1374C5.83233 12.0256 5.92095 11.893 5.98142 11.747C6.04188 11.6011 6.073 11.4446 6.073 11.2866C6.073 11.1286 6.04188 10.9722 5.98142 10.8262C5.92095 10.6802 5.83233 10.5476 5.72061 10.4359C5.60889 10.3242 5.47626 10.2355 5.33029 10.1751C5.18432 10.1146 5.02787 10.0835 4.86987 10.0835C4.71188 10.0835 4.55543 10.1146 4.40946 10.1751C4.26349 10.2355 4.13086 10.3242 4.01914 10.4359C3.90741 10.5476 3.81879 10.6802 3.75833 10.8262C3.69787 10.9722 3.66675 11.1286 3.66675 11.2866ZM9.68237 11.2866C9.68237 11.6057 9.80913 11.9117 10.0348 12.1374C10.2604 12.363 10.5664 12.4897 10.8855 12.4897C11.2046 12.4897 11.5106 12.363 11.7362 12.1374C11.9619 11.9117 12.0886 11.6057 12.0886 11.2866C12.0886 10.9675 11.9619 10.6615 11.7362 10.4359C11.5106 10.2103 11.2046 10.0835 10.8855 10.0835C10.5664 10.0835 10.2604 10.2103 10.0348 10.4359C9.80913 10.6615 9.68237 10.9675 9.68237 11.2866ZM15.698 11.2866C15.698 11.6057 15.8248 11.9117 16.0504 12.1374C16.276 12.363 16.582 12.4897 16.9011 12.4897C17.2202 12.4897 17.5262 12.363 17.7519 12.1374C17.9775 11.9117 18.1042 11.6057 18.1042 11.2866C18.1042 10.9675 17.9775 10.6615 17.7519 10.4359C17.5262 10.2103 17.2202 10.0835 16.9011 10.0835C16.582 10.0835 16.276 10.2103 16.0504 10.4359C15.8248 10.6615 15.698 10.9675 15.698 11.2866Z"
        fill="#85858C"
      />
    </Icon>
  );
}
