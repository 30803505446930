import { Box, Text, Image, Progress, useToast } from '@chakra-ui/react';
import React from 'react';
import CustomRightArrow from '../../Icons/CustomArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserLevelResponse } from '../../interfaces/components';
import storage from '../../utils/storage';
import CheckIcon from '../../Icons/CheckIcon';

interface RankProps {
  isProfilePage?: boolean;
  userLevelData?: UserLevelResponse | null;
  isComplete?: boolean;
  isAfter?: boolean;
}

export default function Rank(props: RankProps) {
  const { userLevelData, isComplete = false, isProfilePage, isAfter = false } = props;
  const walletAddress = storage.get('walletAddress');
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const onProgressClick = () => {
    if (walletAddress) {
      navigate('/my-profile/progress');
    } else {
      toast({
        title: 'Connect your wallet',
        description: 'Connect your wallet first to view your progress',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      borderRadius="26px"
      backgroundColor=" rgba(255, 255, 255, 0.10)"
      width={'100%'}
      padding="1px"
      background="#242424"
      style={
        userLevelData?.current_user_level
          ? {
              backgroundImage:
                'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(to bottom right, #04D3FF 0%, #28F09A 50%, #3AFF65 100%)',
              backgroundOrigin: 'border-box',
              backgroundClip: ' content-box, border-box',
            }
          : {
              border: 'var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10)',
            }
      }
    >
      <Box padding={'24px'}>
        <Box display="flex" gap="16px">
          <Box width="60px" height="68px">
            <Image
              src={userLevelData?.level_image}
              alt="User Level"
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                imageRendering: 'auto', // Adjust rendering to avoid pixelation
              }}
            />
          </Box>
          <Box display="flex" justifyContent={'center'} alignItems="flex-start" flexDirection="column" gap="6px">
            <Box borderRadius="19px" background=" rgba(255, 255, 255, 0.10)" padding="4px 10px">
              <Text
                color="#FFF"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Rank
              </Text>
            </Box>
            <Text
              color="#FFF"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
            >
              {userLevelData?.level_name}
            </Text>
          </Box>
        </Box>
        <Box height="21px" marginTop="24px" display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Text
              color={isAfter ? '#8C8C8C' : '#19A0ED'}
              fontFamily="Montserrat"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
              marginBottom="24px"
            >
              {userLevelData?.current_points
                ? userLevelData?.current_points
                : isComplete
                ? userLevelData?.ending_points + 'XP'
                : userLevelData?.starting_points}
              <span
                style={{
                  color: '#8C8C8C',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                {isComplete
                  ? ''
                  : `${userLevelData?.current_user_level || isProfilePage ? '/' : '-'}${
                      userLevelData?.ending_points
                    }XP`}
              </span>
            </Text>
          </Box>

          {userLevelData?.next_level_name !== 'N/A' && (
            <Box display="flex" alignItems="center" gap={'2px'}>
              {isComplete ? <CheckIcon /> : <CustomRightArrow fillColor={'#D9D9D9'} />}
              <Text
                color={isComplete ? '#19A0ED' : '#8C8C8C'}
                fontSize=" 14px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {isComplete ? 'Complete' : userLevelData?.next_level_name}
              </Text>
            </Box>
          )}
        </Box>
        <Box marginTop="8px">
          <Progress borderRadius="26px" value={isComplete ? 100 : userLevelData?.percentage_completion} height="16px" />
        </Box>
        {location?.pathname === '/my-profile' && (
          <Box display="flex" justifyContent="flex-end" marginTop="16px">
            <Box
              display="flex"
              alignItems="center"
              gap="5px"
              justifyContent="center"
              cursor="pointer"
              onClick={onProgressClick}
            >
              <Text
                color="#19A0ED"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Your Progress
              </Text>
              <CustomRightArrow fillColor={'#19A0ED'} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
