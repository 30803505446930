import { Text, TabList } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { CustomTab } from './CustomTab';

interface TabOptionListProps {
  optionOne: string;
  optionTwo: string;
  tabIndexValue: number;
  iconOne?: ReactNode;
  iconTwo?: ReactNode;
}

export const TabOptionList = (props: TabOptionListProps) => {
  const { optionOne, optionTwo, tabIndexValue, iconOne, iconTwo } = props;
  return (
    <TabList
      gap="2"
      borderRadius="20px"
      border="0.5px solid rgba(255, 255, 255, 0.20)"
      background="#222"
      padding="6px"
      width={['100%', 'fit-content', 'fit-content']}
    >
      <CustomTab isActive={tabIndexValue === 0}>
        {iconOne}
        <Text color="currentcolor" fontSize={['14px', '16px', '20px']} fontWeight="600">
          {optionOne}
        </Text>
      </CustomTab>
      <CustomTab isActive={tabIndexValue === 1} isCompetition={optionTwo === 'Competitions' ? true : false}>
        {iconTwo}
        <Text color="currentcolor" fontSize={['14px', '16px', '20px']} fontWeight="600">
          {optionTwo}
        </Text>
      </CustomTab>
    </TabList>
  );
};
