import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CheckIcon(props: IconProps) {
  return (
    <Icon width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_2089_4677" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
        <rect x="0.333252" y="0.460938" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2089_4677)">
        <path
          d="M6.69965 10.5609L12.3497 4.91094C12.483 4.7776 12.6385 4.71094 12.8163 4.71094C12.9941 4.71094 13.1497 4.7776 13.283 4.91094C13.4163 5.04427 13.483 5.2026 13.483 5.38594C13.483 5.56927 13.4163 5.7276 13.283 5.86094L7.16632 11.9943C7.03298 12.1276 6.87743 12.1943 6.69965 12.1943C6.52187 12.1943 6.36632 12.1276 6.23298 11.9943L3.36632 9.1276C3.23298 8.99427 3.16909 8.83594 3.17465 8.6526C3.18021 8.46927 3.24965 8.31094 3.38298 8.1776C3.51632 8.04427 3.67465 7.9776 3.85798 7.9776C4.04132 7.9776 4.19965 8.04427 4.33298 8.1776L6.69965 10.5609Z"
          fill="#19A0ED"
        />
      </g>
    </Icon>
  );
}
