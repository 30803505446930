import { Box, Text, Image } from '@chakra-ui/react';
import React from 'react';
import TrophyIcon from '../../Icons/Trophy';
import stackedCards from '../../assets/competitionsStacked.png';

export default function CompetitionOverviewHero() {
  return (
    <Box
      position={['unset', 'unset', 'unset', 'unset', 'relative']}
      display="flex"
      flexDirection={['column-reverse', 'column-reverse', 'row', 'row', 'row']}
      padding={['12px 24px', '24px', '36px']}
      borderRadius="36px"
      background=" radial-gradient(1260.58% 227.12% at 106.21% 87.11%, rgba(4, 211, 255, 0.30) 0%, rgba(58, 255, 101, 0.30) 100%)"
    >
      <Box>
        <Box
          borderRadius="25px"
          border="0.5px solid rgba(255, 255, 255, 0.20)"
          backgroundColor="#222"
          width="fit-content"
          gap="6px"
          display={['none', 'none', 'flex']}
          padding="12px 24px"
          justifyContent="center"
          alignItems="center"
        >
          <TrophyIcon />
          <Text
            color="#FFF"
            fontFamily=" Montserrat"
            fontSize=" 21px"
            fontStyle=" normal"
            fontWeight=" 600"
            lineHeight=" normal"
          >
            Challenge Accepted
          </Text>
        </Box>
        <Text
          width={['unset', 'unset', 'unset', 'unset', '67%']}
          color="#FFF"
          fontFamily=" Montserrat"
          fontSize={['36px', '36px', '46px', '64px']}
          fontStyle="normal"
          fontWeight="700"
          lineHeight={['40px', '40px', '50px', '60px']}
          marginTop={['unset', 'unset', '36px']}
        >
          Go Viral on the Blockchain!
        </Text>
        <Text
          color=" rgba(255, 255, 255, 0.50)"
          fontFamily=" Montserrat"
          fontSize=" 20px"
          fontStyle=" normal"
          fontWeight="500"
          lineHeight="normal"
          marginTop="16px"
        >
          Show off your wit & creativity - Compete & Mint Winning NFTs on Layer X.
        </Text>
      </Box>
      <Box
        position={['unset', 'unset', 'unset', 'unset', 'absolute']}
        right={['unset', 'unset', 'unset', 'unset', '20px']}
        top={['unset', 'unset', 'unset', 'unset', '-50px']}
      >
        <Box
          borderRadius="18px"
          border="0.5px solid rgba(255, 255, 255, 0.20)"
          backgroundColor="#222"
          width={['fit-content']}
          gap="6px"
          display={['flex', 'flex', 'none']}
          padding="8px 16px"
          justifyContent="center"
          alignItems="center"
        >
          <TrophyIcon isMobile={true} />
          <Text
            color="#FFF"
            fontFamily="Montserrat"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="normal"
          >
            Challenge Accepted
          </Text>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Image src={stackedCards} />
        </Box>
      </Box>
    </Box>
  );
}
