import { Box, Button, Text, Image } from '@chakra-ui/react';
import allNftheroImage from '../../assets/allNftheroImage.png';
import React from 'react';
import { useAppStore, AppStoreState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { loginWithXpad } from '../xpadAuth';

export default function AllNftsHero() {
  const navigate = useNavigate();
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);

  const onMintYoursClick = () => {
    if (accessToken) {
      navigate('/mint-nft');
    } else {
      loginWithXpad();
    }
  };
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Image src={allNftheroImage} h={['unset', 'unset', 'unset', '182px']} objectFit="contain" />
      </Box>
      <Box marginTop={['24px', '24px', '24px', '54px', '54px']}>
        <Text
          color="#FFF"
          textAlign="center"
          fontSize={['44px', '44px', '44px', '54px', '54px', '54px']}
          fontWeight="700"
          fontFamily="Montserrat"
        >
          <span className="all-nft-text">NFT</span> Overview
        </Text>
      </Box>
      <Box marginTop={['24px', '24px', '24px']}>
        <Text
          color="rgba(255, 255, 255, 0.50)"
          textAlign="center"
          fontSize={'18px'}
          fontWeight="500"
          fontFamily="Montserrat"
        >
          Explore unique NFTs, and get a piece of valuable gems!
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection={['column', 'row', 'row']}
        justifyContent="center"
        gap={['20px', '24x', '24px']}
        marginTop="24px"
      >
        <Button
          width={['auto', '50%', '267px', '267px', '267px']}
          borderRadius="15px"
          borderTop="1px solid #04D3FF"
          padding="16px 32px"
          color=" #FFF"
          backgroundColor="#118BCF"
          fontSize={['16px', '16px', '20px']}
          fontWeight="600"
          h={['52px', '52px', '56px']}
          _hover={{
            color: 'white',
            bg: '#43BDD7',
          }}
          onClick={onMintYoursClick}
        >
          Mint Yours
        </Button>
        <Button
          width={['auto', '50%', '267px', '267px', '267px']}
          borderRadius="15px"
          backgroundColor="#222"
          padding="16px 32px"
          color=" #FFF"
          fontSize={['16px', '16px', '20px']}
          fontWeight="600"
          h={['52px', '52px', '56px']}
          _hover={{
            color: 'white',
            bg: 'rgba(255, 255, 255, 0.20)',
          }}
          border=" 0.5px solid rgba(255, 255, 255, 0.20)"
          onClick={() => navigate('/competitions')}
        >
          View Competitions
        </Button>
      </Box>
    </Box>
  );
}
