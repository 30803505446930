import { Box } from '@chakra-ui/react';
import React from 'react';
import AllNftsHero from './allNftsHero';
import AllNfts from '../Mint/AllNfts';
import NftCollectionSection from './CollectionSection';

export default function AllNftsMain() {
  return (
    <Box
      zIndex={2}
      flexDirection="column"
      maxHeight="100%"
      marginBottom={['56px', '64px', '128px']}
      marginTop="20px"
      maxWidth="1400px"
    >
      <AllNftsHero />
      <AllNfts />
      <NftCollectionSection />
    </Box>
  );
}
