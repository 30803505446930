import { Box, Text, Button, Image, Input, InputGroup, InputLeftElement, FormControl, useToast } from '@chakra-ui/react';
import BoltIcon from '../../assets/bolt.png';
import React, { useEffect } from 'react';
import CopyLinkIcon from '../../Icons/CopyLink';
import { useForm } from 'react-hook-form';
import { AppStoreState, useAppStore } from '../../store';
import { TweetScreenshotPayload } from '../../interfaces/components';
import { addTweetScreenShot, saveNFT } from '../../api/nfts/apis';
import { SMART_CONTRACT_ADDRESS, ABI } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { waitForTransactionReceipt } from 'wagmi/actions';
import { wagmiConfig } from '../../utils/wagmi';
import { useAccount, useChainId } from 'wagmi';
import LoadingSpinner from '../common/LoadingSpinner';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import Web3 from 'web3';

interface MintNftProps {
  heading: string;
  subHeading: string;
  btnText: string;
  onBrowseModalClose?: () => void;
}

export default function MintNft(props: MintNftProps) {
  const { heading, subHeading, btnText, onBrowseModalClose } = props;
  const { address, connector } = useAccount();
  const { open } = useWeb3Modal();
  const toast = useToast();
  const navigate = useNavigate();
  const chaiId = useChainId();
  const { handleSubmit, register, reset } = useForm<any>();
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const mintNowView = useAppStore((state: AppStoreState) => state.mintNowView);
  const setMintedImage = useAppStore((state: AppStoreState) => state.setMintedImage);
  const mintedImage = useAppStore((state: AppStoreState) => state.mintedImage);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const setRerender = useAppStore((state: AppStoreState) => state.setRerender);
  const reRender = useAppStore((state: AppStoreState) => state.reRender);

  useEffect(() => {
    if (mintedImage) {
      setMintedImage(undefined);
    }
  }, []);

  const handleLogin = async () => {
    open({ view: 'Networks' }).then((res) => {
      console.log('handle login res network', res);
    });
  };

  const onConnectButtonClick = async () => {
    handleLogin();
  };

  const handleFormSubmit = async (data: TweetScreenshotPayload) => {
    {
      if (!address) {
        console.log('give connect wallet functionality');
        onBrowseModalClose && onBrowseModalClose();
        onConnectButtonClick();
      } else
        try {
          setLoading(true);
          const res = await addTweetScreenShot(data);
          if (res && res.data.json_cid) {
            const json_cid = res.data.json_cid;
            const web3 = new Web3((await connector?.getProvider()) as any);
            const contract = new web3.eth.Contract(ABI, SMART_CONTRACT_ADDRESS);

            const response = await contract.methods.safeMint(`ipfs://${json_cid}`).send({
              from: address,
            });

            const transactionReceipt = await waitForTransactionReceipt(wagmiConfig, {
              chainId: chaiId,
              hash: response.transactionHash as `0x${string}`,
            });

            const saveResponse = await saveNFT(transactionReceipt.transactionHash);

            if (mintNowView) {
              setSelectedNft && setSelectedNft(saveResponse?.data);
              setRerender(!reRender);
            } else {
              setMintedImage(saveResponse.data.display_image_url);
              navigate(`/minted-nft?url=${saveResponse.data.image_url}&identifier=${saveResponse.data.identifier}`);
            }
            setLoading(false);
            reset();
          }
        } catch (error: any) {
          console.log('error', error);
          setLoading(false);
          toast({
            title: 'Error',
            description: 'There was an error during transaction. Please try again',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
    }
  };

  return (
    <>
      {false ? (
        <LoadingSpinner />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Box
            padding={'1px'}
            zIndex={2}
            style={{
              borderRadius: '24px',
              backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#118BCF, #3AFF65, #3AFF65)',
              backgroundOrigin: 'border-box',
              backgroundClip: ' content-box, border-box',
            }}
          >
            <Box
              padding={['20px 20px', '36px', '36px']}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Image src={BoltIcon} />

              <Text fontWeight={700} fontSize="28px" color="#fff">
                {heading}
              </Text>
              <Text fontWeight={500} fontSize="18px" color="rgba(255, 255, 255, 0.50)" marginTop="14px">
                {subHeading}
              </Text>
              <form onSubmit={handleSubmit(handleFormSubmit)} style={{ marginTop: '24px' }}>
                <FormControl>
                  <InputGroup
                    borderRadius="15px"
                    backgroundColor=" #000"
                    size="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <InputLeftElement pointerEvents="none">
                      <Box marginTop="10px" height="60px" display="flex" justifyContent="center" alignItems="center">
                        <CopyLinkIcon />
                      </Box>
                    </InputLeftElement>
                    <Input
                      borderRadius="15px"
                      height="56px"
                      border="none"
                      placeholder="Click to paste link"
                      className="my-input"
                      borderTop=" 1px solid #414141"
                      color="#ffffff"
                      width="100%"
                      {...register('tweet_url')}
                    />
                  </InputGroup>
                </FormControl>

                <Button
                  marginTop="24px"
                  width={['100%', '100%', '474px', '474px']}
                  borderRadius="15px"
                  borderTop="1px solid #43BDD7"
                  backgroundColor="#118BCF"
                  padding="16px 32px"
                  color="#FFF"
                  fontSize={loading ? '16px' : '20px'}
                  fontWeight="600"
                  h="56px"
                  _hover={{
                    color: 'white',
                    bg: '#43BDD7',
                  }}
                  type="submit"
                  isLoading={loading}
                  loadingText="Processing. This may take a while."
                  disabled={loading}
                  spinnerPlacement="end"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {btnText}
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
