import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function NFTFilled(props: IconProps) {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_1671_8098"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1671_8098)">
        <path d="M8.99997 15.0003H5.89997C5.49997 15.0003 5.20414 14.8212 5.01247 14.4628C4.8208 14.1045 4.84164 13.7587 5.07497 13.4253L12.55 2.67533C12.7166 2.442 12.9333 2.2795 13.2 2.18783C13.4666 2.09616 13.7416 2.10033 14.025 2.20033C14.3083 2.30033 14.5166 2.47533 14.65 2.72533C14.7833 2.97533 14.8333 3.242 14.8 3.52533L14 10.0003H17.875C18.3083 10.0003 18.6125 10.192 18.7875 10.5753C18.9625 10.9587 18.9083 11.317 18.625 11.6503L10.4 21.5003C10.2166 21.717 9.99164 21.8587 9.72497 21.9253C9.4583 21.992 9.19997 21.967 8.94997 21.8503C8.69997 21.7337 8.50414 21.5545 8.36247 21.3128C8.2208 21.0712 8.16664 20.8087 8.19997 20.5253L8.99997 15.0003Z" />
      </g>
    </Icon>
  );
}
