import { NavbarOption } from './interfaces/components';

export const navbarOptions: NavbarOption[] = [
  {
    href: '/',
    key: 'dashboard',
    text: 'Dashboard',
  },
  {
    href: '/investments',
    key: 'investments',
    text: 'Investment tracker',
  },
  {
    href: '/clients',
    key: 'clients',
    text: 'Clients',
  },
  {
    href: '/payouts',
    key: 'payouts',
    text: 'Upcoming payouts',
  },
];

export const unauthorizedNavbarOptions: NavbarOption[] = [
  { text: 'Home', href: '/', key: 'home' },
  { text: 'NFTs', href: '/nfts', key: 'allnfts' },
  { text: 'Competitions', href: '/competitions', key: 'competitions' },
];
