import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function EthereumLogo(props: IconProps) {
  return (
    <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12.5366" cy="12" r="12" fill="#EDF0F4" />
      <path
        d="M12.5042 4.7998L12.4087 5.12406V14.5324L12.5042 14.6276L16.8713 12.0462L12.5042 4.7998Z"
        fill="#343434"
      />
      <path d="M12.504 4.7998L8.13672 12.0462L12.504 14.6276V10.0611V4.7998Z" fill="#8C8C8C" />
      <path d="M12.504 15.4546L12.4502 15.5202V18.8716L12.504 19.0287L16.8738 12.8745L12.504 15.4546Z" fill="#3C3C3B" />
      <path d="M12.504 19.0287V15.4546L8.13672 12.8745L12.504 19.0287Z" fill="#8C8C8C" />
      <path d="M12.5039 14.6276L16.8711 12.0461L12.5039 10.061V14.6276Z" fill="#141414" />
      <path d="M8.13672 12.0461L12.504 14.6276V10.061L8.13672 12.0461Z" fill="#393939" />
    </Icon>
  );
}
