import { Icon } from '@chakra-ui/icons';
import React from 'react';

interface ClockProps {
  color: string;
  w?: string;
  h?: string;
}

export default function Clock(props: ClockProps) {
  const { color, w, h } = props;
  return (
    <Icon
      width={w ? w : '20px'}
      height={h ? h : '20px'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_367_9995"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_367_9995)">
        <path
          d="M10.6247 9.74683V6.45828C10.6247 6.2812 10.5647 6.13273 10.4449 6.01287C10.325 5.89314 10.1766 5.83328 9.99947 5.83328C9.82225 5.83328 9.67384 5.89314 9.55426 6.01287C9.43454 6.13273 9.37468 6.2812 9.37468 6.45828V9.93912C9.37468 10.0372 9.39287 10.1322 9.42926 10.2241C9.46551 10.3161 9.52266 10.4011 9.60072 10.4791L12.4549 13.3333C12.5702 13.4487 12.7152 13.5077 12.8899 13.5104C13.0646 13.513 13.2123 13.454 13.333 13.3333C13.4537 13.2126 13.5141 13.0662 13.5141 12.8941C13.5141 12.7222 13.4537 12.5759 13.333 12.4552L10.6247 9.74683ZM10.0011 17.9166C8.90613 17.9166 7.8769 17.7088 6.91342 17.2933C5.94995 16.8777 5.1119 16.3138 4.39926 15.6014C3.68662 14.889 3.12238 14.0513 2.70655 13.0883C2.29085 12.1252 2.08301 11.0963 2.08301 10.0014C2.08301 8.90641 2.29079 7.87717 2.70634 6.9137C3.1219 5.95023 3.68586 5.11217 4.39822 4.39953C5.11058 3.68689 5.94829 3.12266 6.91134 2.70682C7.8744 2.29113 8.90336 2.08328 9.99822 2.08328C11.0932 2.08328 12.1225 2.29106 13.0859 2.70662C14.0494 3.12217 14.8875 3.68613 15.6001 4.39849C16.3127 5.11085 16.877 5.94856 17.2928 6.91162C17.7085 7.87467 17.9163 8.90363 17.9163 9.99849C17.9163 11.0935 17.7086 12.1227 17.293 13.0862C16.8775 14.0497 16.3135 14.8877 15.6011 15.6004C14.8888 16.313 14.0511 16.8772 13.088 17.2931C12.125 17.7088 11.096 17.9166 10.0011 17.9166ZM9.99968 16.6666C11.8469 16.6666 13.4198 16.0173 14.7184 14.7187C16.017 13.4201 16.6663 11.8472 16.6663 9.99995C16.6663 8.15273 16.017 6.57981 14.7184 5.2812C13.4198 3.98259 11.8469 3.33328 9.99968 3.33328C8.15245 3.33328 6.57954 3.98259 5.28093 5.2812C3.98231 6.57981 3.33301 8.15273 3.33301 9.99995C3.33301 11.8472 3.98231 13.4201 5.28093 14.7187C6.57954 16.0173 8.15245 16.6666 9.99968 16.6666Z"
          fill={
            color
            // "#0FBF95"
          }
        />
      </g>
    </Icon>
  );
}
