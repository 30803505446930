import { PostCommentPayload } from '../../interfaces/components';
import axios from '../axios';

export const getAllComments = async (id: number): Promise<any> => {
  try {
    const endPoint = `/api/nfts/${id}/comments/`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addComments = async (id: number, data: PostCommentPayload): Promise<any> => {
  try {
    const endPoint = `/api/nfts/${id}/comments/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateComment = async (commentId: number, comment: string, nftId: number): Promise<any> => {
  try {
    const endPoint = `/api/nfts/comment/${commentId}/`;
    const res = await axios.put(
      endPoint,
      { comment: comment, nft: nftId },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (!res?.data) throw 'Something went wrong while adding NFT to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteComment = async (commentId: string): Promise<any> => {
  try {
    const endPoint = `/api/nfts/comment/${commentId}/`;
    const res = await axios.delete(endPoint);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
