import { Icon } from '@chakra-ui/icons';
import React from 'react';

export default function CrownIconGold() {
  return (
    <Icon width="68px" height="52px" viewBox="0 0 68 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1014_25646)">
        <g filter="url(#filter1_dii_1014_25646)">
          <path
            d="M19.4195 19.5469L10.8452 13.3233C8.88569 11.9011 6.17146 13.4926 6.45582 15.8971L8.46328 32.8717C8.58985 33.9419 9.32799 34.8445 10.3521 35.18C23.638 39.5328 30.1531 40.9327 49.9494 43.1567C51.0507 43.2805 52.1234 42.7356 52.6718 41.7726L61.0997 26.9707C62.2977 24.8666 60.4172 22.344 58.0586 22.8912L47.7379 25.2857C46.4413 25.5865 45.1136 24.9251 44.5727 23.709L38.4259 9.88988C37.6498 8.14493 35.3848 7.68593 33.9905 8.99104L22.9487 19.3269C21.977 20.2365 20.4966 20.3288 19.4195 19.5469Z"
            fill="url(#paint0_linear_1014_25646)"
          />
          <path
            d="M10.4373 13.8852L19.0116 20.1088C20.3581 21.0861 22.2086 20.9707 23.4232 19.8338L34.465 9.49792C35.5107 8.51909 37.2094 8.86334 37.7916 10.1721L43.9383 23.9912C44.6145 25.5113 46.2741 26.338 47.8948 25.962L58.2155 23.5675C59.9845 23.1571 61.3949 25.0491 60.4963 26.6271L52.0684 41.429C51.6576 42.1505 50.8529 42.5596 50.0269 42.4668C30.2643 40.2465 23.7978 38.8546 10.5683 34.5202C9.80131 34.2689 9.24763 33.5921 9.15278 32.7901L7.14531 15.8155C6.93204 14.0121 8.96772 12.8185 10.4373 13.8852Z"
            stroke="url(#paint1_linear_1014_25646)"
            strokeOpacity="0.5"
            strokeWidth="1.3886"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1014_25646"
          x="-4.88767"
          y="-2.38365"
          width="76.546"
          height="57.0629"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.77721" />
          <feGaussianBlur stdDeviation="2.77721" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1014_25646" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1014_25646" result="shape" />
        </filter>
        <filter
          id="filter1_dii_1014_25646"
          x="5.04658"
          y="8.24023"
          width="57.8133"
          height="37.7108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.3886" />
          <feGaussianBlur stdDeviation="0.694302" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1014_25646" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1014_25646" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-45.8239" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1014_25646" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.77721" />
          <feGaussianBlur stdDeviation="0.694302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.936073 0 0 0 0 0.519445 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_1014_25646" result="effect3_innerShadow_1014_25646" />
        </filter>
        <linearGradient
          id="paint0_linear_1014_25646"
          x1="39.0961"
          y1="-4.80987"
          x2="29.9308"
          y2="40.4169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC00" />
          <stop offset="0.0966666" stopColor="#D8C307" />
          <stop offset="0.186667" stopColor="#FFF246" />
          <stop offset="0.366667" stopColor="#FFF3C0" />
          <stop offset="0.761667" stopColor="#FFC500" />
          <stop offset="1" stopColor="#FBCF82" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1014_25646"
          x1="39.0961"
          y1="-4.80987"
          x2="29.9308"
          y2="40.4169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFBF2" />
          <stop offset="1" stopColor="#FFEEC4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
