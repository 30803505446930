import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Box,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import ModalUnlinkIcon from '../../Icons/ModalUnlinkIcon';
import { useMutation } from '@tanstack/react-query';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { userUnLinkWallet } from '../../api/userApiCalls/user';
import { challengeApi, currentUserApi } from '../../api/api';
import storage from '../../utils/storage';
import { AppStoreState, useAppStore } from '../../store';

interface UnlinkWalletModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const UnlinkWalletModal = (props: UnlinkWalletModalProps) => {
  const { isOpen, onClose } = props;
  const { address } = useAccount();
  const toast = useToast();
  const { signMessageAsync } = useSignMessage();
  const walletAddress = storage.get('walletAddress');
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const walletLinked = useAppStore((state: AppStoreState) => state.walletLinked);
  const setSignature = useAppStore((state: AppStoreState) => state.setSignature);
  const setWalletLinked = useAppStore((state: AppStoreState) => state.setWalletLinked);
  const setUser = useAppStore((state: AppStoreState) => state.setUser);
  const { disconnectAsync } = useDisconnect();
  const [isLoading, setIsLoading] = useState(false);
  const unLinkWalletMutation = useMutation({
    mutationFn: userUnLinkWallet,
  });

  const challengeMutation = useMutation({
    mutationFn: challengeApi,
  });

  const disconnectWallet = async () => {
    await disconnectAsync();
  };

  const getChallenge = async (walletAddress: string) => {
    try {
      const res = await challengeMutation.mutateAsync(walletAddress);
      return res.data;
    } catch (error) {
      console.error('getChallengeError', error);
      throw error;
    }
  };

  console.log('loading', isLoading);

  const onUnLinkClick = async () => {
    if (!address) {
      toast({
        title: 'Connect your wallet',
        description: 'Connect your wallet first to unlink wallet',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } else if (walletAddress) {
      try {
        setIsLoading(true);
        const challenge = await getChallenge(walletAddress);
        const signature = await signMessageAsync({ message: challenge });
        if (!signature) throw 'User declined signature request';

        const res = await unLinkWalletMutation.mutateAsync({
          challenge,
          signature,
          wallet_address: walletAddress,
        });
        if (res.data.wallet_address) {
          setSignature('"yes"');
          return true;
        }
        const user = await currentUserApi();
        storage.set('user', JSON.stringify(res.data));
        setUser(res?.data as any);
        setWalletLinked(walletLinked);
        console.log('user', user);
        disconnectWallet();
        toast({
          title: 'Wallet Unlinked',
          description: 'Wallet unlinked successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } catch (error) {
        console.error('linkWalletError', error);
        toast({
          title: 'Something went wrong',
          description: 'Something went wrong while unlinking wallet. Please try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <Modal isCentered initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="unlink-wallet-modal">
        <ModalBody pb={6} className="modal-body">
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <ModalUnlinkIcon />
            <Text color=" #FFF" fontSize="28px" fontWeight="700" marginTop="24px" textAlign="center">
              Are you sure?
            </Text>
          </Box>
          <Text
            color="rgba(255, 255, 255, 0.50)"
            fontSize="18px"
            fontWeight="500"
            marginTop="10px"
            textAlign="center"
            lineHeight=" normal"
          >
            You will not be able to view the NFTs associated with this wallet address
          </Text>
        </ModalBody>

        <ModalFooter className="modal-footer">
          <Button className="cancel-btn" onClick={onClose}>
            Cancel
          </Button>
          <Button className="unlink-btn" onClick={onUnLinkClick}>
            Unlink wallet
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
