import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import ProgressHero from './ProgressHero';
import Rankings from './Rankings';

export default function MyProgressMain() {
  return (
    <>
      <Box
        zIndex={2}
        flexDirection="column"
        maxHeight="100%"
        marginTop={['11px', '11px', '40px', '40px']}
        maxWidth="1400px"
      >
        <Text
          color="rgba(255, 255, 255, 0.50)"
          fontFamily=" Montserrat"
          fontSize=" 14px"
          fontStyle=" normal"
          fontWeight=" 600"
          lineHeight="normal"
          marginBottom="24px"
        >
          My Profile/
          <span
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            &nbsp;{`Your Progress`}
          </span>
        </Text>
        <ProgressHero />
        <Rankings />
      </Box>
    </>
  );
}
