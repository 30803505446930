import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function XpIconLayerX(props: IconProps) {
  return (
    <Icon width="50" height="50" viewBox="0 0 50 50" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="white" fillOpacity="0.1" />
      <path
        d="M25.9836 32.6327V17.3672H33.3329C34.7577 17.3672 35.9862 17.5998 37.0184 18.065C38.0652 18.5303 38.8721 19.199 39.4391 20.0714C40.0061 20.9291 40.2896 21.9468 40.2896 23.1245C40.2896 24.3021 40.0061 25.3198 39.4391 26.1776C38.8721 27.0353 38.0652 27.7041 37.0184 28.1839C35.9862 28.6491 34.7577 28.8817 33.3329 28.8817H28.8405L31.1303 26.6791V32.6327H25.9836ZM31.1303 27.2462L28.8405 24.9127H33.0058C33.7182 24.9127 34.2416 24.7528 34.5759 24.4329C34.9249 24.1131 35.0993 23.6769 35.0993 23.1245C35.0993 22.572 34.9249 22.1358 34.5759 21.816C34.2416 21.4961 33.7182 21.3362 33.0058 21.3362H28.8405L31.1303 19.0028V27.2462Z"
        fill="#04D3FF"
      />
      <path
        d="M9.71021 32.6327L16.5143 23.1245L16.4924 26.7228L9.90648 17.3672H15.6856L19.5237 23.0372L17.0595 23.059L20.8104 17.3672H26.3496L19.7636 26.5265V22.95L26.6767 32.6327H20.7886L16.9504 26.701L19.3275 26.6791L15.5765 32.6327H9.71021Z"
        fill="#04D3FF"
      />
    </Icon>
  );
}
