import { Box, Grid, GridItem, useToast, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import storage from '../../utils/storage';
import { getUserLookupLevel } from '../../api/userLevels/userLevelApi';
import { UserLevelResponse } from '../../interfaces/components';
import LoadingSpinner from '../common/LoadingSpinner';
import { NoData } from '../common/NoData';
import Rank from './Rank';
import { XpLeaderBoardDrawer } from '../modals/XpHistoryDrawer';

export default function Rankings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const walletAddress = storage.get('walletAddress');
  const [loading, setLoading] = useState(false);
  const [userLookupLevels, setUserLookupLevels] = useState<UserLevelResponse[] | null>(null);

  const fetchUserLookupLevel = async () => {
    try {
      setLoading(true);
      const res = walletAddress && (await getUserLookupLevel(walletAddress));
      res && setUserLookupLevels(res);
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching current user',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchUserLookupLevel();
  }, []);

  return (
    <>
      <XpLeaderBoardDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Box
        display="flex"
        height="44px"
        padding="12px 24px"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        width="150px"
        borderRadius="15px"
        background="#118BCF"
        cursor="pointer"
        onClick={onOpen}
      >
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily=" Montserrat"
          fontSize=" 16px"
          fontStyle="normal"
          fontWeight=" 600"
          lineHeight="normal"
        >
          View history
        </Text>
      </Box>
      {loading ? (
        <Box height="50px" display="flex" justifyContent="center" alignItems="center" marginBottom="40px">
          <LoadingSpinner />
        </Box>
      ) : userLookupLevels && userLookupLevels?.length === 0 ? (
        <Box marginTop="24px" marginBottom="64px">
          <NoData />
        </Box>
      ) : (
        <>
          <Grid
            templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']}
            gap={'18px'}
            marginTop="24px"
            marginBottom="64px"
          >
            {userLookupLevels &&
              userLookupLevels?.length > 0 &&
              userLookupLevels.map((level, i) => (
                <GridItem key={i} width={['100%']}>
                  <Rank
                    userLevelData={level}
                    isComplete={i < userLookupLevels.findIndex((level) => level.current_user_level)}
                    isAfter={i > userLookupLevels.findIndex((level) => level.current_user_level)}
                  />
                </GridItem>
              ))}
          </Grid>
        </>
      )}
    </>
  );
}
