import { Box, useToast, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AllFavNftsResponse,
  AllNftsResponse,
  AllNftsResponseData,
  FavoriteNftResponse,
} from '../../interfaces/components';
import { getMyFavoriteNfts, getMyNfts } from '../../api/nfts/apis';
import Nft from './Nft';
import Pagination from '../common/Pagination';
import { NoData } from '../common/NoData';
import { TabOptionList } from '../common/TabList';
import NFTFilled from '../../Icons/NFTFilled';
import HeartFilledIcon from '../../Icons/HeartFilledIcon';
import LoadingSpinner from '../common/LoadingSpinner';
import storage from '../../utils/storage';
import { AppStoreState, useAppStore } from '../../store';

export default function MyNfts() {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [myNfts, setMyNfts] = useState<AllNftsResponse>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [tabIndex, setTabIndex] = useState(0);
  const [favNfts, setFavNfts] = useState<AllFavNftsResponse>();
  const [removeFromFav, setRemoveFromFav] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFavPage, setCurrentFavPage] = useState(1);
  const walletLinked = useAppStore((state: AppStoreState) => state.walletLinked);
  const user = useAppStore((state: AppStoreState) => state.user);
  const userFromStorage = storage.get('user');
  const currentUser = JSON.parse(userFromStorage || '{}');

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const fetchMyNfts = async (page: number, recordsPerPage: number, walletAddress: string) => {
    try {
      setLoading(true);
      const res = await getMyNfts(page, recordsPerPage, walletAddress);
      res && setMyNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFTs',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    walletAddress && fetchMyNfts(currentPage, 9, walletAddress);
  }, [currentPage]);

  useEffect(() => {
    walletAddress && fetchMyFavoriteNfts(walletAddress, currentFavPage, 9);
  }, [currentFavPage]);

  const fetchMyFavoriteNfts = async (walletAddress: string, page: number, perPage: number) => {
    try {
      setLoading(true);
      const res = await getMyFavoriteNfts(walletAddress, page, perPage);
      res && setFavNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching favorite NFTs',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      handleMyNftsClick();
    } else {
      handleFavoriteNftsClick();
    }
  }, [tabIndex, removeFromFav, walletAddress]);

  const onNftClick = (nft: AllNftsResponseData | FavoriteNftResponse) => {
    navigate(`/nfts/${nft?.identifier}`);
  };

  useEffect(() => {
    setWalletAddress(currentUser?.wallet_address);
  }, [tabIndex]);

  const handleFavoriteNftsClick = () => {
    setTabIndex(1);
    walletAddress && fetchMyFavoriteNfts(walletAddress, 1, 9);
  };

  const handleMyNftsClick = () => {
    walletAddress && fetchMyNfts(1, 9, walletAddress);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (myNfts?.total_pages && currentPage < myNfts?.total_pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPaginationItemClick = (pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  };

  const handleFavPrevPage = () => {
    if (currentFavPage > 1) {
      setCurrentFavPage(currentFavPage - 1);
    }
  };
  const handleFavNextPage = () => {
    if (favNfts?.total_pages && currentFavPage < favNfts?.total_pages) {
      setCurrentFavPage(currentFavPage + 1);
    }
  };
  const onPaginationItemFavClick = (pageToFetch: number) => {
    setCurrentFavPage(pageToFetch);
  };

  useEffect(() => {
    user?.wallet_address === '' ? setWalletAddress('none') : setWalletAddress(user?.wallet_address);
  }, [user, walletLinked]);

  return (
    <Box margin="0px 0px 64px 0px" display="flex" flexDirection="column" gap="16px">
      <Tabs variant="unstyled" width="100" index={tabIndex} onChange={handleTabsChange}>
        <TabOptionList
          optionOne="MY NFTs"
          optionTwo="Favorites"
          tabIndexValue={tabIndex}
          iconOne={<NFTFilled fill="currentcolor" boxSize={[4, 5, 6]} />}
          iconTwo={<HeartFilledIcon fill="currentcolor" boxSize={[4, 5, 6]} width="24px" height="24px" />}
        />

        <TabPanels mt="20px">
          <TabPanel padding="0px">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
                  columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
                >
                  {myNfts && myNfts?.data?.length > 0 ? (
                    <Nft myNfts={myNfts?.data} onNftClick={onNftClick} tabIndex={tabIndex} />
                  ) : (
                    <NoData />
                  )}
                </Box>
                {myNfts?.data?.length ? (
                  <Pagination
                    handlePreviousPage={handlePrevPage}
                    totalPages={myNfts?.total_pages}
                    currentPage={currentPage}
                    onPaginationitemClick={onPaginationItemClick}
                    handleNextPage={handleNextPage}
                  />
                ) : null}
              </>
            )}
          </TabPanel>
          <TabPanel padding="0px">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
                  columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
                >
                  {favNfts && favNfts?.results.length > 0 ? (
                    <Nft
                      favNfts={favNfts?.results}
                      onNftClick={onNftClick}
                      tabIndex={tabIndex}
                      setRemoveFromFav={setRemoveFromFav}
                      removeFromFav={removeFromFav}
                    />
                  ) : (
                    <NoData />
                  )}
                </Box>
                {favNfts?.results?.length ? (
                  <Pagination
                    handlePreviousPage={handleFavPrevPage}
                    totalPages={favNfts?.total_pages}
                    currentPage={currentFavPage}
                    onPaginationitemClick={onPaginationItemFavClick}
                    handleNextPage={handleFavNextPage}
                  />
                ) : null}
              </Box>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
