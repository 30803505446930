import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function LayerXLogoIcon(props: IconProps) {
  return (
    <Icon width="67px" height="17px" viewBox="0 0 67 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M54.3428 12.9168L59.682 5.55541V7.64852L54.5734 0.5H57.8372L61.3671 5.48445L60.0013 5.50219L63.478 0.5H66.5999L61.5268 7.52435V5.46671L66.9015 12.9168H63.5844L59.9303 7.64852H61.243L57.6421 12.9168H54.3428Z"
        fill="url(#paint0_linear_225_14677)"
      />
      <path
        d="M54.3428 12.9168L59.682 5.55541V7.64852L54.5734 0.5H57.8372L61.3671 5.48445L60.0013 5.50219L63.478 0.5H66.5999L61.5268 7.52435V5.46671L66.9015 12.9168H63.5844L59.9303 7.64852H61.243L57.6421 12.9168H54.3428Z"
        fill="url(#paint1_linear_225_14677)"
      />
      <path
        d="M42.9043 12.9168V3.37363H45.5473V6.06984L45.1748 5.28936C45.4586 4.6153 45.9139 4.10681 46.5406 3.76387C47.1674 3.4091 47.9301 3.23172 48.8289 3.23172V5.78603C48.7106 5.7742 48.6042 5.76829 48.5096 5.76829C48.415 5.75647 48.3145 5.75055 48.208 5.75055C47.4512 5.75055 46.8363 5.96933 46.3633 6.40687C45.9021 6.83259 45.6715 7.50073 45.6715 8.41129V12.9168H42.9043Z"
        fill="white"
      />
      <path
        d="M36.4493 13.0587C35.3614 13.0587 34.4035 12.8459 33.5757 12.4201C32.7597 11.9944 32.1271 11.415 31.6777 10.6818C31.2283 9.93678 31.0037 9.09126 31.0037 8.14522C31.0037 7.18735 31.2224 6.34183 31.66 5.60865C32.1093 4.86364 32.7184 4.28419 33.487 3.8703C34.2557 3.44458 35.1249 3.23172 36.0945 3.23172C37.0288 3.23172 37.8684 3.43275 38.6134 3.83482C39.3702 4.22506 39.9674 4.79269 40.4049 5.53769C40.8425 6.27088 41.0613 7.15188 41.0613 8.1807C41.0613 8.28712 41.0553 8.41129 41.0435 8.5532C41.0317 8.68328 41.0199 8.80745 41.008 8.9257H33.2564V7.31152H39.5535L38.4892 7.79045C38.4892 7.29378 38.3887 6.86215 38.1877 6.49556C37.9866 6.12897 37.7087 5.84516 37.354 5.64412C36.9992 5.43126 36.5853 5.32483 36.1123 5.32483C35.6393 5.32483 35.2195 5.43126 34.8529 5.64412C34.4981 5.84516 34.2202 6.13488 34.0192 6.5133C33.8181 6.87989 33.7176 7.31743 33.7176 7.82593V8.25165C33.7176 8.77197 33.83 9.23317 34.0546 9.63523C34.2912 10.0255 34.6164 10.327 35.0302 10.5399C35.456 10.7409 35.9526 10.8414 36.5203 10.8414C37.0288 10.8414 37.4722 10.7646 37.8506 10.6108C38.2409 10.4571 38.5956 10.2265 38.9149 9.91904L40.3872 11.5155C39.9497 12.0122 39.3998 12.3965 38.7375 12.6685C38.0753 12.9286 37.3126 13.0587 36.4493 13.0587Z"
        fill="white"
      />
      <path
        d="M22.578 16.4999C22.0813 16.4999 21.5905 16.4231 21.1057 16.2693C20.6209 16.1156 20.2247 15.9028 19.9172 15.6308L20.9283 13.6618C21.1412 13.851 21.3836 13.9988 21.6556 14.1053C21.9394 14.2117 22.2173 14.2649 22.4893 14.2649C22.8795 14.2649 23.187 14.1703 23.4117 13.9811C23.6482 13.8037 23.861 13.5022 24.0503 13.0765L24.5469 11.9057L24.7598 11.6042L28.1833 3.37363H30.844L26.5336 13.5022C26.2261 14.2708 25.8714 14.8739 25.4693 15.3115C25.0791 15.749 24.6415 16.0565 24.1567 16.2339C23.6837 16.4113 23.1574 16.4999 22.578 16.4999ZM24.2099 13.2893L19.9527 3.37363H22.8086L26.1079 11.3558L24.2099 13.2893Z"
        fill="white"
      />
      <path
        d="M16.4488 12.9168V11.0543L16.2715 10.6463V7.31152C16.2715 6.72024 16.0882 6.25905 15.7216 5.92794C15.3668 5.59682 14.8169 5.43126 14.0719 5.43126C13.5634 5.43126 13.0608 5.51404 12.5642 5.6796C12.0793 5.83333 11.6654 6.04619 11.3225 6.31818L10.3291 4.38471C10.8495 4.01812 11.4762 3.7343 12.2094 3.53327C12.9426 3.33224 13.6876 3.23172 14.4444 3.23172C15.8989 3.23172 17.0283 3.57466 17.8324 4.26054C18.6366 4.94642 19.0386 6.01663 19.0386 7.47117V12.9168H16.4488ZM13.5398 13.0587C12.7948 13.0587 12.1562 12.9346 11.624 12.6862C11.0919 12.4261 10.6839 12.0772 10.4001 11.6397C10.1163 11.2021 9.97437 10.7114 9.97437 10.1674C9.97437 9.59976 10.1104 9.10309 10.3823 8.67737C10.6662 8.25165 11.1096 7.92053 11.7127 7.68402C12.3158 7.43569 13.1022 7.31152 14.0719 7.31152H16.6085V8.9257H14.3735C13.7231 8.9257 13.2737 9.03213 13.0253 9.24499C12.7888 9.45785 12.6706 9.72392 12.6706 10.0432C12.6706 10.398 12.8066 10.6818 13.0786 10.8946C13.3624 11.0957 13.7467 11.1962 14.2315 11.1962C14.6927 11.1962 15.1066 11.0898 15.4732 10.8769C15.8398 10.6522 16.1059 10.327 16.2715 9.90131L16.6972 11.1785C16.4961 11.7934 16.1295 12.2605 15.5974 12.5798C15.0652 12.8991 14.3794 13.0587 13.5398 13.0587Z"
        fill="white"
      />
      <path d="M0 12.9168V0.5H2.8736V10.5753H9.09973V12.9168H0Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_225_14677"
          x1="63.3622"
          y1="18.5608"
          x2="60.1796"
          y2="-0.395829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B707F5" />
          <stop offset="1" stopColor="#FF993A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_225_14677"
          x1="63.3622"
          y1="18.5608"
          x2="60.1796"
          y2="-0.395829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D3FF" />
          <stop offset="1" stopColor="#3AFF65" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
