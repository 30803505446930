import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import NftListView from '../allNfts/NftListView';

interface CompetingNftsProps {
  nfts: any;
  loading: boolean;
}

export default function CompetingNFTs(props: CompetingNftsProps) {
  const { nfts, loading } = props;

  return (
    <>
      <Box marginTop={['56px', '56px', 'unset']}>
        <Text
          color="#FFF"
          fontFamily="Montserrat"
          fontSize=" 36px"
          fontStyle="normal"
          fontWeight="800"
          lineHeight="normal"
        >
          Competing NFTs
        </Text>
        <Box marginTop="24px">
          <NftListView allNfts={nfts} showButton={true} competingNftsLoading={loading} />
        </Box>
      </Box>
    </>
  );
}
