import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function HeartIcon(props: IconProps) {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_408_5149" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_408_5149)">
        <path
          d="M10.0002 16.9375C9.80576 16.9375 9.60784 16.9028 9.40645 16.8333C9.20506 16.7639 9.02798 16.6528 8.8752 16.5L7.4377 15.1875C5.96548 13.8403 4.63562 12.5035 3.44812 11.1771C2.26062 9.85071 1.66687 8.3889 1.66687 6.79168C1.66687 5.48612 2.10437 4.39584 2.97937 3.52084C3.85437 2.64584 4.94465 2.20834 6.2502 2.20834C6.98631 2.20834 7.68076 2.36459 8.33354 2.67709C8.98631 2.98959 9.54187 3.41668 10.0002 3.95834C10.4585 3.41668 11.0141 2.98959 11.6669 2.67709C12.3196 2.36459 13.0141 2.20834 13.7502 2.20834C15.0558 2.20834 16.146 2.64584 17.021 3.52084C17.896 4.39584 18.3335 5.48612 18.3335 6.79168C18.3335 8.3889 17.7433 9.85418 16.5627 11.1875C15.3821 12.5208 14.0419 13.8611 12.5419 15.2083L11.1252 16.5C10.9724 16.6528 10.7953 16.7639 10.594 16.8333C10.3926 16.9028 10.1946 16.9375 10.0002 16.9375ZM9.20854 5.62501C8.80576 5.05557 8.3752 4.62154 7.91687 4.32293C7.45854 4.02432 6.90298 3.87501 6.2502 3.87501C5.41687 3.87501 4.72243 4.15279 4.16687 4.70834C3.61131 5.2639 3.33354 5.95834 3.33354 6.79168C3.33354 7.5139 3.59048 8.28126 4.10437 9.09376C4.61826 9.90626 5.23284 10.6945 5.94812 11.4583C6.6634 12.2222 7.39951 12.9375 8.15645 13.6042C8.9134 14.2708 9.52798 14.8195 10.0002 15.25C10.4724 14.8195 11.087 14.2708 11.844 13.6042C12.6009 12.9375 13.337 12.2222 14.0523 11.4583C14.7676 10.6945 15.3821 9.90626 15.896 9.09376C16.4099 8.28126 16.6669 7.5139 16.6669 6.79168C16.6669 5.95834 16.3891 5.2639 15.8335 4.70834C15.278 4.15279 14.5835 3.87501 13.7502 3.87501C13.0974 3.87501 12.5419 4.02432 12.0835 4.32293C11.6252 4.62154 11.1946 5.05557 10.7919 5.62501C10.6946 5.7639 10.5766 5.86807 10.4377 5.93751C10.2988 6.00696 10.153 6.04168 10.0002 6.04168C9.84742 6.04168 9.70159 6.00696 9.5627 5.93751C9.42381 5.86807 9.30576 5.7639 9.20854 5.62501Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
