import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function ArrowUpIconBlue(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_573_1334" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" transform="matrix(1 0 0 -1 0 24)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_573_1334)">
        <path
          d="M11.4752 9.52506L7.8502 13.1501C7.8002 13.2001 7.7627 13.2542 7.7377 13.3126C7.7127 13.3709 7.7002 13.4334 7.7002 13.5001C7.7002 13.6334 7.74603 13.7501 7.8377 13.8501C7.92936 13.9501 8.0502 14.0001 8.2002 14.0001H15.8002C15.9502 14.0001 16.071 13.9501 16.1627 13.8501C16.2544 13.7501 16.3002 13.6334 16.3002 13.5001C16.3002 13.4667 16.2502 13.3501 16.1502 13.1501L12.5252 9.52506C12.4419 9.44173 12.3585 9.38339 12.2752 9.35006C12.1919 9.31673 12.1002 9.30006 12.0002 9.30006C11.9002 9.30006 11.8085 9.31673 11.7252 9.35006C11.6419 9.38339 11.5585 9.44173 11.4752 9.52506Z"
          fill="#04D3FF"
        />
      </g>
    </Icon>
  );
}
