import { Box, Text, Image, Divider, Button, useToast, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GroupIcon from '../../Icons/GroupIcon';
import VotesIcon from '../../Icons/Votes';
import Clock from '../../Icons/Clock';
import BellIcon from '../../Icons/Bell';
import { useNavigate } from 'react-router-dom';
import { useAppStore, AppStoreState } from '../../store';
import { getAllCompetitions, subscribeToCompetition, unsubscribeToCompetition } from '../../api/competitions/apis';
import { SubmitNftModal } from '../modals/SubmitNft';
import FeatureCardIcon from '../../Icons/FeatureCard';
import AddToNftSuccessIcon from '../../Icons/AddToNftSuccess';
import AddToNftFailureIcon from '../../Icons/AddToNftFailure';
import Pagination from '../common/Pagination';
import { CompetitionObject } from '../../interfaces/components';
import { isAfter, isBefore, parseISO } from 'date-fns';
import Countdown from '../common/Countdown';
import LoadingSpinner from '../common/LoadingSpinner';
import { NoData } from '../common/NoData';

interface CompetitionProps {
  heading?: string;
  singleCompetition?: boolean;
}

export default function Competitions(props: CompetitionProps) {
  const { heading, singleCompetition } = props;
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const setRerender = useAppStore((state: AppStoreState) => state.setRerender);
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const navigate = useNavigate();
  const competitionList = useAppStore((state: AppStoreState) => state.competitionList);
  const setSelectedCompetitionId = useAppStore((state: AppStoreState) => state.setSelectedCompetitionId);
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const setCompetitionList = useAppStore((state: AppStoreState) => state.setCompetitionList);
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const setMintNowView = useAppStore((state: AppStoreState) => state.setMintNowView);
  const [submitClicked, setSubmitClicked] = useState(false);
  const addNftToCompetitionStatus = useAppStore((state: AppStoreState) => state.addNftToCompetitionStatus);

  const fetchAllCompetitions = async (page: number, recordsPerPage: number) => {
    try {
      setLoading(true);
      const res = await getAllCompetitions(page, recordsPerPage);
      setCompetitionList(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching competition',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (location?.pathname === '/competitions') {
      setSelectedCompetitionId(null);
      fetchAllCompetitions(currentPage, 9);
    } else {
      fetchAllCompetitions(currentPage, 9);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname === '/competitions') {
      setSelectedCompetitionId(null);
      fetchAllCompetitions(currentPage, 9);
    } else {
      fetchAllCompetitions(currentPage, 9);
    }
  }, [currentPage]);

  const onParticipateClick = (competition: CompetitionObject) => {
    setSelectedCompetitionId(competition?.id);
    setSelectedNft(null);
    setMintNowView(false);
    onOpen();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onPaginationitemClick = (pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  };

  const handleNextPage = () => {
    if (competitionList?.total_pages && currentPage < competitionList?.total_pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onCompetitionClick = (competition: CompetitionObject) => {
    setRerender(!reRender);
    setSelectedCompetitionId(competition?.id);
    navigate(`/competitions/${competition?.id}`);
  };

  function updateSubscriptionStatus(array: CompetitionObject[], id: number, status: boolean): CompetitionObject[] {
    return array.map((item) => {
      if (item.id === id) {
        return { ...item, is_subscribed: status }; // Update the object
      }
      return item; // Return the object as is if the id doesn't match
    });
  }

  const onGetNotifiedClick = async (competition: CompetitionObject) => {
    try {
      const res = await subscribeToCompetition(competition?.id);
      const updatedCompetitions = updateSubscriptionStatus(competitionList?.data || [], competition.id, true);
      const updatedCompetitionsList = {
        data: updatedCompetitions,
        page: competitionList?.page || 0,
        records_per_page: competitionList?.records_per_page || 0,
        total_count: competitionList?.total_count || 0,
        total_pages: competitionList?.total_pages || 0,
      };
      setCompetitionList(updatedCompetitionsList);

      toast({
        title: 'Subscribed',
        description: res && res?.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Something went wrong while subscribing to competition',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const unSubscribeCompetition = async (competition: CompetitionObject) => {
    try {
      const res = await unsubscribeToCompetition(competition?.id);

      const updatedCompetitions = updateSubscriptionStatus(competitionList?.data || [], competition.id, false);
      const updatedCompetitionsList = {
        data: updatedCompetitions,
        page: competitionList?.page || 0,
        records_per_page: competitionList?.records_per_page || 0,
        total_count: competitionList?.total_count || 0,
        total_pages: competitionList?.total_pages || 0,
      };
      setCompetitionList(updatedCompetitionsList);

      toast({
        title: 'Subscribed',
        description: res && res?.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Something went wrong while subscribing to competition',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {submitClicked && (
        <SubmitNftModal
          heading={addNftToCompetitionStatus === 'success' ? 'Submitted successfully' : 'Submission failed'}
          subHeading={
            addNftToCompetitionStatus === 'success'
              ? 'Your NFT has been submitted into the competition.'
              : 'Your NFT has been created, but failed to submit, please try to resubmit it later.'
          }
          buttonText={'Close'}
          buttonBgColor={addNftToCompetitionStatus === 'success' ? '#118BCF' : '#E13838'}
          isModalOpen={submitClicked}
          onModalOpen={onOpen}
          onModalClose={onClose}
          setSubmitClicked={setSubmitClicked}
          modalIcon={addNftToCompetitionStatus === 'success' ? <AddToNftSuccessIcon /> : <AddToNftFailureIcon />}
        />
      )}
      <SubmitNftModal
        isModalOpen={isOpen}
        onModalOpen={onOpen}
        onModalClose={onClose}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
        heading={'Submit your NFT'}
        subHeading={'Browse your NFTs or mint a new one to compete in this competition.'}
        buttonText={'Browse NFTs'}
        buttonBgColor={'#222'}
        modalIcon={<FeatureCardIcon />}
      />
      <Box paddingTop="12px 0px 64px 0px" marginTop="60px" marginBottom="56px">
        <Text color=" #FFF" fontSize={['28px', '28px', '36px']} fontWeight="800" marginBottom="24px">
          {heading}
        </Text>
        {loading ? (
          <LoadingSpinner />
        ) : competitionList && competitionList?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <Box
              display="flex"
              flexWrap="wrap"
              // justifyContent={['space-between', 'space-between', 'space-between', 'space-between', 'space-between']}
              rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
              columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
            >
              {competitionList &&
                competitionList?.data?.length > 0 &&
                competitionList?.data
                  ?.filter((comp) => (singleCompetition ? comp?.id !== selectedCompetitionId : true))
                  .map((competition, i) => (
                    <Box
                      key={i}
                      borderRadius="26px"
                      border="var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10)"
                      width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                      position="relative"
                      padding="16px"
                      cursor="pointer"
                      backgroundColor="rgba(255, 255, 255, 0.10)"
                      onClick={() => {
                        onCompetitionClick(competition);
                        // event.stopPropagation();
                      }}
                    >
                      <Image
                        width={['inherit', 'inherit', '100%', '100%', '100%']}
                        cursor="pointer"
                        src={competition?.competition_image}
                        maxHeight="214px"
                        height="214px"
                        objectFit="cover"
                        borderRadius="12px"
                      />
                      <Text
                        color="#FFF"
                        fontFamily=" Montserrat"
                        fontStyle=" normal"
                        fontWeight="700"
                        lineHeight="normal"
                        fontSize="18px"
                        marginTop="18px"
                        marginBottom="24px"
                      >
                        {competition?.competition_name}
                      </Text>
                      <Box
                        padding="15px 10px"
                        display="flex"
                        borderRadius="15px"
                        background="rgba(255, 255, 255, 0.10)"
                      >
                        <Box
                          width="50%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                            <GroupIcon />
                            <Text
                              color="#96BCAD"
                              textAlign="center"
                              fontFamily="Montserrat"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              Entries
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              color=" #FFF"
                              textAlign="center"
                              fontFamily=" Montserrat"
                              fontSize="20px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              {competition?.total_entries}
                            </Text>
                          </Box>
                        </Box>
                        <Divider
                          border="1px solid rgba(255, 255, 255, 0.10) !important"
                          orientation="vertical"
                          height="56px"
                        />
                        <Box
                          width="50%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                            <VotesIcon color="#96BCAD" />
                            <Text
                              color="#96BCAD"
                              textAlign="center"
                              fontFamily="Montserrat"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              Total Votes
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              color=" #FFF"
                              textAlign="center"
                              fontFamily=" Montserrat"
                              fontSize="20px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                            >
                              {competition?.total_votes ? competition?.total_votes : 0}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box marginTop="24px" marginBottom="24px" display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center" gap="8px">
                          <Clock color={'#0FBF95'} />
                          <Text
                            color=" #96BCAD"
                            textAlign="center"
                            fontFamily=" Montserrat"
                            fontSize=" 16px"
                            fontStyle=" normal"
                            fontWeight=" 600"
                            lineHeight="normal"
                          >
                            {isAfter(parseISO(competition?.participation_starts), new Date())
                              ? 'Opens in ' // Announced Stage (before participation starts)
                              : isAfter(new Date(), parseISO(competition?.participation_starts)) &&
                                isBefore(new Date(), parseISO(competition?.voting_starts))
                              ? 'Entries close in' // Participation Phase (after participation starts and before voting starts)
                              : isAfter(new Date(), parseISO(competition?.voting_starts)) &&
                                isBefore(new Date(), parseISO(competition?.voting_ends))
                              ? 'Voting ends in' // Voting Phase (after voting starts and before voting ends)
                              : isAfter(new Date(), parseISO(competition?.voting_ends))
                              ? 'Closed' // Competition Closed Stage (after voting ends)
                              : ''}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            color="#fff"
                            textAlign="center"
                            fontFamily="Montserrat"
                            fontSize="20px"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="normal"
                          >
                            {isAfter(parseISO(competition?.participation_starts), new Date()) ? (
                              <Countdown endDateString={competition?.participation_starts} />
                            ) : isAfter(new Date(), parseISO(competition?.participation_starts)) &&
                              isBefore(new Date(), parseISO(competition?.voting_starts)) ? (
                              <Countdown endDateString={competition?.voting_starts} />
                            ) : isAfter(new Date(), parseISO(competition?.voting_starts)) &&
                              isBefore(new Date(), parseISO(competition?.voting_ends)) ? (
                              <Countdown endDateString={competition?.voting_ends} />
                            ) : (
                              isAfter(new Date(), parseISO(competition?.voting_ends)) && ''
                            )}
                          </Text>
                        </Box>
                      </Box>

                      {isAfter(parseISO(competition?.participation_starts), new Date()) ? (
                        <Box display="flex" flexDirection="row" gap="12px">
                          <Button
                            width="50%"
                            borderRadius="15px"
                            border="0.5px solid rgba(255, 255, 255, 0.20)"
                            padding="12px 24px"
                            color="#FFF"
                            backgroundColor="#222"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: 'rgba(255, 255, 255, 0.20)',
                            }}
                            onClick={() => onCompetitionClick(competition)}
                          >
                            View
                          </Button>
                          <Button
                            width="50%"
                            borderRadius="15px"
                            padding="12px 24px"
                            color="#FFF"
                            leftIcon={<BellIcon />}
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            onClick={(event) => {
                              competition.is_subscribed
                                ? unSubscribeCompetition(competition)
                                : onGetNotifiedClick(competition);
                              event.stopPropagation();
                            }}
                          >
                            {competition.is_subscribed ? 'Unsubscribe' : ' Get notified'}
                          </Button>
                        </Box>
                      ) : isAfter(new Date(), parseISO(competition?.participation_starts)) &&
                        isBefore(new Date(), parseISO(competition?.voting_starts)) ? (
                        <Box display="flex" flexDirection="row" gap="12px">
                          <Button
                            width="50%"
                            borderRadius="15px"
                            border="0.5px solid rgba(255, 255, 255, 0.20)"
                            padding="12px 24px"
                            color="#FFF"
                            backgroundColor="#222"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: 'rgba(255, 255, 255, 0.20)',
                            }}
                            onClick={() => onParticipateClick(competition)}
                          >
                            Participate
                          </Button>
                          <Button
                            width="50%"
                            borderRadius="15px"
                            padding="12px 24px"
                            color=" #FFF"
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '20px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            onClick={() => onCompetitionClick(competition)}
                          >
                            Open
                          </Button>
                        </Box>
                      ) : isAfter(new Date(), parseISO(competition?.voting_starts)) &&
                        isBefore(new Date(), parseISO(competition?.voting_ends)) ? (
                        <Box>
                          <Button
                            width="100%"
                            borderRadius="15px"
                            padding="12px 24px"
                            color=" #FFF"
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '16px']}
                            fontWeight="600"
                            height="48px"
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            onClick={() => onCompetitionClick(competition)}
                          >
                            Open & Vote
                          </Button>
                        </Box>
                      ) : (
                        isAfter(new Date(), parseISO(competition?.voting_ends)) && (
                          <Box>
                            <Button
                              width="100%"
                              borderRadius="15px"
                              padding="12px 24px"
                              color=" #FFF"
                              backgroundColor="#118BCF"
                              fontSize={['16px', '16px', '16px']}
                              fontWeight="600"
                              height="48px"
                              _hover={{
                                color: 'white',
                                bg: '#43BDD7',
                              }}
                              onClick={() => onCompetitionClick(competition)}
                            >
                              View
                            </Button>
                          </Box>
                        )
                      )}
                    </Box>
                  ))}
            </Box>
            {competitionList?.total_pages && (
              <Pagination
                handlePreviousPage={handlePrevPage}
                totalPages={competitionList?.total_pages}
                currentPage={currentPage}
                onPaginationitemClick={onPaginationitemClick}
                handleNextPage={handleNextPage}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}
