import {
  Box,
  Button,
  Divider,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ProfilePicture from '../../assets/profilePicture.png';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { AppMenuItem, NavbarProps, NotificationResponse, NotificationResult } from '../../interfaces/components';
import { useAccount } from 'wagmi';
import { useUser } from '../../hooks';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import ArrowUpIconBlue from '../../Icons/ArrowUpIcon';
import LogoutLogo from '../../Icons/LogoutLogo';
import storage from '../../utils/storage';
import { formatAddress, formatStatus } from '../../utils/commonFunctions';
import PowerSettingIcon from '../../Icons/PoweSettingIcon';
import ArrowDownIconGrey from '../../Icons/ArrowDownIcon';
import MobileMenuIcon from '../../Icons/MobileMenuIcon';
import EclipseIcon from '../../Icons/Eclipse';
import { getAllNotifications, markNotificationAsRead } from '../../api/competitions/apis';
import BellFilledIcon from '../../Icons/BellFilled';
import { XpLeaderBoardDrawer } from '../modals/XpHistoryDrawer';

export const menuItems: AppMenuItem[] = [
  { text: 'Home', path: '/', id: 1 },
  { text: 'NFTs', path: '/nfts', id: 2 },
  { text: 'Competitions', path: '/competitions', id: 3 },
  { text: 'My profile', path: '/my-profile', id: 4 },
];

export default function Header({ openDrawer }: NavbarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onMenuItemClick = (menuItem: AppMenuItem) => {
    navigate(menuItem?.path);
  };
  const { address } = useAccount();
  const { logout } = useUser();
  const navigate = useNavigate();
  const walletAddress = storage.get('walletAddress');
  const userName = storage.get('userName');
  const [allNotifications, setAllNotifications] = useState<NotificationResponse | null>(null);
  const { open } = useWeb3Modal();
  const user = storage.get('user');
  const currentUser = JSON.parse(user || '{}');

  const handleLogin = async () => {
    open({ view: 'Networks' }).then((res) => {
      console.log('handle login res network', res);
    });
  };

  const getNotifications = async () => {
    try {
      const res = await getAllNotifications(1, 10);
      setAllNotifications(res);
    } catch (error: any) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 60000); // 2 minutes = 120000 milliseconds

    getNotifications();

    return () => clearInterval(interval);
  }, []);

  const onLogoutClick = async () => {
    await logout();
  };

  const onConnectButtonClick = async () => {
    if (!address) {
      handleLogin();
    }
  };

  const isActiveMenuItem = (menuPath: string, currentPath: string) => {
    if (menuPath === '/') {
      return currentPath === '/';
    }
    return currentPath.startsWith(menuPath);
  };

  const setAllUnreadNotificationsAsRead = async () => {
    try {
      // Filter out notifications that are not read
      const unreadNotifications = allNotifications?.results?.filter(
        (notification: NotificationResult) => !notification.read,
      );

      // Extract the IDs of unread notifications
      const unreadNotificationIds = unreadNotifications?.map((notification: NotificationResult) => notification.id);

      // If there are any unread notifications, call markNotificationAsRead for each
      if (unreadNotificationIds && unreadNotificationIds?.length > 0) {
        const promises = unreadNotificationIds.map((id) => markNotificationAsRead(id));
        const responses = await Promise.all(promises);
        console.log('Unread notifications marked as read:', responses);

        const updatedNotifications = allNotifications?.results.map((notification: NotificationResult) => {
          if (unreadNotificationIds.includes(notification.id)) {
            return { ...notification, read: true };
          }
          return notification;
        });

        const updatedAllNotifications = {
          count: allNotifications?.count || 0,
          current_page: allNotifications?.count || 0,
          total_pages: allNotifications?.count || 0,
          results: updatedNotifications || allNotifications?.results || [],
        };

        setTimeout(() => {
          setAllNotifications(updatedAllNotifications);
        }, 1000);
      } else {
        console.log('All notifications are already read.');
      }
    } catch (error: any) {
      console.log('Error marking notifications as read:', error);
    }
  };

  // const allNotificationsRead = allNotifications?.results?.every(
  //   (notification: NotificationResult) => notification.read,
  // );

  const isAnyNotificationUnread = allNotifications?.results?.some(
    (notification: NotificationResult) => !notification.read,
  );

  const unreadCount =
    allNotifications?.results?.reduce(
      (count: number, notification: NotificationResult) => count + (notification.read ? 0 : 1),
      0,
    ) || 0;

  const onNotificationClick = (notification: NotificationResult) => {
    navigate(`/competitions/${notification?.competition?.id}`);
  };

  return (
    <>
      <XpLeaderBoardDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Box width="100%" h={['65px', '65px', '65px', '65px', '65px']} borderBottom="1px solid rgba(255, 255, 255, 0.10)">
        <Box
          maxWidth={['unset', 'unset', '1400px']}
          margin="0 auto"
          width="100%"
          h={['65px', '65px', '65px', '65px', '65px']}
          marginBottom="30px"
          position="relative"
        >
          <Box
            margin="0 auto"
            width="inherit"
            height="inherit"
            padding={['14px 12px', '14px 12px', '14px 12px', '20px 24px']}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              cursor="pointer"
              onClick={() => {
                navigate('/');
              }}
            >
              <Image src={Logo} height={['24px', '24px', '24px', '24x', '24px']} />
            </Box>

            <Box
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              display={['none', 'none', 'flex']}
              gap="24px"
            >
              {menuItems.map((menu, i) => (
                <Box key={i} onClick={() => onMenuItemClick(menu)}>
                  <Text
                    color={isActiveMenuItem(menu.path, location.pathname) ? '#FFF' : 'rgba(255, 255, 255, 0.50)'}
                    fontSize="14px"
                    fontWeight="600"
                    cursor="pointer"
                  >
                    {menu?.text}
                  </Text>
                </Box>
              ))}
            </Box>

            <Box display="flex" gap={['14px', '14px', '14px', '16px']} alignItems="center">
              <Popover placement="bottom-end" onOpen={() => setAllUnreadNotificationsAsRead()}>
                <PopoverTrigger>
                  <Box
                    height="44px"
                    paddingTop="10px"
                    paddingBottom="10px"
                    borderRadius="24px"
                    backgroundColor="#1F1F1F"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding={unreadCount > 0 ? '10px 10px 10px 15px' : '10px'}
                    gap="4px"
                    cursor="pointer"
                    transition="0.2s ease-in all"
                  >
                    {unreadCount > 0 && (
                      <Text color="white" fontSize="16px" fontWeight="600">
                        {unreadCount}
                      </Text>
                    )}
                    <Button
                      paddingStart="unset !important"
                      paddingEnd="unset !important"
                      height="unset !important"
                      width="unset !important"
                      minWidth="unset !important"
                      backgroundColor="unset !important"
                    >
                      <BellFilledIcon fill={isAnyNotificationUnread ? '#FF3465' : '#8F8F8F'} boxSize={[6]} />{' '}
                    </Button>
                  </Box>
                </PopoverTrigger>
                <PopoverContent
                  width={['100%', 'auto', 'auto', 'auto']}
                  minWidth={['100%', '350px', '350px', '370px']}
                  borderRadius="26px"
                  border="1px solid"
                  borderColor="rgba(255, 255, 255, 0.10);"
                  background="#242424"
                  boxShadow="0px 10px 24px 0px rgba(0, 0, 0, 0.35)"
                  padding="16px"
                  right={['-10px', '-10px', 'unset']}
                >
                  <PopoverHeader
                    border="none"
                    color="rgba(255, 255, 255, 0.50)"
                    fontSize="16px"
                    fontWeight="600"
                    padding="0px !important"
                  >
                    Notifications
                  </PopoverHeader>
                  <PopoverBody
                    maxHeight={['165px']}
                    overflow="auto"
                    css={{
                      '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar in WebKit browsers
                      },
                      '&': {
                        msOverflowStyle: 'none', // Hide scrollbar in Internet Explorer and Edge
                        scrollbarWidth: 'none', // Hide scrollbar in Firefox
                      },
                      overflowX: 'hidden', // Prevent horizontal scrolling
                    }}
                    transition="0.2s ease-in all"
                    padding="0px !important"
                  >
                    <>
                      {allNotifications && allNotifications?.count > 0 ? (
                        allNotifications?.results.map((notification: NotificationResult, i) => (
                          <Box key={i}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              key={notification.id}
                              userSelect="none"
                              cursor="pointer"
                              onClick={() => onNotificationClick(notification)}
                            >
                              <Box display="flex" gap="6px" alignItems="center" padding="14px 0px">
                                <EclipseIcon
                                  boxSize={[2]}
                                  fill={notification.read ? '#5F5F5F' : '#FF3465'}
                                  transition="0.2s ease-in all"
                                />
                                <Box display="flex" alignItems="center" justifyContent="center" gap="4px">
                                  <Text
                                    color={notification.read ? '#5F5F5F' : '#FFF'}
                                    fontSize="14px"
                                    fontWeight="500"
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    transition="0.2s ease-in all"
                                    textOverflow="ellipsis"
                                  >
                                    {notification.competition?.title}
                                  </Text>
                                  <Box>
                                    <Text
                                      transition="0.2s ease-in all"
                                      color={notification.read ? '#5F5F5F' : '#FFF'}
                                      fontSize="14px"
                                      fontWeight="500"
                                    >
                                      -
                                    </Text>
                                  </Box>

                                  <Text
                                    transition="0.2s ease-in all"
                                    color={notification.read ? '#5F5F5F' : '#FFF'}
                                    fontSize="14px"
                                    fontWeight="500"
                                  >
                                    {formatStatus(notification.type)}
                                  </Text>
                                </Box>
                              </Box>
                              {i !== allNotifications?.results?.length - 1 && (
                                <Divider orientation="horizontal" opacity="0.1" borderColor="rgba(255, 255, 255)" />
                              )}
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box
                          display="flex"
                          minHeight="89px"
                          padding="24px"
                          justifyContent="center"
                          alignItems="center"
                          gap="24px"
                          marginTop={'14px'}
                          alignSelf="stretch"
                          borderRadius="13px"
                          border="2px dashed rgba(255, 255, 255, 0.08)"
                          background="rgba(255, 255, 255, 0.01)"
                        >
                          <Text color="#5F5F5F" fontSize="14px" fontWeight="500">
                            No notifications here...
                          </Text>
                        </Box>
                      )}
                      <Box
                        borderRadius="15px"
                        border="0.5px solid rgba(255, 255, 255, 0.20)"
                        background="#222"
                        padding="16px 32px"
                        height="52px"
                        display="flex"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={onOpen}
                      >
                        <Text color=" #FFF" fontSize="16px" fontWeight="600" lineHeight="normal">
                          View XP Earnings
                        </Text>
                      </Box>
                    </>
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              <Box
                display={['none', 'none', 'none', 'flex']}
                height="44px"
                paddingTop="10px"
                paddingBottom="10px"
                borderRadius="24px"
                backgroundColor="#1F1F1F"
                alignItems="center"
                justifyContent="center"
              >
                <Box paddingLeft="10px" marginRight="6px">
                  <Box display="flex" justifyContent="center" alignItems="center" gap="36px">
                    {currentUser?.profile_picture ? (
                      <Box height="24px" width="25px">
                        <Image
                          src={currentUser?.profile_picture}
                          objectFit="cover"
                          borderRadius="50%"
                          height="inherit"
                          width="inherit"
                        />
                      </Box>
                    ) : (
                      <Box height="24px" width="25px">
                        <Image
                          src={ProfilePicture}
                          objectFit="cover"
                          height="inherit"
                          width="inherit"
                          borderRadius="50%"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                {userName && (
                  <Text
                    color="rgba(255, 255, 255, 0.50)"
                    fontSize="15px"
                    fontWeight="500"
                    textTransform="capitalize"
                    marginRight="10px"
                    flexGrow={1}
                    noOfLines={1}
                  >
                    {userName}
                  </Text>
                )}
                <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="vertical" />
                <Menu placement="bottom-end">
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        display="flex"
                        width="unset"
                        // minWidth="unset"
                        minWidth="10px"
                        padding="0"
                        margin="0"
                        backgroundColor="transparent"
                        height="unset"
                        justifyContent="center"
                        as={Button}
                        rightIcon={isOpen ? <ArrowUpIconBlue /> : <ArrowDownIconGrey />}
                        _hover={{
                          color: 'white',
                          bg: 'transparent',
                        }}
                        _active={{
                          color: 'white',
                          bg: 'transparent',
                        }}
                        flexShrink={0}
                        paddingRight="10px" // Prevents shrinking in flex layouts
                      />
                      <MenuList
                        marginTop="20px"
                        padding="1px"
                        borderRadius="24px"
                        backgroundColor="#1F1F1F"
                        boxShadow="-5px 14px 34px 0px rgba(0, 0, 0, 0.35)"
                        borderWidth="none !important"
                        zIndex={2}
                        style={{
                          borderRadius: '24px',
                          backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
                          backgroundOrigin: 'border-box',
                          backgroundClip: 'content-box, border-box',
                        }}
                      >
                        <Box padding="14px" borderRadius=" 24px">
                          {address ? (
                            <Box
                              padding="9px"
                              borderRadius="14px"
                              background="#000"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text color="rgba(255, 255, 255, 0.50)" fontSize="16px" fontWeight="500">
                                {address && formatAddress(address, 5, 4)}
                              </Text>
                              <PowerSettingIcon />
                            </Box>
                          ) : (
                            <Box
                              display={walletAddress ? 'block' : 'none'}
                              padding="9px"
                              borderRadius="14px"
                              background="#000"
                            >
                              <Text color=" rgba(255, 255, 255, 0.50)" fontSize=" 12px" fontWeight="500">
                                Linked wallet
                              </Text>
                              <Text color="#fff" fontSize="16px" fontWeight="600">
                                {walletAddress && formatAddress(walletAddress, 5, 4)}
                              </Text>
                            </Box>
                          )}
                          {!address && (
                            <Box marginTop="8px" marginBottom="24px">
                              <Button
                                width="100%"
                                borderRadius="15px"
                                backgroundColor="#118BCF"
                                padding="16px 32px"
                                color=" #FFF"
                                fontSize="16px"
                                fontWeight=" 600"
                                h="44px"
                                _hover={{
                                  color: 'white',
                                  bg: '#43BDD7',
                                }}
                                onClick={onConnectButtonClick}
                              >
                                Connect Wallet
                              </Button>
                            </Box>
                          )}
                          <Divider
                            border="1px solid rgba(255, 255, 255, 0.10) !important"
                            marginTop="8px"
                            marginBottom="10px"
                          />
                          <Box>
                            <Button
                              padding="16px 32px"
                              color="rgba(255, 255, 255, 0.50)"
                              fontSize="15px"
                              fontWeight="500"
                              h="44px"
                              zIndex={99}
                              _hover={{
                                color: 'white',
                                bg: 'rgba(255, 255, 255, 0.50)',
                              }}
                              onClick={onLogoutClick}
                              width="100%"
                              leftIcon={<LogoutLogo />}
                              borderRadius="15px"
                              border="0.5px solid rgba(255, 255, 255, 0.10)"
                              backgroundColor="rgba(255, 255, 255, 0.05)"
                              cursor="pointer"
                            >
                              Logout
                            </Button>
                          </Box>
                        </Box>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>

              <Box onClick={openDrawer} display={['unset', 'unset', 'unset', 'none', 'none']}>
                <MobileMenuIcon
                  // boxSize={8}
                  cursor="pointer"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
