import { Box, Image, Button, Text } from '@chakra-ui/react';
import stackedCards from '../../assets/competitionsStacked.png';
import greenBlueBgGradient from '../../assets/greenBlueBgGradient.png';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NftCollectionSection() {
  const navigate = useNavigate();
  return (
    <Box
      // border="1xp solid blue"
      marginTop={['56px', '64px', '64px']}
      display="flex"
      flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
      gap={['0px', '0px', '64px']}
      bgImage={[greenBlueBgGradient, greenBlueBgGradient, 'none', 'none', 'none']}
    >
      <Box height={['unset', 'unset', 'unset', '410px']} width={['100%', '100%', '50%']}>
        <Box marginTop={['56px', '56px', '80px']}>
          <Text color=" #FFF" fontFamily="Montserrat" fontSize="36px" fontWeight=" 800" lineHeight=" normal">
            NFT Competitions
          </Text>

          <Text
            color="#FFFFFF80"
            fontFamily=" Montserrat"
            fontSize="19px"
            fontWeight="500"
            lineHeight="normal"
            marginTop="24px"
          >
            Show off your wit & creativity with NFT Competitions. Compete & Mint Winning NFTs on Layer X.
          </Text>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row', 'row']}
            gap={'10px'}
            marginTop="44px"
          >
            <Button
              width={['auto', 'auto', 'auto', '267px', '267px']}
              borderRadius="15px"
              borderTop="1px solid #04D3FF"
              padding="16px 32px"
              color=" #FFF"
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              h={'56px'}
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => navigate('/competitions')}
            >
              View Competitions
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
      //
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={['-webkit-fill-available', '-webkit-fill-available;', 'unset', '533px']}
        >
          <Image src={stackedCards} width={['-webkit-fill-available;', '-webkit-fill-available;', 'unset', '533px']} />
        </Box>
      </Box>
    </Box>
  );
}
