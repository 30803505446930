import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import storage from '../../utils/storage';
import { useUser } from '../../hooks';
import LoadingSpinner from '../common/LoadingSpinner';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setAccessToken } = useUser();

  useEffect(() => {
    async function handleCallback() {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      const error = queryParams.get('error');

      if (error) {
        console.error('Error during authorization:', error);
        return;
      }

      if (code) {
        try {
          const code_verifier = sessionStorage.getItem('code_verifier');
          if (!code_verifier) throw new Error('Code verifier is missing');

          const apiUrl = process.env.REACT_APP_LAYERX_API_URL || 'https://api.layerx.baboons.tech';
          const response = await axios.post(`${apiUrl}/auth/callback/`, {
            code,
            code_verifier,
          });

          const response_data = response.data;

          if (!response_data.data.access_token) {
            throw new Error('No access token received from authorization server');
          } else {
            setAccessToken(response_data.data.access_token);
            storage.set('accessToken', response_data.data.access_token);
            storage.set('refreshAccessToken', response_data.data.refresh_token);
            navigate('/');
          }
        } catch (error) {
          console.error('Failed to exchange code for token:', error);
        }
      }
    }

    handleCallback();
  }, [navigate]);

  return <LoadingSpinner />;
};

export default AuthCallback;
