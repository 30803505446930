import { OtherUserCompetitions, OtherUserNfts } from '../../interfaces/components';
import axios from '../axios';

export const otherUserData = async (address: string) => {
  try {
    const endPoint = `/api/user/public/?wallet_address=${address}`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const otherUserCompetitions = async (address: string, page: number, recordsPerPage: number) => {
  try {
    const endPoint = `/api/nfts/competitions/?wallet_address=${address}&page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get<OtherUserCompetitions>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const otherUserNfts = async (address: string, page: number, recordsPerPage: number) => {
  try {
    const endPoint = `/api/nfts/wallet/${address}?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get<OtherUserNfts>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
