import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function CommentIcon(props: IconProps) {
  return (
    <Icon width="18px" height="18px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.45839 11.9772C1.1945 11.4078 0.996582 10.8244 0.864638 10.2272C0.732693 9.62999 0.666721 9.01888 0.666721 8.39388C0.666721 7.2411 0.885471 6.15777 1.32297 5.14388C1.76047 4.12999 2.35422 3.24805 3.10422 2.49805C3.85422 1.74805 4.73617 1.1543 5.75005 0.716797C6.76394 0.279297 7.84728 0.0605469 9.00005 0.0605469C10.1528 0.0605469 11.2362 0.279297 12.2501 0.716797C13.2639 1.1543 14.1459 1.74805 14.8959 2.49805C15.6459 3.24805 16.2396 4.12999 16.6771 5.14388C17.1146 6.15777 17.3334 7.2411 17.3334 8.39388C17.3334 9.54666 17.1146 10.63 16.6771 11.6439C16.2396 12.6578 15.6459 13.5397 14.8959 14.2897C14.1459 15.0397 13.2639 15.6335 12.2501 16.071C11.2362 16.5085 10.1528 16.7272 9.00005 16.7272C8.37505 16.7272 7.76394 16.6612 7.16672 16.5293C6.5695 16.3974 5.98617 16.1994 5.41672 15.9355L1.29172 17.1439C0.972277 17.2411 0.694499 17.1717 0.458388 16.9355C0.222276 16.6994 0.152832 16.4217 0.250054 16.1022L1.45839 11.9772ZM2.29172 15.1022L4.95839 14.3105C5.15283 14.255 5.35075 14.2342 5.55214 14.248C5.75353 14.2619 5.9445 14.3105 6.12505 14.3939C6.5695 14.6161 7.03478 14.7828 7.52089 14.8939C8.007 15.005 8.50005 15.0605 9.00005 15.0605C10.8612 15.0605 12.4376 14.4147 13.7292 13.123C15.0209 11.8314 15.6667 10.255 15.6667 8.39388C15.6667 6.53277 15.0209 4.95638 13.7292 3.66471C12.4376 2.37305 10.8612 1.72721 9.00005 1.72721C7.13894 1.72721 5.56255 2.37305 4.27089 3.66471C2.97922 4.95638 2.33339 6.53277 2.33339 8.39388C2.33339 8.89388 2.38894 9.38694 2.50005 9.87305C2.61117 10.3592 2.77783 10.8244 3.00005 11.2689C3.09728 11.4494 3.14936 11.6404 3.1563 11.8418C3.16325 12.0432 3.13894 12.2411 3.08339 12.4355L2.29172 15.1022Z"
        fill="white"
      />
    </Icon>
  );
}
