import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function CardIcon3(props: IconProps) {
  return (
    <Icon width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_302_85" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="34">
        <rect x="0.333374" width="34.0021" height="34" fill="url(#paint0_linear_302_85)" />
      </mask>
      <g mask="url(#mask0_302_85)">
        <path
          d="M15.2807 25.7835L22.6124 17.0002H16.9454L17.9726 8.9606L11.4201 18.4169H16.3433L15.2807 25.7835ZM13.0848 21.2502H8.69283C8.12613 21.2502 7.70701 20.9964 7.43546 20.4887C7.16392 19.9811 7.19343 19.4912 7.52401 19.0189L18.1142 3.78977C18.3504 3.45921 18.6573 3.229 19.0351 3.09914C19.4129 2.96928 19.8025 2.97518 20.204 3.11685C20.6054 3.25852 20.9005 3.50643 21.0894 3.8606C21.2783 4.21477 21.3492 4.59255 21.3019 4.99393L20.1685 14.1669H25.6585C26.2724 14.1669 26.7033 14.4384 26.9512 14.9814C27.1992 15.5245 27.1224 16.0321 26.721 16.5044L15.0682 30.4585C14.8085 30.7655 14.4897 30.9662 14.1119 31.0606C13.7341 31.155 13.3681 31.1196 13.0139 30.9544C12.6597 30.7891 12.3823 30.5353 12.1816 30.1929C11.9809 29.8505 11.9041 29.4787 11.9514 29.0773L13.0848 21.2502Z"
          fill="#04D3FF"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_302_85"
          x1="24.7531"
          y1="49.4545"
          x2="15.9448"
          y2="-2.42047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B707F5" />
          <stop offset="1" stopColor="#FF993A" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
