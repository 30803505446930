import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export default function LogoutLogo(props: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
      <mask id="mask0_577_1347" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_577_1347)">
        <path
          d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H9.16667C9.40278 2.5 9.60069 2.57986 9.76042 2.73958C9.92014 2.89931 10 3.09722 10 3.33333C10 3.56944 9.92014 3.76736 9.76042 3.92708C9.60069 4.08681 9.40278 4.16667 9.16667 4.16667H4.16667V15.8333H9.16667C9.40278 15.8333 9.60069 15.9132 9.76042 16.0729C9.92014 16.2326 10 16.4306 10 16.6667C10 16.9028 9.92014 17.1007 9.76042 17.2604C9.60069 17.4201 9.40278 17.5 9.16667 17.5H4.16667ZM14.3125 10.8333H8.33333C8.09722 10.8333 7.89931 10.7535 7.73958 10.5938C7.57986 10.434 7.5 10.2361 7.5 10C7.5 9.76389 7.57986 9.56597 7.73958 9.40625C7.89931 9.24653 8.09722 9.16667 8.33333 9.16667H14.3125L12.75 7.60417C12.5972 7.45139 12.5208 7.26389 12.5208 7.04167C12.5208 6.81944 12.5972 6.625 12.75 6.45833C12.9028 6.29167 13.0972 6.20486 13.3333 6.19792C13.5694 6.19097 13.7708 6.27083 13.9375 6.4375L16.9167 9.41667C17.0833 9.58333 17.1667 9.77778 17.1667 10C17.1667 10.2222 17.0833 10.4167 16.9167 10.5833L13.9375 13.5625C13.7708 13.7292 13.5729 13.809 13.3438 13.8021C13.1146 13.7951 12.9167 13.7083 12.75 13.5417C12.5972 13.375 12.5243 13.1771 12.5312 12.9479C12.5382 12.7188 12.6181 12.5278 12.7708 12.375L14.3125 10.8333Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
    </Icon>
  );
}
