import { Box } from '@chakra-ui/react';
import React, { useRef } from 'react';
import Work from './Work';
import HomeHero from './HomeHero';
import FeaturedNft from './FeaturedNft';

export default function HomeMain() {
  const featuredNftRef = useRef<HTMLDivElement>(null);

  const scrollToFeaturedNft = () => {
    if (featuredNftRef.current) {
      window.scrollBy({ top: 1150, behavior: 'smooth' });
    }
  };

  return (
    <Box
      display="flex"
      zIndex={2}
      flexDirection="column"
      maxHeight="100%"
      overflow="auto"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar for WebKit browsers
        },
        '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
        'scrollbar-width': 'none', // Hide scrollbar for Firefox
      }}
      marginBottom="30px"
    >
      <HomeHero onExploreButtonClick={scrollToFeaturedNft} />
      <Work />
      <Box border="1px solid transparent">
        <div ref={featuredNftRef}>
          <FeaturedNft />
        </div>
      </Box>
    </Box>
  );
}
