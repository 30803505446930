import { GetUser, twitterSave, userLinkWallet } from '../api/userApiCalls/user';
import { AppStoreState, useAppStore } from '../store';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import storage from '../utils/storage';
import { challengeApi } from '../api/api';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { User } from '../interfaces/components';
import { useNavigate } from 'react-router-dom';

const useUser = () => {
  const navigate = useNavigate();
  const { signMessageAsync } = useSignMessage();
  const [user, setUser] = useState<User | null>(null);
  const setUserInStore = useAppStore((state: AppStoreState) => state.setUser);
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const setAccessToken = useAppStore((state: AppStoreState) => state.setAccessToken);
  const setSignature = useAppStore((state: AppStoreState) => state.setSignature);
  const walletLinked = useAppStore((state: AppStoreState) => state.walletLinked);
  const setWalletLinked = useAppStore((state: AppStoreState) => state.setWalletLinked);
  const signatureSet = useAppStore((state: AppStoreState) => state.signatureSet);
  const setWalletAddress = useAppStore((state: AppStoreState) => state.setWalletAddress);
  const [isWalletLinked, setIsWalletLinked] = useState(false);
  const { disconnectAsync } = useDisconnect();
  const [isLoading, setIsLoading] = useState(false);
  const { address, isConnected, connector: activeConnector } = useAccount();

  const loginMutation = useMutation({
    mutationFn: twitterSave,
  });

  const linkWalletMutation = useMutation({
    mutationFn: userLinkWallet,
  });

  const challengeMutation = useMutation({
    mutationFn: challengeApi,
  });

  const currentUserMutation = useMutation({
    mutationFn: GetUser,
  });

  useEffect(() => {
    const accessToken = storage.get('accessToken');
    if (accessToken) setAccessToken(accessToken);
  }, []);

  useEffect(() => {
    if (accessToken) {
      getCurrentUser();
    }
  }, [accessToken, address]);

  useEffect(() => {
    if (signatureSet === '"yes"') {
      !address && setSignature('no');
    } else if (accessToken && address && isConnected) {
      linkWallet(address);
    }
  }, [isConnected, signatureSet, address, isWalletLinked]);

  const loginTwitter = async (payload: { state: string; code: string }) => {
    try {
      const res = await loginMutation.mutateAsync(payload);
      if (res.data) {
        setAccessToken(res.data.access);
        storage.set('user', JSON.stringify(res.data));
        storage.set('accessToken', res.data.access);
        storage.set('refreshAccessToken', res.data.refresh);
        return true;
      }
      return false;
    } catch (error) {
      console.error('error login', error);
      return false;
    }
  };

  const getCurrentUser = async () => {
    try {
      setIsLoading(true);
      const res = await currentUserMutation.mutateAsync();
      if (res?.data) {
        setUserInStore(res?.data as any);
        setWalletAddress(res.data.wallet_address);
        storage.set('user', JSON.stringify(res.data));
        storage.set('walletAddress', res.data.wallet_address);
        storage.set('userName', res.data.username);
        setUser(res.data);
      }
    } catch (error) {
      // console.error('getCurrentUserError', error);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    if (activeConnector) {
      // Check if there's an active connector
      try {
        await disconnectAsync();
      } catch (error) {
        console.error('Error disconnecting:', error);
      }
    } else {
      console.warn('No active connector found.');
    }
    storage.clear();
    setAccessToken(null);
    setIsLoading(false);
    navigate('/');
  };

  const getChallenge = async (walletAddress: string) => {
    try {
      const res = await challengeMutation.mutateAsync(walletAddress);
      return res.data;
    } catch (error) {
      console.error('getChallengeError', error);
      throw error;
    }
  };

  const linkWallet = async (walletAddress: `0x${string}` | undefined) => {
    if (walletAddress) {
      try {
        setIsLoading(true);
        const challenge = await getChallenge(walletAddress);
        const signature = await signMessageAsync({ message: challenge });
        if (!signature) throw 'User declined signature request';

        const res = await linkWalletMutation.mutateAsync({
          challenge,
          signature,
          wallet_address: walletAddress,
        });
        if (res?.status === 'success') {
          setWalletLinked(walletLinked);
          setIsWalletLinked(true);
          getCurrentUser();
        }
        if (res.data.wallet_address) {
          setSignature('"yes"');
          return true;
        }
      } catch (error) {
        setIsLoading(false);
        return error;
        // await disconnectAsync();
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    logout,
    getCurrentUser,
    loginTwitter,
    isLoading,
    linkWallet,
    user,
    setAccessToken,
  };
};

export default useUser;
