import { Box, Button, Image, Text } from '@chakra-ui/react';
import bgImageLeft from '../../assets/starBgImage1.png';
import bgImageRight from '../../assets/starBgImage2.png';
import homeImageMain from '../../assets/homeImageMain.png';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppStoreState, useAppStore } from '../../store';
import { loginWithXpad } from '../xpadAuth';

interface HomeHeroProps {
  onExploreButtonClick: () => void;
}

export default function HomeHero(props: HomeHeroProps) {
  const { onExploreButtonClick } = props;
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const navigate = useNavigate();

  const onMintNowClick = () => {
    if (accessToken) {
      navigate('/mint-nft');
    } else {
      loginWithXpad();
    }
  };

  return (
    <Box padding="56px 0px 64px 0px">
      <Box display="flex" justifyContent="center" alignItems="flex-start" gap={['unset', 'unset', '36px']}>
        <Box>
          <Image
            position={['absolute', 'absolute', 'unset']}
            src={bgImageLeft}
            width={['unset', 'unset', '225px']}
            height={['unset', 'unset', '225px']}
            left={['-187px', '-187px', 'unset']}
            top={['47px', '47px', 'unset']}
            objectFit="contain"
          />
        </Box>
        <Box>
          <Image src={homeImageMain} height={['unset', '219px', '314px']} objectFit="contain" />
        </Box>
        <Box>
          <Image
            position={['absolute', 'absolute', 'unset']}
            src={bgImageRight}
            width="225px"
            height={['unset', 'unset', '225px']}
            right={['-187px', '-187px', 'unset']}
            top={['47px', '47px', 'unset']}
            objectFit="contain"
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Text
          color="#FFF"
          textAlign="center"
          fontSize={['44px', '44px', '44px', '74px']}
          fontWeight="700"
          fontFamily="Montserrat"
        >
          <span className="gradient-text">Own</span> your feed
        </Text>
      </Box>
      <Box display="flex" justifyContent="center" marginTop="24px">
        <Text color="rgba(255, 255, 255, 0.50)" fontSize={['18px']} fontWeight="500" textAlign="center">
          The platform that converts your Twitter screenshots into unique, ownable NFTs.
        </Text>
      </Box>
      <Box display="flex" justifyContent="center" gap={['10px', '10px', '32px']} marginTop="24px">
        <Button
          width={['50%', '50%', '232px']}
          borderRadius="15px"
          borderTop="1px solid #04D3FF"
          padding="16px 32px"
          color=" #FFF"
          backgroundColor="#118BCF"
          fontSize={['16px', '16px', '20px']}
          fontWeight="600"
          h={['52px', '52px', '56px']}
          _hover={{
            color: 'white',
            bg: '#43BDD7',
          }}
          onClick={onMintNowClick}
        >
          Mint now
        </Button>
        <Button
          width={['50%', '50%', '232px']}
          borderRadius="15px"
          borderTop=" 1px solid #43BDD7"
          backgroundColor="#222"
          padding="16px 32px"
          color=" #FFF"
          fontSize={['16px', '16px', '20px']}
          fontWeight="600"
          h={['52px', '52px', '56px']}
          _hover={{
            color: 'white',
            bg: 'rgba(255, 255, 255, 0.20)',
          }}
          border="0.5px solid rgba(255, 255, 255, 0.20)"
          onClick={() => onExploreButtonClick()}
        >
          Explore now
        </Button>
      </Box>
    </Box>
  );
}
