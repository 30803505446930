import { Box, Divider, Image, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import TickIcon from '../../Icons/TickIcon';
import { AppStoreState, useAppStore } from '../../store';
import CopyIcon from '../../Icons/Copy';
import { formatAddress } from '../../utils/commonFunctions';
import EyeIcon from '../../Icons/EyeIcon';
import { UserLevelResponse } from '../../interfaces/components';
import ProfilePicture from '../../assets/profilePicture.png';
import { UnlinkWalletModal } from '../modals/UnlinkWallet';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import storage from '../../utils/storage';
import Rank from '../progress/Rank';
import { getUserLevel } from '../../api/userLevels/userLevelApi';
import LoadingSpinner from '../common/LoadingSpinner';

export default function MyProfileHero() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { open } = useWeb3Modal();
  const walletAddress = storage.get('walletAddress');
  const toast = useToast();
  const walletAddressStore = useAppStore((state: AppStoreState) => state.walletAddress);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const [rerender, setRerender] = useState(false);
  const [userLevelData, setUserLevelData] = useState<UserLevelResponse | null>(null);
  const user = storage.get('user');
  const currentUser = JSON.parse(user || '{}');

  const buttonTexts = [
    {
      id: 1,
      text: currentUser?.wallet_address ? 'Wallet Linked' : 'Link Your Wallet',
      showTick: currentUser?.wallet_address ? true : false,
    },
    { id: 2, text: accessToken ? 'Twitter Linked' : 'Link Twitter', showTick: accessToken ? true : false },
    { id: 3, text: walletAddress ? walletAddress : 'Connect Your Wallet', showTick: walletAddress ? true : false },
  ];

  const handleLogin = async () => {
    open({ view: 'Networks' }).then((res) => {
      console.log('handle login res network', res);
    });
  };

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast({
        title: 'Copied!',
        description: 'Wallet address copied to clipboard',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Error!',
        description: 'Something went wrong while copying wallet address. Please try again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const fetchUserLevel = async () => {
    try {
      setLoading(true);
      const res = await getUserLevel();
      setUserLevelData(res);
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching user level',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchUserLevel();
  }, []);

  useEffect(() => {
    setRerender(!rerender);
  }, [walletAddressStore]);

  const onButtonClick = (info: any) => {
    if (info.id === 3) {
      if (!walletAddress) {
        handleLogin();
      } else {
        console.log('from else');
      }
    }
  };

  return (
    <>
      <UnlinkWalletModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box width={['100%']} padding="56px 0px 64px 0px">
          <Box display="flex" justifyContent="center" alignItems="center" gap="36px">
            {currentUser?.profile_picture ? (
              <Box height="96px" width="96px">
                <Image
                  src={currentUser?.profile_picture}
                  objectFit="cover"
                  borderRadius="50%"
                  height="inherit"
                  width="inherit"
                />
              </Box>
            ) : (
              <Box height="96px" width="96px">
                <Image src={ProfilePicture} objectFit="cover" height="inherit" width="inherit" borderRadius="50%" />
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="36px" gap="10px">
            <Text color="#FFFFFF" fontSize="28px" fontWeight="700">
              {`@${currentUser?.username.toLowerCase()}`}
            </Text>
          </Box>
          <Box w={'100%'} display={'flex'} justifyContent={'center'}>
            <Box
              display="flex"
              width={'100%'}
              maxWidth={'920px'}
              flexDirection={['column', 'column', 'row', 'row']}
              marginTop="36px"
              justifyContent="center"
              alignItems="center"
              gap="14px"
            >
              <Box
                borderRadius="26px"
                border="1px solid rgba(255, 255, 255, 0.10)"
                background=" rgba(255, 255, 255, 0.10)"
                padding="24px"
                width={['100%', '100%', '50%']}
                height="216px"
              >
                {buttonTexts?.map((info, index) => (
                  <Box key={index}>
                    <Box
                      width={['auto', 'auto', 'auto', 'auto']}
                      display="flex"
                      alignItems={['unset', 'unset', 'center']}
                      height="56px"
                      padding="16px"
                      onClick={() => onButtonClick(info)}
                      justifyContent="space-between"
                      cursor={info?.id === 3 ? 'pointer' : 'unset'}
                    >
                      <Box display="flex" gap="10px" alignItems="center">
                        {info?.showTick && info?.id !== 3 ? (
                          <TickIcon />
                        ) : info?.id === 3 && info?.showTick ? (
                          <Box cursor="pointer">
                            <CopyIcon
                              onClick={() => {
                                info?.text && copyToClipBoard(info?.text);
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="20px"
                            height="20px"
                            borderRadius="50%"
                            border=" 1px solid rgba(255, 255, 255, 0.10)"
                            background=" rgba(255, 255, 255, 0.05)"
                          ></Box>
                        )}

                        {info?.id === 3 && info?.showTick ? (
                          <Text color="#909090" fontSize="20px" fontWeight="600">
                            {formatAddress(info?.text, 5, 4)}
                          </Text>
                        ) : (
                          <Text color=" #909090" fontSize="20px" fontWeight="600">
                            {info?.text}
                          </Text>
                        )}
                      </Box>
                      <Box cursor="pointer" onClick={onOpen}>
                        {info?.id === 3 && info?.showTick && <EyeIcon />}
                      </Box>
                    </Box>
                    {info?.id !== 3 && <Divider border="0.5px solid rgba(255, 255, 255, 0.20) !important" />}
                  </Box>
                ))}
              </Box>
              <Box width={['100%', '100%', '50%']}>
                <Rank isProfilePage={true} userLevelData={userLevelData} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
