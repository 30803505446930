import { Box } from '@chakra-ui/react';
import React from 'react';
import CompetitionOverviewHero from './CompetitionOverviewHero';
import CompetitionPlay from './CompetitionPlay';
import Competitions from './Competitions';

export default function CompetitionOverviewMain() {
  return (
    <Box
      zIndex={2}
      flexDirection="column"
      maxHeight="100%"
      marginTop={['11px', '11px', '40px', '40px']}
      maxWidth="1400px"
    >
      <CompetitionOverviewHero />
      <Competitions heading={'Featured Competitions'} />
      <CompetitionPlay />
    </Box>
  );
}
