import { Box, Text, Image } from '@chakra-ui/react';
import bgImageLeft from '../../assets/starBgImage1.png';
import { parseISO, isBefore, isAfter } from 'date-fns';
import TweetImage from '../../assets/tweetImage.png';
import React from 'react';
import CompetitionStats from './CompetitionStats';
import { CompetitionObject } from '../../interfaces/components';

interface SingleCompetitionHeroProps {
  competitionDetails: CompetitionObject;
  onVoteNowClick: () => void;
}

export default function SingleCompetitionHero(props: SingleCompetitionHeroProps) {
  const { competitionDetails, onVoteNowClick } = props;

  return (
    <Box>
      <Box
        position={['unset', 'unset', 'unset', 'unset', 'relative']}
        display="flex"
        flexDirection={['column-reverse', 'row', 'row', 'row', 'row']}
        marginBottom={['unset', 'unset', '56px']}
        borderRadius={['20px', '20px', '36px']}
        padding={['16px', '16px', 'unset']}
        gap={['24px', '24px', 'unset']}
        background="radial-gradient(1260.58% 227.12% at 106.21% 87.11%, rgba(4, 211, 255, 0.30) 0%, rgba(58, 255, 101, 0.30) 100%)"
      >
        <Box width="auto" padding={['unset', 'unset', '36px']}>
          <Text
            color="#FFF"
            fontFamily="Montserrat"
            fontSize={['24px', '24px', '36px']}
            fontStyle="normal"
            fontWeight="700"
            lineHeight="normal"
          >
            {competitionDetails?.competition_name}
          </Text>
          <Text
            color="rgba(255, 255, 255, 0.50)"
            fontFamily="Montserrat"
            fontSize={['16px', '16px', '18px']}
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            marginTop="10px"
          >
            Show off your wit & creativity - Compete & Mint Winning NFTs on Layer X.
          </Text>
          <Box display={['none', 'none', 'none', 'block']}>
            <CompetitionStats
              bgColorMainBox="rgba(0, 0, 0, 0.10)"
              playerCount={competitionDetails?.total_entries}
              countDownDate={
                isAfter(parseISO(competitionDetails?.participation_starts), new Date())
                  ? competitionDetails?.participation_starts
                  : isAfter(new Date(), parseISO(competitionDetails?.participation_starts)) &&
                    isBefore(new Date(), parseISO(competitionDetails?.voting_starts))
                  ? competitionDetails?.voting_starts
                  : isAfter(new Date(), parseISO(competitionDetails?.voting_starts)) &&
                    isBefore(new Date(), parseISO(competitionDetails?.voting_ends))
                  ? competitionDetails?.voting_ends
                  : isAfter(new Date(), parseISO(competitionDetails?.voting_ends)) && competitionDetails?.voting_ends
              }
              competitionDetails={competitionDetails}
              startDate={competitionDetails?.start}
              totalVotes={competitionDetails?.total_votes ? competitionDetails?.total_votes : 0}
              onVoteNowClick={onVoteNowClick}
            />
          </Box>
        </Box>
        <Box
          width="-webkit-fill-available"
          display={['none', 'none', 'flex']}
          justifyContent={['unset', 'unset', 'flex-end', 'flex-end', 'unset']}
        >
          <Box>
            <Image right={['unset', 'unset', '200px', '300px']} position="absolute" src={bgImageLeft} />
          </Box>

          <Box
            zIndex={1}
            position="relative"
            // left={['unset', 'unset', 'unset', 'unset', '192px', '360px']}
            transform="rotate(10deg)"
            borderRadius=" 36px"
            border="1.218px solid rgba(255, 255, 255, 0.10)"
            background="#1E1E1E"
            boxShadow="10px 29px 33.85px 0px rgba(0, 0, 0, 0.45)"
            backdropFilter="blur(26.797731399536133px)"
            width={['unset', 'unset', '272px', '359px', '359px']}
            color="white"
            className="single-competition-hero-img"
          >
            <Image
              width={['inherit', 'inherit', '100%', '100%', '100%']}
              cursor="pointer"
              src={competitionDetails?.competition_image}
              height="100%"
              objectFit="cover"
              borderRadius="36px"
              padding="10px"
            />
          </Box>
          <Box>
            <Image transform="rotate(-101deg)" position="absolute" src={bgImageLeft} bottom={1} right={'10px'} />
          </Box>
        </Box>
        <Box
          borderRadius="12px"
          border="1.218px solid rgba(255, 255, 255, 0.10)"
          background="#1E1E1E"
          boxShadow="10px 29px 33.85px 0px rgba(0, 0, 0, 0.45)"
          backdropFilter="blur(26.797731399536133px)"
          width={['auto']}
          color="white"
          display={['block', 'block', 'none']}
        >
          <Image
            width={['inherit', 'inherit', '100%', '100%', '100%']}
            cursor="pointer"
            src={TweetImage}
            height="100%"
            objectFit="cover"
            borderRadius="12px"
            padding="8px"
          />
        </Box>
      </Box>
      <Box marginTop="24px" display={['block', 'block', 'block', 'none']}>
        <CompetitionStats
          bgColorMainBox="#242424"
          playerCount={competitionDetails?.total_entries}
          countDownDate={
            isAfter(parseISO(competitionDetails?.participation_starts), new Date())
              ? competitionDetails?.participation_starts
              : isAfter(new Date(), parseISO(competitionDetails?.participation_starts)) &&
                isBefore(new Date(), parseISO(competitionDetails?.voting_starts))
              ? competitionDetails?.voting_starts
              : isAfter(new Date(), parseISO(competitionDetails?.voting_starts)) &&
                isBefore(new Date(), parseISO(competitionDetails?.voting_ends))
              ? competitionDetails?.voting_ends
              : isAfter(new Date(), parseISO(competitionDetails?.voting_ends)) && competitionDetails?.voting_ends
          }
          competitionDetails={competitionDetails}
          startDate={competitionDetails?.start}
          totalVotes={competitionDetails?.total_votes ? competitionDetails?.total_votes : 0}
          onVoteNowClick={onVoteNowClick}
        />
      </Box>
    </Box>
  );
}
