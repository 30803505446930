import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import CompetitionCardIcon from '../../Icons/CompetitionCardIcon';
import CompetitionCardIcon2 from '../../Icons/CompetitionCardIcon2';
import CompetitionCardIcon3 from '../../Icons/CompetitionCardIcon3';

const working = [
  {
    id: 1,
    heading: 'Pick a challenge',
    decription: 'Select a challenge that you like and want to participate in',
    icon: <CompetitionCardIcon />,
  },
  {
    id: 2,
    heading: 'Find a relevant tweet on Twitter',
    decription: 'Pick a tweet that fits into the theme of the challenge and let your creativity shine',
    icon: <CompetitionCardIcon2 />,
  },
  {
    id: 2,
    heading: 'Submit your entry',
    decription: 'Submit your NFT to compete in the challenge and let the votes come flying in!',
    icon: <CompetitionCardIcon3 />,
  },
];

export default function CompetitionPlay() {
  return (
    <Box marginTop="56px" marginBottom="56px">
      <Text color=" #FFF" fontSize={['28px', '28px', '34px', '36px']} fontWeight="800">
        How to play?
      </Text>
      <Box display="flex" flexDirection={['column', 'column', 'row']} gap="24px" marginTop="24px">
        {working.map((work, i) => (
          <Box
            borderRadius="26px"
            border="0.5px solid rgba(255, 255, 255, 0.10)"
            backgroundColor="rgba(255, 255, 255, 0.10)"
            width={['100%', '100%', '33%']}
            padding="16px 16px 28px 16px"
            key={i}
          >
            <Box
              width="58px"
              height="58px"
              borderRadius="14px"
              border="0.5px solid rgba(255, 255, 255, 0.10)"
              backgroundColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {work?.icon}
            </Box>
            <Box marginTop="18px" display="flex" flexDirection="column" gap="18px">
              <Text color="#FFF" fontSize={['20px', '20px', '24px']} fontWeight="600">
                {work?.heading}
              </Text>
              <Text color="rgba(255, 255, 255, 0.50)" fontSize="16px" fontWeight="500">
                {work?.decription}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
      {/* <Box display="flex" alignItems="center" justifyContent="center" marginTop="56px">
        <Button
          width="232px"
          borderRadius="15px"
          borderTop="1px solid #43BDD7"
          backgroundColor="#118BCF"
          padding="16px 32px"
          color=" #FFF"
          fontSize={['16px', '16px', '20px']}
          fontWeight=" 600"
          h="56px"
          _hover={{
            color: 'white',
            bg: '#43BDD7',
          }}
          onClick={() => navigate('/competitions')}
        >
          See competitions
        </Button>
      </Box> */}
    </Box>
  );
}
