import { Icon } from '@chakra-ui/icons';
import React from 'react';

export default function CopyLinkIcon() {
  return (
    <Icon width="24px" height="25px" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7091 18.0295C20.7091 20.4895 18.6991 22.4995 16.2391 22.4995H7.75906C5.29906 22.4995 3.28906 20.4895 3.28906 18.0295V8.61949C3.28906 6.90949 4.24906 5.41949 5.65906 4.66949C6.14906 4.40949 6.75906 4.75949 6.75906 5.31949C6.75906 6.90949 8.05906 8.20949 9.64906 8.20949H14.3491C15.9391 8.20949 17.2391 6.90949 17.2391 5.31949C17.2391 4.75949 17.8391 4.40949 18.3391 4.66949C19.7491 5.41949 20.7091 6.90949 20.7091 8.61949V18.0295Z"
        fill="#4C4C4C"
      />
      <path
        d="M9.64977 2.5C8.60977 2.5 7.75977 3.34 7.75977 4.38V5.32C7.75977 6.36 8.59977 7.2 9.63977 7.2H14.3498C15.3898 7.2 16.2298 6.36 16.2298 5.32V4.38C16.2398 3.34 15.3898 2.5 14.3498 2.5H9.64977Z"
        fill="#4C4C4C"
      />
    </Icon>
  );
}
